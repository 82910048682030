<template>
    <vSelect
        class="select"
        :class="classes"
        label="title"
        :options="options"
        :clearable="false"
        :searchable="false"
        v-model="value"
        @input="selectInput(value)"
    >
        <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
                <ArrowDown />
            </span>
        </template>

        <template #option="{ title }">
            <Checked class="option-checked" />
            <span class="option-title">{{ title }}</span>
        </template>
    </vSelect>
</template>
<script>
import vSelect from 'vue-select';
import ArrowDown from '@/components/icons/ArrowDown';
import Checked from '@/components/icons/Checked';

import 'vue-select/src/scss/vue-select.scss';

export default {
    name: 'Select',
    model: {
        event: 'input',
        prop: 'outerValue',
    },
    data() {
        return {
            value: '',
        };
    },
    watch: {
        options() {
            this.setOptions();
        },
        outerValue(outerValue) {
            this.value = outerValue;
        }
    },
    created() {
        this.value = this.outerValue;
    },
    mounted() {
        this.setOptions();
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        outerValue: [String, Object],
    },
    methods: {
        setOptions() {
            if (!this.value && this.options[0]) {
                this.value = this.options[0];
            }
        },
        selectInput(value) {
            this.$emit('input', value);
        },
    },
    computed: {
        classes() {
            return {
                'vs-not-selected': [null, 'Все'].includes(this.value) || (this.value && ([null, 'Все'].includes(this.value.title) || [null, 'Все'].includes(this.value.value))),
            }
        }
    },
    components: {
        vSelect,
        ArrowDown,
        Checked,
    },
};
</script>

<style lang="scss">
@import '@/styles/vars.scss';

.v-select.select {
    font-size: 1rem;
    width: 100%;
    /*&.vs-not-selected {
        .vs__dropdown-toggle {
            border-color: #D9DDE7;
        }
    }*/
    &.vs--open {
        .vs__dropdown-toggle {
            border-radius: 4px;
            border-color: $c-dark2;
        }

        .vs__selected,
        .vs__search {
            font-weight: 500;
            opacity: 1;
        }
    }

    &:hover {
        .vs__dropdown-toggle {
            border-color: $c-dark2;
        }

        .vs__selected,
        .vs__search {
            opacity: 1;
        }
    }

    .vs__dropdown {
        &-option {
            color: $c-dark;
            position: relative;
            display: flex;
            align-items: center;
            padding: 5px 1.6rem 5px 3.4rem;
            margin: 0;
            line-height: 0;

            .option-title {
                display: inline-block;
                line-height: (13/12);
                font-size: 1.2rem;
            }

            .option-checked {
                display: none;
                position: absolute;
                top: 45%;
                transform: translateY(-50%);
                left: 1.6rem;
            }

            &--selected {
                .option-checked {
                    display: inline;
                }
            }

            &:hover,
            &--highlight {
                background-color: $c-primary;
                color: $c-white;
            }
        }

        &-toggle {
            padding: 0px 0rem 0px 1rem;
            align-items: center;
            border: 1px solid #D9DDE7;
        }

        &-menu {
            display: block;
            border: none;
            box-shadow: $shadow;
            margin-top: 4px;
            padding: 2px 0;
        }
    }

    .vs__selected,
    .vs__search {
        width: 100%;
        //padding-right: 0;
        display: inline-block;
        border: none;
        margin: 0;
        flex-shrink: 0;
        line-height: (19/13);
        font-size: 1.3rem;
        color: $c-dark;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .vs__selected-options {
        flex-wrap: nowrap;
        overflow: hidden;
    }

    .vs__actions {
        padding: 0;
    }

    .vs__open-indicator {
        display: inline-block;
        line-height: 0;
        padding: 20px 1.4rem 19px;
    }
}

@media (min-width: 2320px) {
    .v-select.select {
        &.vs--open {
            .vs__dropdown-toggle {
                border-radius: 6px;
            }
        }
        .vs__open-indicator {
            svg {
                width: 21px;
                height: 21px;
            }
            padding: 28px 2.3rem 26px;
        }
        .vs__selected,
        .vs__search {
            font-size: 2rem;
        }
        .vs__selected-options {
            padding: 0 5px;
        }
        .vs__dropdown-toggle {
            padding-left: 20px;
            border-radius: 6px;
        }
        .vs__dropdown {
            &-option {
                padding: 15px 2.6rem 15px 4.5rem;

                .option-title {
                    line-height: (13/12);
                    font-size: 1.7rem;
                }

                .option-checked {
                    width: 20px;
                    height: 20px;
                }
            }

            &-menu {
                margin-top: 8px;
                padding: 4px 0;
            }
        }
    }
}

@media (max-width: 890px) {
    .v-select.select .vs__dropdown-toggle {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 620px) {
    .v-select.select .vs__open-indicator {
        padding: {
            top: 15px;
            bottom: 14px;
        }
    }
}
</style>
