<template>
    <div id="app" class="skin-magnit" :style="{opacity: resizing || !user.id ? 0 : 1}">
        <Header
            v-if="$route.meta.layout !== 'empty'"
            :class="{'header-login': this.$router.currentRoute.path === '/login'}"
            :main="this.$router.currentRoute.path === '/'"
            :login="this.$router.currentRoute.path === '/login'"
        />
        <router-view />
        
        <Footer
                v-if="$route.meta.layout !== 'empty'"
                :class="{'footer-login': this.$router.currentRoute.path === '/login'}"
        />
        <div id="screenshot" style="display: none" />
        <modal
                :adaptive="true"
                :minWidth="300"
                width="90%"
                height="auto"
                name="messages-modal"
                @closed="hideMessagesModal"
        >
            <CloseModal
                :styles="{position: 'absolute', 'top': 24, right: 24}"
                @click="hideMessagesModal"
            />
            <div style="padding: 40px 24px 30px">
                <div class="section-subtitle">{{ modalMessagesHeader }}</div>
                <div class="table-wrap">
                    <Posts
                            v-model="orderData"
                            :headers="modalMessageHeaders"
                            :posts="modalMessages"
                    />
                </div>
                <Pagination
                        v-model="modalPage"
                        :pages="modalMessagesPages"
                />
            </div>
        </modal>
        <modal
                :adaptive="true"
                :minWidth="300"
                width="90%"
                height="auto"
                name="message-modal"
                @closed="hideMessageModal"
        >
            <CloseModal
                :styles="{position: 'absolute', 'top': 24, right: 24}"
                @click="hideMessageModal"
            />
            <div style="padding: 40px 24px 30px">
                <div class="section-subtitle">Подробная информация</div>
                <br><br>
                <table class="table" data-v-54b96478="" v-if="($router.currentRoute.path === '/' && !isMobile) || (windowWidth > 1200 && $router.currentRoute.path !== '/')">
                   <thead data-v-54b96478="">
                        <tr data-v-54b96478="">
                           <th data-v-54b96478="" class="">Дата</th>
                           <th data-v-54b96478="" class="">Перейти на оригинал</th>
                           <th data-v-54b96478="" class="">Округ</th>
                           <th data-v-54b96478="" class="">Проблема 2 ур.</th>
                           <th data-v-54b96478="" class="">Ответственный</th>
                           <th data-v-54b96478="" class="">Принятые меры</th>
                        </tr>
                   </thead>
                   <tbody data-v-54b96478="">
                      <tr class="" data-v-54b96478="">
                         <td class="td--date" data-v-54b96478="">
                            <div class="td--date-time" data-v-54b96478="">
                               <span data-v-54b96478="" class="td--inner-date" v-html="modalMessagenDate"></span>
                               <span data-v-54b96478="" class="td--inner-time" v-html="modalMessagenDateTime"></span>
                               <span data-v-54b96478="" :class="{'td--inner-status': true, 'processed': modalMessagenStatus === 'Обработан', 'new': modalMessagenStatus === 'Новый'}" v-html="modalMessagenStatus"></span>
                            </div>
                         </td>
                         <td class="td--btn" data-v-54b96478="">
                            <a data-v-54b96478="" :href="modalMessagenLink" target="_blank">
                               <span data-v-54b96478="">Перейти</span>
                               <img data-v-54b96478="" src="/img/icons/arrow-right.svg" alt=">">
                            </a>
                         </td>
                         <td class="td--text td--center" data-v-54b96478="">
                            <div class="td--region-branch" data-v-54b96478="">
                               <span data-v-54b96478="" class="td--inner-region-name" v-html="modalMessagenRegionName"></span>
                               <span data-v-54b96478="" class="td--inner-region-branch" v-html="modalMessagenRegionBranch"></span>
                            </div>
                         </td>
                         <td data-v-54b96478="" class="td--text td--center" v-html="modalMessagenProblem"></td>
                         <td data-v-54b96478="" class="td--text td--center" v-html="modalMessagenResponsible"></td>
                         <td data-v-54b96478="" class="td--text" v-html="modalMessagenMeasures"></td>
                      </tr>
                   </tbody>
                </table>
                <table
                    v-else
                    class="table table-mobile"
                >
                    <tbody data-v-54b96478="">
                       <tr data-v-54b96478="">
                          <td data-v-54b96478="" colspan="2" class="th">1</td>
                       </tr>
                       <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Дата
                          </td>
                          <td data-v-54b96478="" class="td--date">
                             <div data-v-54b96478="" class="td--date-time">
                                <span data-v-54b96478="" class="td--inner-date" v-html="modalMessagenDate"></span>
                                <span data-v-54b96478="" class="td--inner-time" v-html="modalMessagenDateTime"></span>
                                <span data-v-54b96478="" :class="{'td--inner-status': true, 'processed': modalMessagenStatus === 'Обработан', 'new': modalMessagenStatus === 'Новый'}" v-html="modalMessagenStatus"></span>
                            </div>
                          </td>
                       </tr>
                        <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Перейти на оригинал
                          </td>
                          <td data-v-54b96478="" class="td--btn asfi">
                            <a data-v-54b96478="" :href="modalMessagenLink" target="_blank">
                                <span data-v-54b96478="">Перейти</span>
                                <img data-v-54b96478="" src="/img/icons/arrow-right.svg" alt=">">
                            </a>
                          </td>
                        </tr>
                        <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Округ
                          </td>
                          <td data-v-54b96478="" class="td--text td--center">
                             <div data-v-54b96478="" class="td--region-branch">
                               <span data-v-54b96478="" class="td--inner-region-name" v-html="modalMessagenRegionName"></span>
                               <span data-v-54b96478="" class="td--inner-region-branch" v-html="modalMessagenRegionBranch"></span>
                            </div>
                          </td>
                        </tr>
                        <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Проблема 2 ур.
                          </td>
                          <td data-v-54b96478="" class="td--text asfi" v-html="modalMessagenProblem"></td>
                        </tr>
                        <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Ответственный
                          </td>
                          <td data-v-54b96478="" class="td--text td--center asfi" v-html="modalMessagenResponsible"></td>
                        </tr>
                        <tr data-v-54b96478="">
                          <td data-v-54b96478="" class="td--th">
                             Принятые меры
                          </td>
                          <td data-v-54b96478="" class="td--text asfi" v-html="modalMessagenMeasures"></td>
                        </tr>
                    </tbody>
                </table>
                <br><br>
                <div class="section-subtitle f20">Полный текст сообщения</div>
                <p v-html="modalMessagenText"></p>

            </div>
        </modal>
        <canvas id="image-crop" style="display:none"/>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Posts from '@/components/Posts.vue';
import Pagination from '@/components/Pagination.vue';
import CloseModal from '@/components/icons/CloseModal.vue'

export default {
    name: 'App',
    components: {
        Header,
        Footer,
        Posts,
        Pagination,
        CloseModal,
    },
    computed: {
        ...mapState(['resizing', 'windowWidth', 'defaultDate']),
        ...mapGetters([
            'modalMessageOpen',

            'modalMessagenHeader',
            'modalMessagenText',

            'modalMessagesDate',
            'modalMessagesDateTime',
            'modalMessagesStatus',
            'modalMessagesLink',
            'modalMessagesRegionName',
            'modalMessagesRegionBranch',
            'modalMessagesProblem',
            'modalMessagesResponsible',
            'modalMessagesMeasures',

            'modalMessagenDate',
            'modalMessagenDateTime',
            'modalMessagenStatus',
            'modalMessagenLink',
            'modalMessagenRegionName',
            'modalMessagenRegionBranch',
            'modalMessagenProblem',
            'modalMessagenResponsible',
            'modalMessagenMeasures',

            'modalMessagesOpen',
            'modalMessagesHeader',
            'modalMessageHeaders',
            'modalMessages',
            'modalMessagesPages',
            'modalMessageFilters',
            'modalMessagesAction',
            'user',

            'modalMessage',
        ]),
        apiQueryParams() {
        }
    },
    data() {
        return {
            modalPage: 1,
            orderData: {},
            detailizations: [
                {
                    title: 'Детализация по дням',
                    value: 'day',
                },
                {
                    title: 'Детализация по неделям',
                    value: 'week',
                },
                {
                    title: 'Детализация по месяцам',
                    value: 'month',
                },
            ],
        }
    },
    watch: {
        // '$route.fullPath': function() {
            // this.setDate(this.defaultDate);
            // this.setTonality(null);
            // this.setRegion(null);
            // this.setCompareDate({});
        // },
        modalMessagesOpen(modalMessagesOpen) {
            if (modalMessagesOpen) {
                this.showMessagesModal();
            } else {
                this.$modal.hide('messages-modal');
            }
        },
        modalMessageOpen(modalMessageOpen) {
            if (modalMessageOpen) {
                this.showMessageModal();
            } else {
                this.$modal.hide('message-modal');
            }
        },
        modalPage() {
            this.getModalMessages()
        },
        orderData() {
            this.getModalMessages()
        },
        $route (to, from){
            if (!['/token', '/logout'].includes(this.$router.currentRoute.path)) {
                if (!localStorage.getItem('token')) {
                  window.location.href = process.env.VUE_APP_ADMIN_URL;
                } else {
                    this.fetchUser();
                    setInterval(() => {
                        this.fetchUser();
                    }, 120000);
                }
            }
        }
    },
    methods: {
        ...mapActions([
            'setModalMessagesOpen',
            'setModalMessageOpen',
            'getSocialModalMessage',
            'getSocialModalMessages',
            'getSmiModalMessages',
            'fetchUser',
            'setDate',
            'setCompareDate',
            'setTonality',
            'setRegion',
            'setDetailization',

        ]),
        showMessagesModal () {
            this.$modal.show('messages-modal');
        },
        hideMessagesModal () {
            this.setModalMessagesOpen(false);
        },

        showMessageModal () {
            this.$modal.show('message-modal');
        },
        hideMessageModal () {
            this.setModalMessageOpen(false);
        },

        getModalMessages() {
            this[this.modalMessagesAction]({
                ...this.modalMessageFilters,
                page: this.modalPage,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            });
        }
    },
    created() {
        document.title = "ECHO";

        if (!['/token', '/logout'].includes(this.$router.currentRoute.path)) {
            if (this.$route.query.date) {
                const [startDate, endDate] = this.$route.query.date.split('-');
                this.setDate({
                    startDate,
                    endDate,
                });
            }
            if (this.$route.query.compareDate) {
                const [startDate, endDate] = this.$route.query.compareDate.split('-');
                this.setCompareDate({
                    startDate,
                    endDate,
                });
            }
            if (this.$route.query.tonality) {
                this.setTonality(this.$route.query.tonality);
            }
            if (this.$route.query.region) {
                this.setRegion(this.$route.query.region);
            }
            if (this.$route.query.detailization) {
                this.setDetailization(this.detailizations.find(item => item.value === this.$route.query.detailization));
            }
            if (!localStorage.getItem('token')) {
               window.location.href = process.env.VUE_APP_ADMIN_URL;
            } else {
                this.fetchUser();
                setInterval(() => {
                    this.fetchUser();
                }, 120000);
            }
        } else {
        }
    },
    mounted () {
        if (this.modalMessagesOpen) {
            this.showMessagesModal();
        } else {
            this.$modal.hide('messages-modal');
        }

        if (this.modalMessageOpen) {
            this.showMessageModal();
        } else {
            this.$modal.hide('message-modal');
        }
    }
};
</script>


<style lang="scss">
@import '@/styles/base.scss';
#app .vm--modal {
    overflow-y: auto;
    height: calc(100vh - 60px) !important;
}
</style>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

#app {
    transition: opacity .3s ease;
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    > * {
        max-width: 100%;
    }
}
.header-login,
.footer-login {
    background-color: $c-login-dark;
}

.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
 }

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.table-wrap {
    border: none;
    padding: 0;
}
</style>
