<template>
    <div class="smi-mentioning" v-if="userAccessSmi">
        <div>
            <div class="container">
                <div class="section-subtitle">СМИ: Динамика упоминаемости</div>
            </div>
        </div>
        <Form
            :comparedatePosBreakPoints="{620: 'center', 1440: 'left', 10000: 'right'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'detailization', 'tonality', 'compareDate']"
            @download="$downloadReport(pressMessages, $event)"
        />
        <div>
            <div class="container">
                <LineChartComponent />
            </div>
        </div>
        <section>
            <div class="container">
                <div class="table-wrap">
                    <Posts
                        v-model="orderData"
                        :ordering="false"
                        :headers="postHeaders"
                        :posts="pressMessages"
                    />
                </div>
                <Pagination v-model="page" :pages="pressMessagesPages" />
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import LineChartComponent from '@/components/LineChartComponent';
import Pagination from '@/components/Pagination';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SmiMentioning',
    components: {
        Form,
        Posts,
        Pagination,
        LineChartComponent,
    },
    mixins: [downloadReport],
    data() {
        return {
            page: 1,
            orderData: {},
            postHeaders: {
                date: { title: 'Дата', class: 'td--date', format_number: false, ordering: true },
                time: { title: 'Время', class: 'td--date', format_number: false, ordering: false},
                title: { title: 'Заголовок', class: 'td--text', format_number: false, ordering: true },
                mediaIndex: { title: 'Медиаиндекс', class: '', format_number: true, ordering: true },
                tonality: { title: 'Тональность', class: '', format_number: false, ordering: true },
                link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
            },
            reportColumns: ['date', 'time', 'title', 'mediaIndex', 'tonality', 'link'],
        };
    },
    watch: {
        apiQueryParams: {
            handler() {
                setTimeout(() => {
                    this.userAccessSmi && this.getPressMentions(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSmi && this.getPressMentions2(this.apiQueryParamsCompare);
                }
            },
            deep: true,
        },
        apiQueryParamsPaginated: {
            handler() {
                setTimeout(() => {
                    this.userAccessSmi && this.getPressMessages(this.apiQueryParamsPaginated);
                }, 500);
            },
            deep: true,
        },
        userAccessSmi(userAccessSmi) {
            this.$checkAccess(userAccessSmi);
        },
    },
    mounted() {
        this.$checkAccess(this.userAccessSmi);
        if (!this.userAccessSmi) return;
        this.getPressMentions(this.apiQueryParams);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getPressMentions2(this.apiQueryParamsCompare);
        }
    },
    computed: {
        ...mapState(['detailization']),
        ...mapGetters([
            'date',
            'compareDate',
            'pressMentions',
            'pressMessages',
            'pressMessagesPages',
            'progressTime',
            'userAccessSmi',
            'tonality',
            'region',
        ]),
        apiQueryParams() {
            return {
                date: this.date,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
            }
        },
        apiQueryParamsPaginated() {
            return {
                date: this.date,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                page: this.page,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
            }
        },
    },
    methods: {
        ...mapActions([
            'getPressMentions',
            'getPressMentions2',
            'getPressMessages',
        ]),
    }
};
</script>

<style lang="scss">
.smi-mentioning {
    .form-field {
        &.tonality {
            margin-right: calc(44 / 1200 * 100%);
        }
        &.comparedate .opensleft {
            transform: translateX(calc(50% + 52px));
        }
    }
}
@media (min-width: 1440px) {
    .smi-mentioning {
        .form-field {
            &.detailization {
                margin-right: calc(237 / 1200 * 100%);
            }
        }
    }
}

@media (max-width: 1108px) {
    .smi-mentioning {
        .form-field {
            &.tonality {
                margin-right: calc(30 / 920 * 100%);
            }
        }
    }
}

@media (max-width: 980px) {
    .smi-mentioning {
        .form-field {
            &.btns-wrap {
                max-width: unset;
            }
            &.comparedate .opensleft {
                transform: none;
            }
        }
    }
}

@media (max-width: 890px) {
    .smi-mentioning {
        .form-field {
            &.tonality {
                min-width: 160px;
            }
            &.tonality,
            &.detailization,
            &.btns-wrap .btn {
                margin-right: 20px;
            }
        }
    }
}
@media (max-width: 620px) {
    .smi-mentioning {
        .form-field {
            &.btns-wrap .btn {
                margin-right: 0;
            }
        }
    }
}
</style>
