import axios from 'axios';

// import socketError from '../utils/socketError';

async function delay(timeout) {
    return new Promise((res, rej) => {
        setTimeout(() => {
            res();
        }, timeout)
    })
}

export default {
    // namespaced: true,

    state: {
        topNegativeSourcesLoaded: false,
        topNegativeSources: [],
        topNegativeSources2: {},
        socialNegativeSourcesList: {
            data: [],
        },
        socialNegativeSourcesList2: {},
    },
    mutations: {
        setTopNegativeSources(state, val) {
            state.topNegativeSources = val;
            state.topNegativeSourcesLoaded = true;
        },
        setTopNegativeSources2(state, val) {
            const data = {};
            val.forEach(item => {
                data[item.name] = item.value;
            });
            state.topNegativeSources2 = data;
        },
        setSocialNegativeSourcesList(state, val) {
            state.socialNegativeSourcesList = val;
        },
        setSocialNegativeSourcesList2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item;
            });
            state.socialNegativeSourcesList2 = data;
        },
    },
    actions: {
        async getTopNegativeSources({dispatch, commit}, {date, region, tonality}) {
            let success = false;
            while (!success) {
                try {
                    const res = await axios.get('/social/topfivenegativesourcespercentage', {
                        params: {
                            region,
                            tonality,
                            date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        }
                    });
                    const resData = res.data;
                    commit('setTopNegativeSources', resData.data);
                    success = true;
                    return resData;
                } catch (e) {
                    console.error(e);
                    await delay(3000);
                }
                break;
            }
        },
        async getTopNegativeSources2({dispatch, commit}, {date, region, tonality, names}) {
            const res = await axios.get('/social/topfivenegativesourcespercentage', {
                params: {
                    region,
                    tonality,
                    names,
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                }
            });
            const resData = res.data;
            commit('setTopNegativeSources2', resData.data);
            return resData;
        },
        async getSocialNegativeSourcesList({ dispatch, commit }, {date, tonality, sources, region, orderBy, orderDir}) {
            try {
                const params = {
                    region,
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    sources: sources.length ? sources : undefined,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/social/negativesources', {params});
                const resData = res.data;
                commit('setSocialNegativeSourcesList', resData);
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialNegativeSourcesList2({ dispatch, commit }, {date, tonality, sources, region, orderBy, orderDir}) {
            try {
                const params = {
                    region,
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    sources: sources.length ? sources : undefined,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/social/negativesources', {params});
                const resData = res.data;
                commit('setSocialNegativeSourcesList2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        topNegativeSourcesLoaded(state) {
            return state.topNegativeSourcesLoaded;
        },
        topNegativeSources(state) {
            return state.topNegativeSources;
        },
        topNegativeSources2(state) {
            return state.topNegativeSources2;
        },
        socialNegativeSourcesList(state) {
            return state.socialNegativeSourcesList.data;
        },
        socialNegativeSourcesList2(state) {
            return state.socialNegativeSourcesList.data.map(item => state.socialNegativeSourcesList2[item.name]);
        },
    },
};
