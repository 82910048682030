<template>
    <div class="vue-daterange-picker" :class="{ inline: opens === 'inline' }">
        <div
                :class="controlContainerClass"
                @click="onClickPicker"
                ref="toggle"
        >
            <!--
              Allows you to change the input which is visible before the picker opens

              @param {Date} startDate - current startDate
              @param {Date} endDate - current endDate
              @param {object} ranges - object with ranges
            -->
            <slot
                    name="input"
                    :startDate="start"
                    :endDate="end"
                    :ranges="ranges"
            >
                <i class="glyphicon glyphicon-calendar fa fa-calendar"></i>&nbsp;
                <span>{{rangeText}}</span>
                <b class="caret"></b>
            </slot>
        </div>
        <transition name="slide-fade" mode="out-in">
            <div
                    class="daterangepicker dropdown-menu ltr"
                    :class="pickerStyles"
                    v-if="open || opens === 'inline'"
                    v-append-to-body
                    ref="dropdown"
            >

                <!--
                  Optional header slot (same props as footer) @see footer slot for documentation
                -->
                <slot name="header"
                      :rangeText="rangeText"
                      :locale="locale"
                      :clickCancel="clickCancel"
                      :clickApply="clickedApply"
                      :in_selection="in_selection"
                      :autoApply="autoApply"
                >
                </slot>

                <div class="calendars row no-gutters">
                    <!--
                      Allows you to change the range

                      @param {Date} startDate - current startDate
                      @param {Date} endDate - current endDate
                      @param {object} ranges - object with ranges
                      @param {Fn} clickRange(dateRange) - call to select the dateRange - any two date objects or an object from tha ranges array
                    -->
                    <slot
                            name="ranges"
                            :startDate="start"
                            :endDate="end"
                            :ranges="ranges"
                            :clickRange="clickRange"
                            v-if="showRanges"
                    >
                        <calendar-ranges class="col-12 col-md-auto"
                                         @clickRange="clickRange"
                                         @showCustomRange="showCustomRangeCalendars=true"
                                         :always-show-calendars="alwaysShowCalendars"
                                         :locale-data="locale"
                                         :ranges="ranges"
                                         :selected="{ startDate: start, endDate: end }"
                        ></calendar-ranges>
                    </slot>

                    <div class="calendars-container" v-if="showCalendars && !precision">
                        <div class="drp-calendar col left" :class="{single: singleDatePicker}">
                            <div class="daterangepicker_input d-none d-sm-block" v-if="false">
                                <input class="input-mini form-control" type="text" name="daterangepicker_start"
                                       :value="startText"/>
                                <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                            </div>
                            <div class="calendar-table">
                                <calendar-custom :monthDate="monthDate"
                                          :locale-data="locale"
                                          :start="start" :end="end"
                                          :minDate="min" :maxDate="max"
                                          :show-dropdowns="showDropdowns"

                                          @change-month="changeLeftMonth"
                                          :date-format="dateFormatFn"

                                          @dateClick="dateClick" @hoverDate="hoverDate"
                                          :showWeekNumbers="showWeekNumbers"
                                ></calendar-custom>
                            </div>
                            <calendar-time v-if="timePicker && start"
                                           @update="onUpdateStartTime"
                                           :miniute-increment="timePickerIncrement"
                                           :hour24="timePicker24Hour"
                                           :second-picker="timePickerSeconds"
                                           :current-time="start"
                                           :readonly="readonly"
                            />
                        </div>

                        <div class="drp-calendar col right" v-if="!singleDatePicker">
                            <div class="daterangepicker_input" v-if="false">
                                <input class="input-mini form-control" type="text" name="daterangepicker_end"
                                       :value="endText"/>
                                <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                            </div>
                            <div class="calendar-table">
                                <calendar-custom :monthDate="nextMonthDate"
                                          :locale-data="locale"
                                          :start="start" :end="end"
                                          :minDate="min" :maxDate="max"
                                          :show-dropdowns="showDropdowns"

                                          @change-month="changeRightMonth"
                                          :date-format="dateFormatFn"

                                          @dateClick="dateClick" @hoverDate="hoverDate"
                                          :showWeekNumbers="showWeekNumbers"
                                ></calendar-custom>
                            </div>
                            <calendar-time v-if="timePicker && end"
                                           @update="onUpdateEndTime"
                                           :miniute-increment="timePickerIncrement"
                                           :hour24="timePicker24Hour"
                                           :second-picker="timePickerSeconds"
                                           :current-time="end"
                                           :readonly="readonly"
                            />
                        </div>
                    </div>
                    <div class="calendars-container" v-if="showCalendars && precision === 'month'">
                        <div class="drp-calendar col left" :class="{single: singleDatePicker}">
                            <div class="daterangepicker_input d-none d-sm-block" v-if="false">
                                <input class="input-mini form-control" type="text" name="daterangepicker_start"
                                       :value="startText"/>
                                <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
                            </div>
                            <div class="calendar-table">
                                <calendar-month :monthDate="monthDate"
                                          :locale-data="locale"
                                          :start="start" :end="end"
                                          :minDate="min" :maxDate="max"
                                          :show-dropdowns="showDropdowns"

                                          @change-month="changeLeftMonth"
                                          :date-format="dateFormatFn"

                                          @dateClick="dateClick" @hoverDate="hoverDate"
                                          :showWeekNumbers="showWeekNumbers"
                                ></calendar-month>
                            </div>
                        </div>
                    </div>
                </div>
                <!--
                  Allows you to change footer of the component (where the buttons are)

                  @param {string} rangeText - the formatted date range by the component
                  @param {object} locale - the locale object @see locale prop
                  @param {function} clickCancel - function which is called when you want to cancel the range picking and reset old values
                  @param {function} clickApply -function which to call when you want to apply the selection
                  @param {boolean} in_selection - is the picker in selection mode
                  @param {boolean} autoApply - value of the autoApply prop (whether to select immediately)
                -->
                <slot name="footer"
                      :rangeText="rangeText"
                      :locale="locale"
                      :clickCancel="clickCancel"
                      :clickApply="clickedApply"
                      :in_selection="in_selection"
                      :autoApply="autoApply"
                >
                    <div class="drp-buttons" v-if="!autoApply">
                        <span class="drp-selected" v-if="showCalendars">{{rangeText}}</span>
                        <button
                                class="cancelBtn btn btn-sm btn-secondary"
                                type="button"
                                @click="clickCancel"
                                v-if="!readonly"
                        >{{locale.cancelLabel}}
                        </button>
                        <button
                                class="applyBtn btn btn-sm btn-success"
                                :disabled="in_selection"
                                type="button"
                                @click="clickedApply"
                                v-if="!readonly"
                        >{{locale.applyLabel}}
                        </button>
                    </div>
                </slot>
            </div>
        </transition>
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker/src/components/DateRangePicker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import CalendarMonth from '@/components/CalendarMonth';
import CalendarCustom from '@/components/CalendarCustom';

export default {
    name: 'DateRangePickerCustom',
    extends: DateRangePicker,
    props: {
        precision: String,
    },
    components: {
        CalendarCustom,
        CalendarMonth,
    },
    methods: {
        dateClick (value) {
            if(this.readonly)
                return false
            if (this.start === null) {
                this.in_selection = false
            }
            if (this.in_selection) {
                this.in_selection = false
                this.end = this.normalizeDatetime(value, this.end);

                if (this.end < this.start) {
                    this.in_selection = true
                    this.start = this.normalizeDatetime(value, this.start);
                }
                if (!this.in_selection) {
                    this.onSelect();
                    if (this.autoApply)
                        this.clickedApply();
                }
            } else {
                this.start = this.normalizeDatetime(value, this.start);
                this.end = this.normalizeDatetime(value, this.end);
                if (!this.singleDatePicker || this.singleDatePicker === 'range') {
                    this.in_selection = true
                } else {
                    this.onSelect();
                    if (this.autoApply)
                        this.clickedApply();
                }
            }
        },
    }
}
</script>
