<template>
    <div class="soc-negative-messages" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: Коэффициенты негативных сообщений по округам</div>
            </div>
        </div>
        <Form
            :comparedatePosBreakPoints="{620: 'center', 1440: 'left', 10000: 'center'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['dateMonth', 'compareDateMonth']"
            @download="$downloadReport(posts, $event)"
        />
        <div>
            <div class="container">
                <div class="map-chart">
                    <RussianMap />
                </div>
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                    style="margin-bottom: 0"
                >
                    <Posts
                        @click="postClick"
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :postsCompare="postsCompare"
                        :posts="posts"
                    />
                </div>
                <!-- <Pagination /> -->
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import RussianMap from '@/components/Map/RussianMap';
import Pagination from '@/components/Pagination';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";

export default {
    name: 'SocNetNegativeMessages',
    data() {
        return {
            orderData: {},
            postHeaders: {
                name: { title: 'Округ', class: 'td--text td--center', format_number: false, ordering: true, },
                quantity: { title: 'Количество сообщений', class: '', format_number: true, ordering: true, },
                percent: { title: 'Коэффициент', class: '', format_number: false, ordering: true, },
            },
            reportColumns: [ 'name', 'quantity', 'percent' ],
        };
    },
    components: {
        Form,
        Posts,
        Pagination,
        RussianMap,
    },
    mixins: [downloadReport],
    watch: {
        apiQueryParams: {
            handler() {
                setTimeout(() => {
                    this.userAccessSocmedia && this.getSocialProblemMap(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getSocialProblemMap2(this.apiQueryParamsCompare);
                }
            },
            deep: true,
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.getSocialProblemMap(this.apiQueryParams);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialProblemMap2(this.apiQueryParamsCompare);
        }
    },
    computed: {
        ...mapState(['detailization']),
        ...mapGetters([
            'date',
            'compareDate',
            'progressTime',
            'userAccessSocmedia',
        ]),
        ...mapGetters(['socialProblemMap', 'socialProblemMap2']),
        posts() {
            let {orderBy, orderDir} = this.orderData;
            if (orderBy === 'percent') {
                orderBy = 'value';
            }
            // console.log(orderBy, orderDir, this.socialProblemMap);
            return this.socialProblemMap
                .sort((a, b) => {
                    if (orderBy === undefined) {return 0};
                    if (!isNaN(Number(a[orderBy])))
                        return (a[orderBy] - b[orderBy]) * orderDir;
                    else {
                        // console.log(a[orderBy].localeCompare(b[orderBy]));
                        return a[orderBy].localeCompare(b[orderBy]) * orderDir
                    }
                })
                .map(item => ({
                    name: item.name,
                    percent: Math.round(item.value),
                    quantity: item.quantity,
                }));
        },
        postsCompare() {
            let {orderBy, orderDir} = this.orderData;
            if (orderBy === 'percent') {
                orderBy = 'value';
            }
            // console.log(orderBy, orderDir, this.socialProblemMap);
            return this.socialProblemMap2
                .sort((a, b) => {
                    if (orderBy === undefined) {return 0};
                    if (!isNaN(Number(a[orderBy])))
                        return (a[orderBy] - b[orderBy]) * orderDir;
                    else {
                        // console.log(a[orderBy].localeCompare(b[orderBy]));
                        return a[orderBy].localeCompare(b[orderBy]) * orderDir
                    }
                })
                .map(item => ({
                    name: item.name,
                    percent: Math.round(item.value),
                    quantity: item.quantity,
                }));
        },
        apiQueryParams() {
            return {
                date: this.dateLocal,
                compareDate: this.compareDateLocal,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDateLocal,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        dateLocal() {
            return {
                startDate: moment(this.date.startDate, 'DD.MM.YY').startOf('month').format('DD.MM.YY'),
                endDate: moment(this.date.startDate, 'DD.MM.YY').endOf('month').format('DD.MM.YY'),
            };
        },
        compareDateLocal() {
            if (!this.compareDate.startDate) {
                return {};
            }
            return {
                startDate: moment(this.compareDate.startDate, 'DD.MM.YY').startOf('month').format('DD.MM.YY'),
                endDate: moment(this.compareDate.startDate, 'DD.MM.YY').endOf('month').format('DD.MM.YY'),
            };
        },
    },
    methods: {
        ...mapActions([
            'getSocialProblemMap',
            'getSocialProblemMap2',
            'getSocialModalMessages',
        ]),
        postClick(post) {
            this.userAccessSocmedia && this.getSocialModalMessages({
                district: post.name,
                ...this.apiQueryParams
            });
        },
    }
};
</script>

<style lang="scss">
.soc-negative-messages {
    .map-chart {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-bottom: 48px;
    }

    .form-field.comparedate .opensleft {
        transform: translateX(calc(50% + 52px));
    }
}

@media (max-width: 1032px) and (min-width: 1014px) {
    .soc-negative-messages .form-field.comparedate .opensleft {
        transform: translateX(28%);
    }
}
@media (max-width: 980px) {
    .soc-negative-messages {
        .form-field {
            &.tonality,
            &.region {
                min-width: 170px;
            }
            &.tonality {
                margin-right: calc(30 / 920 * 100%);
            }
        }
    }
}
@media (max-width: 931px) {
    .soc-negative-messages {
        .form-field {
            &.comparedate .opensleft {
                transform: none;
            }
        }
    }
}
</style>
