<template>
    <div class="soc-quantity" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: Динамика по охвату и количеству публикаций
                </div>
            </div>
        </div>
        <Form
            :fieldsList="['fastChoose', 'date', 'detailization', 'tonality', 'region', 'compareDate']"
            @download="$downloadReport(socialProblems, $event)"
        />
        <div>
            <div class="container">
                <LineChart2Component />
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                    style="margin-bottom: 0"
                >
                    <Posts
                        v-model="orderData"
                        :headers="postHeaders"
                        :postsCompare="socialProblems2"
                        :posts="socialProblems"
                        @click="postClick"
                    />
                </div>
                <!-- <Pagination /> -->
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import Pagination from '@/components/Pagination';
import LineChart2Component from '@/components/LineChart2Component';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SocNetQuantity',
    data() {
        return {
            orderData: {},
            postHeaders: {
                title1: { title: 'Проблема 1 ур', class: 'td--text td--center', format_number: false, ordering: true },
                title2: { title: 'Проблема 2 ур', class: 'td--text td--center', format_number: false, ordering: true },
                quantity: { title: 'Количество публикаций', class: '', format_number: true, ordering: true },
                coverage: { title: 'Охват', class: '', format_number: true, ordering: true },
                involvement: { title: 'Вовлеченность', class: '', format_number: true, ordering: true },
            },
            reportColumns: [ 'title1', 'title2', 'quantity', 'coverage', 'involvement' ],
            timeout: null,
            timeout2: null,
        };
    },
    components: {
        Form,
        Posts,
        Pagination,
        LineChart2Component,
    },
    mixins: [downloadReport],
    watch: {
        apiQueryParams: {
            handler() {
                if (!this.userAccessSocmedia) return;
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.getSocialQuantityAndCoverage(this.apiQueryParams);
                }, 200);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (!this.userAccessSocmedia) return;
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    clearTimeout(this.timeout2);
                    this.timeout2 = setTimeout(() => {
                        this.getSocialQuantityAndCoverage2(this.apiQueryParamsCompare);
                    }, 200);
                }
            },
            deep: true,
        },
        apiQueryParamsList: {
            handler() {
                if (!this.userAccessSocmedia) return;
                clearTimeout(this.timeout3);
                this.timeout3 = setTimeout(() => {
                    this.getSocialProblems(this.apiQueryParamsList);
                }, 200);
            },
            deep: true,
        },
        apiQueryParamsListCompare: {
            handler() {
                if (!this.userAccessSocmedia) return;
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    clearTimeout(this.timeout4);
                    this.timeout4 = setTimeout(() => {
                        this.getSocialProblems2(this.apiQueryParamsListCompare);
                    }, 200);
                }
            },
            deep: true,
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.getSocialQuantityAndCoverage(this.apiQueryParams);
        this.getSocialProblems(this.apiQueryParamsList);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialQuantityAndCoverage2(this.apiQueryParamsCompare);
            this.getSocialProblems2(this.apiQueryParamsListCompare);
        }
    },
    computed: {
        ...mapState(['detailization']),
        ...mapGetters([
            'date',
            'compareDate',
            'socialProblems',
            'progressTime',
            'userAccessSocmedia',
            'region',
            'tonality',
        ]),
        ...mapGetters({
            socialProblems2Global: "socialProblems2",
        }),
        socialProblems2() {
            return this.compareDate.startDate ? this.socialProblems2Global : [];
        },
        apiQueryParams() {
            return {
                date: this.date,
                compareDate: this.compareDate,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                tonality: this.tonality,
                region: this.region,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                tonality: this.tonality,
                region: this.region,
            }
        },
        apiQueryParamsList() {
            return {
                date: this.date,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                tonality: this.tonality,
                region: this.region,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsListCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                tonality: this.tonality,
                region: this.region,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
    },
    methods: {
        ...mapActions([
            'getSocialQuantityAndCoverage',
            'getSocialQuantityAndCoverage2',
            'getSocialProblems',
            'getSocialProblems2',
            'getSocialModalMessages',
        ]),
        postClick(post) {
            this.userAccessSocmedia && this.getSocialModalMessages({
                problem2: post.title2,
                ...this.apiQueryParams
            });
        }
    }
};
</script>

<style lang="scss">
.soc-quantity {
    padding-top: 52px;
    .form-section {
        margin-top: 0;
    }
    .section-subtitle {
        margin-bottom: 30px;
    }
}

@media (max-width: 1000px) {
    .soc-quantity {
        padding-top: 35px;
        .section-subtitle {
            margin-bottom: 20px;
        }
    }
}
@media (max-width: 768px) {
    .soc-quantity {
        padding-top: 22px;
        .section-subtitle {
            margin-bottom: 15px;
        }
    }
}
</style>
