import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        selectedPressSources: [],
        socialProblemDistrictsLoaded: false,
        socialProblemDistrictsMinLoaded: false,
        socialProblemDistricts: [],
        socialProblemDistricts2: {},
        socialProblemDistrictsMin: [],
        socialProblemDistrictsMin2: {},
        socialProblemDistrictMessages: {
            data: [],
            total: 0,
        },
        socialProblemDistrictMessages2: {},
        pressPosts: [],
        pressPostsPages: 1,
        percents: 1,
    },
    mutations: {
        setSelectedPressSources(state, val) {
            state.selectedPressSources = val;
        },
        setSocialProblemDistricts(state, val) {
            state.socialProblemDistricts = val || [];
            state.socialProblemDistrictsLoaded = true;
        },
        setSocialProblemDistricts2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item.value;
            });
            state.socialProblemDistricts2 = data;
        },
        setSocialProblemDistrictsMin(state, val) {
            state.socialProblemDistrictsMin = val || [];
            state.socialProblemDistrictsMinLoaded = true;
        },
        setSocialProblemDistrictsMin2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item.value;
            });
            state.socialProblemDistrictsMin2 = data;
        },
        setSocialProblemDistrictMessages(state, val) {
            state.socialProblemDistrictMessages = val;
        },
        setSocialProblemDistrictMessages2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.title2] = item;
            });
            state.socialProblemDistrictMessages2 = data;
        },
        setPressPosts(state, val) {
            state.pressPosts = val;
        },
        setPercents(state, val) {
            state.percents = val;
        },
    },
    actions: {
        async getSocialProblemDistricts({ state, dispatch, commit }, {date, tonality, orderdir = -1, limit, percents = 1, region}) {
            try {
                if (state.percents !== percents) {
                    commit('setSocialProblemDistricts2', []);
                }
                const res = await axios.get('/social/problemdistricts', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                        orderdir,
                        limit,
                        percents,
                        region,
                    }
                });
                commit('setPercents', !!percents);
                const resData = res.data;
                if (orderdir === -1) {
                    commit('setSocialProblemDistricts', resData.data);
                } else {
                    commit('setSocialProblemDistrictsMin', resData.data);
                }
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialProblemDistricts2({ state, dispatch, commit }, {date, tonality, orderdir = -1, limit, percents = 1, region, names}) {
            try {
                if (state.percents !== percents) {
                    commit('setSocialProblemDistricts2', []);
                }
                const res = await axios.get('/social/problemdistricts', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                        orderdir,
                        limit,
                        names,
                        percents,
                        region,
                    }
                });
                const resData = res.data;
                if (orderdir === -1) {
                    commit('setSocialProblemDistricts2', resData.data);
                } else {
                    commit('setSocialProblemDistrictsMin2', resData.data);
                }
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialProblemDistrictMessages({ state, dispatch, commit }, {date, tonality, districts, region, orderBy, orderDir, page, limit, justReturn}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    districts,
                    region,
                    orderby: orderBy,
                    orderdir: orderDir,
                    page,
                    limit: limit || state.perPage,
                };
                const res = await axios.get('/social/problemdistrictmessages', {params});
                const resData = res.data;
                if (!justReturn) {
                    commit('setSocialProblemDistrictMessages', resData);
                }
                return resData.data;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialProblemDistrictMessages2({ state, dispatch, commit }, {date, tonality, districts, region, orderBy, orderDir, page}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    districts,
                    region,
                    orderby: orderBy,
                    orderdir: orderDir,
                    page,
                    limit: state.perPage,
                };
                const res = await axios.get('/social/problemdistrictmessages', {params});
                const resData = res.data;
                commit('setSocialProblemDistrictMessages2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressPosts({ state, dispatch, commit }, {date, tonality, sources, page = 1, region}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    page,
                    perpage: state.perPage,
                    region,
                };
                if (sources && sources.length) {
                    params.sources = sources;
                }
                const res = await axios.get('/smi/posts', {params});
                const resData = res.data;
                commit('setPressPosts', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        socialProblemDistrictsLoaded(state) {
            return state.socialProblemDistrictsLoaded;
        },
        socialProblemDistrictsMinLoaded(state) {
            return state.socialProblemDistrictsMinLoaded;
        },
        socialProblemDistricts(state) {
            return state.socialProblemDistricts;
        },
        socialProblemDistricts2(state) {
            return state.socialProblemDistricts2;
        },
        socialProblemDistrictsMin(state) {
            return state.socialProblemDistrictsMin;
        },
        socialProblemDistrictsMin2(state) {
            return state.socialProblemDistrictsMin2;
        },
        socialProblemDistrictMessages(state) {
            return state.socialProblemDistrictMessages.data;
        },
        socialProblemDistrictMessagesPages(state) {
            return Math.max(1,
                Math.ceil((state.socialProblemDistrictMessages.total || 0 ) / state.perPage)
            );
        },
        socialProblemDistrictMessages2(state) {
            return state.socialProblemDistrictMessages.data.map(item => state.socialProblemDistrictMessages2[item.name]);
        },
        // pressPosts(state) {
        //     return state.pressPosts.data;
        // },
        // pressPostsPages(state) {
        //     return Math.max(1,
        //         Math.ceil((state.pressPosts.total || 0 ) / state.perPage)
        //     );
        // }
    },
};
