<template>
    <div class="timer">{{ timeNow }}</div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'Timer',
    props: {
        live: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            timeNow: this.getTimeNow(),
            timer: null,
        };
    },
    mounted() {
        this.startInterval();
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        live() {
            if (!this.live) {
                clearInterval(this.timer);
            } else {
                this.startInterval();
            }
        },
    },
    methods: {
        getTimeNow() {
            return moment().format('HH:mm:ss');
        },
        startInterval() {
            if (this.live) {
                this.timer = setInterval(() => {
                    this.timeNow = this.getTimeNow();
                }, 1000);
            } else {
                this.timeNow = this.getTimeNow();
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/styles/vars.scss';

.timer {
    color: $c-white;
    margin-right: 32px;
    font-size: 1.4rem;
    line-height: (18/14);
}
.skin-magnit {
    .timer {
        color: #1f2020;
    }
}

@media (min-width: 2320px) {
    .timer {
        font-size: 2.2rem;
        margin-right: 46px;
    }
}

@media (max-width: 1200px) {
    .timer {
        font-size: 1.3rem;
        margin-right: 22px;
    }
}

@media (max-width: 992px) {
    .timer {
        font-size: 1.2rem;
        margin-right: 18px;
    }
}

@media (max-width: 768px) {
    .timer {
        font-size: 1rem;
        margin-right: 10px;
    }
}
</style>
