<template>
    <section>
        <div class="container">
            <div class="row row--gapped">
                <div class="col-w-50">
                    <router-link
                        :to="$url('/socnet-topnegativesubjects?orderDir=-1')"
                        class="section-subtitle"
                    >Топ-5 субъектов по наибольшему негативу
                    </router-link>
                    <div class="card">
                        <Loading v-if="!chartData.labels || !chartData.labels.length" :loaded="socialProblemDistrictsLoaded"/>
                        <BarChartHorizontal
                            v-else
                            :height="styles.height"
                            :styles="styles"
                            :chart-data="chartData"
                            :chart-options="chartOptions"
                        />
                    </div>
                </div>
                <div class="col-w-50">
                    <router-link
                        :to="$url('/socnet-topnegativesubjects?orderDir=1')"
                        class="section-subtitle"
                    >Топ-5 субъектов по наименьшему негативу
                    </router-link>
                    <div class="card">
                        <Loading v-if="!chartData2.labels || !chartData2.labels.length" :loaded="socialProblemDistrictsMinLoaded"/>
                        <BarChartHorizontal
                            v-else
                            :height="styles2.height"
                            :styles="styles2"
                            :chart-data="chartData2"
                            :chart-options="chartOptions2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from 'vuex';
import BarChartHorizontal from './Charts/BarChartHorizontal';
import Loading from './Loading';

export default {
    name: 'TopGeoBar',
    components: {
        BarChartHorizontal,
        Loading,
    },
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'region',
            'tonality',
            'detailization',
            'windowWidth',
            'horizonBarChartMediaPoints',
            'socialProblemDistricts',
            'socialProblemDistrictsMin',
            'socialProblemDistrictsLoaded',
            'socialProblemDistrictsMinLoaded',
        ]),
        ...mapGetters({
            socialProblemDistricts2Global: "socialProblemDistricts2",
            socialProblemDistrictsMin2Global: "socialProblemDistrictsMin2",
        }),
        socialProblemDistricts2() {
            return this.compareDate.startDate ? this.socialProblemDistricts2Global : {};
        },
        socialProblemDistrictsMin2() {
            return this.compareDate.startDate ? this.socialProblemDistrictsMin2Global : {};
        },
        fillDataParams() {
            return {
                horizonBarChartMediaPoints: this.horizonBarChartMediaPoints,
                socialProblemDistricts: this.socialProblemDistricts,
                socialProblemDistricts2: this.socialProblemDistricts2,
                socialProblemDistrictsMin: this.socialProblemDistrictsMin,
                socialProblemDistrictsMin2: this.socialProblemDistrictsMin2,
            }
        }
    },
    watch: {
        fillDataParams: {
            handler(val, oldVal) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.fillData();
                }, 500);
            },
            deep: true,
        },
    },
    data() {
        return {
            timeout: null,
            chartData: {},
            chartData2: {},
            chartOptions: {
                innerPadding: 18,
                cornerRadius: 0.8,
                fillTextSuffix: '%',
                scales: {
                    yAxes: [
                        {
                            barThickness: 18,
                            maxBarThickness: 18,
                            ticks: {
                                padding: 6,
                                fontSize: 14,
                            },
                        },
                    ],
                },
            },
            chartOptions2: {
                innerPadding: 18,
                cornerRadius: 0.8,
                fillTextSuffix: '%',
                scales: {
                    yAxes: [
                        {
                            barThickness: 18,
                            maxBarThickness: 18,
                            ticks: {
                                padding: 6,
                                fontSize: 14,
                            },
                        },
                    ],
                },
            },
            styles: {
                height: 0,
            },
            styles2: {
                height: 0,
            },
        };
    },
    created() {
        this.setMediaOptions('chartOptions');
        this.setMediaOptions('chartOptions2');
    },
    methods: {
        fillData() {
            this.setMediaOptions('chartOptions');
            this.setMediaOptions('chartOptions2');
            let labels = this.socialProblemDistricts.map(item => item.name).slice(0, 5);
            let data = this.socialProblemDistricts.map(item => item.value).slice(0, 5);
            //this.styles.height = data.length * 50.5 + 'px'
            let height = data.length ? (data.length + 1) * this.chartOptions.scales.yAxes[0].maxBarThickness + data.length * this.chartOptions.innerPadding : 0;
            let datasets = [
                {
                    defaultFontColor: '#555555',
                    backgroundColor: '#28b93c',
                    borderColor: '#28b93c',
                    borderWidth: 10,
                    data,
                },
            ];
            if (this.compareDate.startDate) {
                datasets.push({
                    defaultFontColor: '#FFF',
                    backgroundColor: '#878787',
                    borderColor: '#878787',
                    borderWidth: 10,
                    data: labels.map(label => (this.socialProblemDistricts2[label] || 0)),
                });
            }
            this.chartData = {
                labels: window.fixWordWrap(labels, 18),
                datasets,
            };

            labels = this.socialProblemDistrictsMin.map(item => item.name).slice(0, 5);
            data = this.socialProblemDistrictsMin.map(item => item.value).slice(0, 5);

            let height2 = data.length ? (data.length + 1) * this.chartOptions2.scales.yAxes[0].maxBarThickness + data.length * this.chartOptions2.innerPadding : 0;
            if (this.windowWidth >= 1000) {
                height = height2 = Math.max(height, height2);
            }
            this.styles.height = height;
            this.styles2.height = height2;

            datasets = [
                {
                    defaultFontColor: '#555555',
                    backgroundColor: '#28b93c',
                    borderColor: '#28b93c',
                    borderWidth: 10,
                    data,
                },
            ];
            if (this.compareDate.startDate) {
                datasets.push({
                    defaultFontColor: '#FFF',
                    backgroundColor: '#878787',
                    borderColor: '#878787',
                    borderWidth: 10,
                    data: labels.map(label => Math.round(this.socialProblemDistrictsMin2[label] || 0)),
                });
            }
            this.chartData2 = {
                labels: window.fixWordWrap(labels, 20),
                datasets,
            };
        },
        setMediaOptions(opt) {
            const obj = JSON.parse(JSON.stringify(this[opt]));
            if (this.horizonBarChartMediaPoints === 'MOBILE') {
                obj.scales.yAxes[0].ticks.fontSize = 10;
                obj.scales.yAxes[0].ticks.padding = 1;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 16;
                obj.scales.yAxes[0].barThickness = 15;
                obj.scales.yAxes[0].maxBarThickness = 15;
            } else if (this.horizonBarChartMediaPoints === 1000) {
                obj.scales.yAxes[0].ticks.fontSize = 12;
                obj.scales.yAxes[0].ticks.padding = 1;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 26;
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === 1300) {
                obj.scales.yAxes[0].ticks.fontSize = 12;
                obj.scales.yAxes[0].ticks.padding = 12;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 26;
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === 'DESKTOP') {
                obj.scales.yAxes[0].ticks.fontSize = 14;
                obj.scales.yAxes[0].ticks.padding = 40;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 26;
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === '4K') {
                obj.scales.yAxes[0].ticks.fontSize = 22;
                obj.scales.yAxes[0].ticks.padding = 22;
                obj.cornerRadius = 1.2;
                obj.innerPadding = 41;
                obj.scales.yAxes[0].barThickness = 29;
                obj.scales.yAxes[0].maxBarThickness = 29;
            }
            this[opt] = obj;
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
    },
};
</script>

<style lang="scss" scoped>
.card {
    padding-top: 18px;
    padding-left: 19px;
    padding-right: 42px;
    padding-bottom: 14px;
}

@media (max-width: 1300px) {
    .col-w-50 {
        width: calc(50% - 7px);
    }
    .card {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (max-width: 1000px) {
    .row {
        flex-wrap: wrap;
    }
    .card {
        padding: 8px calc(22 / 588 * 100%);
    }
    .col-w-50 {
        width: 100%;
        min-width: unset;
    }
}

@media (max-width: 768px) {
    .card {
        padding: 3px calc(16 / 360 * 100%);
    }
}
</style>
