<template>
    <section
        class="section--dark"
        id="widgets"
    >
        <div class="container">
            <h2 class="section-title">Сводка</h2>
            <TotalWidget
                v-for="widget in widgetTotals"
                :title="widget.title"
                :totals="widget.totals"
                :key="widget.title"
            />
        </div>
    </section>
</template>

<script>
import TotalWidget from '@/components/Widgets/TotalWidget';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'TotalsWidget',
    components: {
        TotalWidget,
    },
    data() {
        return {
            widgets: [],
        };
    },
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'progressTime',
            'widgetTotals',
            'tonality',
            'userAccessSmi',
            'userAccessSocmedia',
            'region',
        ]),
        apiQueryParams() {
            return {
                date: this.date,
                tonality: this.tonality,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                tonality: this.tonality,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
            }
        }
    },
    methods: {
        ...mapActions([
          'getPressTotals',
          'getPressTotals2',
          'getSocialTotals',
          'getSocialTotals2',
        ]),
        getData() {
            this.userAccessSmi && this.getPressTotals(this.apiQueryParams);
            this.userAccessSocmedia && this.getSocialTotals(this.apiQueryParams);
        },
        getDataCompare() {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.userAccessSmi && this.getPressTotals2(this.apiQueryParamsCompare);
                this.userAccessSocmedia && this.getSocialTotals2(this.apiQueryParamsCompare);
            }
        },
    },
    watch: {
        apiQueryParams: {
            handler() {
                this.getData();
            },
            deep: true
        },
        apiQueryParamsCompare: {
            handler() {
                this.getDataCompare()
            },
            deep: true
        },
    },
    mounted() {
        this.widgets = [];
        this.getData();
        this.getDataCompare()
    },
};
</script>

<style lang="scss" scoped>
section {
    margin-top: 0;
    padding: 48px 0;
}

.section-title{
    margin-top: 0;
}

@media (min-width: 2320px) {
    section {
        padding: 78px 0;
    }
}

@media (max-width: 1200px) {
    section {
        padding: 40px 0;
    }
}

@media (max-width: 1000px) {
    section {
        padding: 30px 0;
    }
}
</style>
