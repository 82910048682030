<template>
    <div v-if="pages > 1" class="pagination-wrap">
        <div
            :style="{ opacity: currentTab === 1 ? 0 : 1, 'pointer-events': currentTab === 1 ? 'none' : null}"
            class="pagination-item left"
            @click="toggleTab('prev')"
        >
            <ArrowLeft/>
        </div>
        <div
            :class="['pagination-item', currentTab === t ? 'active' : null]"
            v-for="(t, i) in tabs"
            :key="i"
            @click="toggleTab(t)"
        >
            {{t}}
        </div>
        <div
            :style="{ opacity: currentTab >= pages ? 0 : 1, 'pointer-events': currentTab >= pages ? 'none' : null}"
            class="pagination-item right"
            @click="toggleTab('next')"
        >
            <ArrowRight />
        </div>
    </div>
</template>

<script>
import ArrowLeft from '@/components/icons/ArrowLeft';
import ArrowRight from '@/components/icons/ArrowRight';

export default {
    name: 'Pagination',
    model: {
        event: 'change',
        prop: 'page',
    },
    data() {
        return {
            currentTab: 1,
        };
    },
    props: {
        page: {
            type: Number,
            default: 1
        },
        pages: {
            type: Number,
            default: 5
        }
    },
    watch: {
        currentTab() {
            this.$emit('change', this.currentTab);
        },
        page(page) {
            this.currentTab = page;
        },
        pages(pages) {
            if (this.currentTab > pages) {
                this.currentTab = pages;
            }
        },
    },
    computed: {
        tabs() {
            const tabs = [];
            for (let i = 1; i <= this.pages; i++) {
                if (this.currentTab + 3 < this.pages) {
                    if (i !== 1 && i < this.currentTab - 3) {
                        continue;
                    }
                } else {
                    if (i !== 1 && i < this.pages -7) {
                        continue;
                    }
                }
                tabs.push(i);
                if (tabs.length === 6 && i !== this.pages) {
                    tabs.push(this.pages);
                    break;
                }
            }
            return tabs;
        },
    },
    methods: {
        toggleTab(dir) {
            if (dir === 'prev' && this.currentTab > 1) {
                this.currentTab--;
            } else if (dir === 'next' && this.currentTab < this.pages) {
                this.currentTab++;
            } else if (typeof dir === 'number') {
                this.currentTab = dir;
            }
        },
    },
    components: {
        ArrowLeft,
        ArrowRight,
    },
};
</script>


<style lang="scss" scoped>
@import '@/styles/vars.scss';

.pagination {
    &-wrap {
        display: flex;
        justify-content: center;
    }
    &-item {
        user-select: none;
        cursor: pointer;
        transition: $transition;
        min-width: 18px;
        width: 27px;
        box-sizing: border-box;
        font-size: 1.5rem;
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        margin-right: 12px;
        border: 1px solid $c-grey4;
        border-radius: 4px;
        &.right {
            margin-right: 0;
        }
        &.left,
        &.right {
            padding: 5px 4px;
            svg {
                fill: #1c1c1c;
            }
            &:hover {
                background-color: $c-primary;
                border-color: $c-primary;
                svg {
                    fill: #fff;
                }
            }
            &.active {
                background-color: $c-primary2;
                border-color: $c-primary2;
                svg {
                    fill: #fff;
                }
            }
        }

        &:hover {
            border-color: $c-primary2;
            color: $c-primary2;
        }
        &.active {
            border-color: $c-primary;
            color: $c-primary;
        }
    }
}
</style>
