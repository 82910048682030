<template>
    <label :class="{'radio-label': true, 'checked': optionValue === period}">
		<span class="radio-label-title">
			<slot></slot>
		</span>
        <input
                type="radio"
                :name="name"
                :value="optionValue"
                :checked="optionValue === value"
                :disabled="disabled"
                :required="required"
                :hidden="hidden"
                @click="updateValue(optionValue)"
        >
    </label>
</template>

<script>
export default {
    model: {
        event: 'change',
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [ String, Number, Boolean, Object ],
            default: null,
        },
        optionValue: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
        period: {
            type: String,
            default: '',
        },
    },
    methods: {
        updateValue(value) {
            this.$emit('change', value)
        },
    },
}
</script>
<style lang="scss" scoped>
.radio-label-title {
    display: inline-block;
}
</style>
