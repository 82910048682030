<template>
    <div @click="$emit('toggleLive')">
        <svg
            :width="width"
            :height="height"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 6.76795C16.3333 7.53775 16.3333 9.46225 15 10.2321L3.75 16.7272C2.41667 17.497 0.750001 16.5348 0.750001 14.9952L0.750002 2.00481C0.750002 0.465209 2.41667 -0.497042 3.75 0.272758L15 6.76795Z"
                fill="#28b93c"
            />
        </svg>
    </div>
</template>

<script>
//7.96973 x2
export default {
    name: 'Pause',
    props: {
        width: {
            type: Number,
            required: false,
            default: 16,
        },
        height: {
            type: Number,
            required: false,
            default: 17,
        },
    },
    computed: {
        x2() {
            return this.width - this.rectWidth;
        },
    },
};
</script>