<template>
    <section
        id='social'
        class="line-chart-wrap"
    >
        <div class="container">
            <div class="section-title">Социальные сети</div>
            <router-link
                :to="$url('/socnet-quantity')"
                class="section-subtitle"
            >Динамика по охвату и количеству публикаций
            </router-link>
            <LineChart2Component />
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import LineChart2Component from '@/components/LineChart2Component';

export default {
    name: 'SocialNetworks',
    components: {
        LineChart2Component,
    },
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'region',
            'tonality',
            'detailization',
        ]),
    },
};
</script>

<style lang="scss" scoped>
</style>
