<template>
  <div class="home">
    <Form :fieldsList="['fastChoose', 'date', 'detailization', 'tonality', 'region', 'compareDate']"
          :tableReport="false"/>
    <HomeNavigation/>
    <TotalsWidget/>
    <Press v-if="userAccessSmi"/>
    <PressBar v-if="userAccessSmi"/>
    <SocialNetworks v-if="userAccessSocmedia"/>
    <NegativeCategoryBar v-if="userAccessSocmedia"/>
    <NegativeMessageRegions v-if="userAccessSocmedia"/>
    <TopGeoBar v-if="userAccessSocmedia"/>
    <ReputationMatrixBubble v-if="userAccessSocmedia"/>
    <RiskyMentions v-if="userAccessSocmedia"/>
  </div>
</template>

<script>
  import Form from '@/components/Form/Form';
  import HomeNavigation from '@/components/HomeNavigation';
  import TotalsWidget from '@/components/Widgets/TotalsWidget';
  import Press from '@/components/Press';
  import SocialNetworks from '@/components/SocialNetworks';
  import PressBar from '@/components/PressBar';
  import NegativeCategoryBar from '@/components/NegativeCategoryBar';
  import TopGeoBar from '@/components/TopGeoBar';
  import NegativeMessageRegions from '@/components/NegativeMessageRegions';
  import ReputationMatrixBubble from '@/components/ReputationMatrixBubble';
  import RiskyMentions from '@/components/RiskyMentions';
  import Posts from '@/components/Posts';
  import {mapActions, mapGetters, mapState} from "vuex";
  import moment from "moment";

  export default {
    name: 'Home',
    components: {
      Form,
      HomeNavigation,
      TotalsWidget,
      Press,
      SocialNetworks,
      PressBar,
      NegativeCategoryBar,
      TopGeoBar,
      NegativeMessageRegions,
      ReputationMatrixBubble,
      RiskyMentions,
      Posts,
    },
    data() {
      return {
        timeout: null,
        timeout2: null,
        timeout10: null,
        timeout11: null,
        timeout12: null,
      };
    },
    watch: {
      apiQueryParams: {
        handler() {
          setTimeout(() => {
            this.getData();
          }, 500);
        },
        deep: true,
      },
      apiQueryParamsCompare: {
        handler() {
          this.getCompareData();
        },
        deep: true,
      },
      socialProblemDistricts() {
        clearTimeout(this.timeout10);
        this.timeout10 = setTimeout(() => {
          if (this.socialProblemDistricts && this.socialProblemDistricts.length && this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialProblemDistricts2({
              ...this.apiQueryParamsCompare,
              tonality: 'negative',
              names: this.socialProblemDistricts.map(el => el.name),
            });
          }
        }, 800);
      },
      socialProblemDistrictsMin() {
        clearTimeout(this.timeout11);
        this.timeout11 = setTimeout(() => {
          if (this.socialProblemDistrictsMin && this.socialProblemDistrictsMin.length && this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialProblemDistricts2({
              ...this.apiQueryParamsCompare,
              names: this.socialProblemDistrictsMin.map(el => el.name),
              orderdir: 1,
            });
          }
        }, 800);
      },
      socialRiskyMentions() {
        clearTimeout(this.timeout12);
        this.timeout12 = setTimeout(() => {
          if (this.socialRiskyMentions && this.socialRiskyMentions.length && this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialRiskyMentions2({
              ...this.apiQueryParamsCompare,
              names: this.socialRiskyMentions.map(el => el.name),
            });
          }
        }, 800);
      },
    },
    mounted() {
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
      this.timeout2 = setTimeout(() => {
        this.getCompareData();
      }, 500);
    },
    computed: {
      ...mapState(['detailization']),
      ...mapGetters([
        'date',
        'tonality',
        'compareDate',
        'progressTime',
        'userAccessSmi',
        'userAccessSocmedia',
        'region',
        'socialProblemDistricts',
        'socialProblemDistrictsMin',
        'socialRiskyMentions',
      ]),
      apiQueryParams() {
        return {
          date: this.date,
          tonality: this.tonality,
          detailization: this.detailization,
          progressTime: this.progressTime,
          userAccessSmi: this.userAccessSmi,
          userAccessSocmedia: this.userAccessSocmedia,
          region: this.region,
        };
      },
      apiQueryParamsCompare() {
        return {
          date: this.compareDate,
          tonality: this.tonality,
          detailization: this.detailization,
          progressTime: this.progressTime,
          userAccessSmi: this.userAccessSmi,
          userAccessSocmedia: this.userAccessSocmedia,
          region: this.region,
        };
      },
    },
    methods: {
      ...mapActions([
        'getPressMentions',
        'getPressMentions2',
        'getSocialQuantityAndCoverage',
        'getSocialQuantityAndCoverage2',
        'getSocialProblemDistricts',
        'getSocialProblemDistricts2',
        'getSocialRiskyMentions',
        'getSocialRiskyMentions2',
        'getSocialReputationMatrix',
        'getSocialReputationMatrix2',
        'getSocialProblemMap',
        'getSocialProblemMap2',
        'getInfoFeed',
      ]),
      getData() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getPressMentions(this.apiQueryParams);
          this.getSocialQuantityAndCoverage(this.apiQueryParams);
          this.getSocialProblemDistricts({...this.apiQueryParams, tonality: 'negative'});
          this.getSocialProblemDistricts({...this.apiQueryParams, orderdir: 1});
          this.getSocialRiskyMentions(this.apiQueryParams);
          this.getSocialReputationMatrix(this.apiQueryParams);
          this.getSocialProblemMap({
            ...this.apiQueryParams,
            date: {
              startDate: moment().subtract('30', 'days').format('DD.MM.YY'),
              endDate: moment().format('DD.MM.YY'),
            },
          });
          this.getInfoFeed({
            ...this.apiQueryParams,
            orderBy: 'mediaIndex',
            orderDir: -1,
            limit: 5,
          });
        }, 500);
      },
      getCompareData() {
        clearTimeout(this.timeout2);
        this.timeout2 = setTimeout(() => {
          if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getPressMentions2(this.apiQueryParamsCompare);
            this.getSocialQuantityAndCoverage2(this.apiQueryParamsCompare);
            if (this.socialProblemDistricts && this.socialProblemDistricts.length) {
              this.getSocialProblemDistricts2({
                ...this.apiQueryParamsCompare,
                tonality: 'negative',
                names: this.socialProblemDistricts.map(el => el.name),
              });
            }
            if (this.socialProblemDistrictsMin && this.socialProblemDistrictsMin.length) {
              this.getSocialProblemDistricts2({
                ...this.apiQueryParamsCompare,
                names: this.socialProblemDistrictsMin.map(el => el.name),
                orderdir: 1,
              });
            }
            if (this.socialRiskyMentions && this.socialRiskyMentions.length) {
              this.getSocialRiskyMentions2({
                ...this.apiQueryParamsCompare,
                names: this.socialRiskyMentions.map(el => el.name),
              });
            }
            this.getSocialReputationMatrix2(this.apiQueryParamsCompare);
            this.getSocialProblemMap2({
              ...this.apiQueryParamsCompare,
              date: {
                startDate: moment().subtract('60', 'days').format('DD.MM.YY'),
                endDate: moment().subtract('30', 'days').format('DD.MM.YY'),
              },
            });
          }
        }, 500);
      },
    },
  };
</script>
