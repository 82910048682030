<template>
    <div class="dots-wrap">
        <template v-if="!loaded">
            <div class="dots">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
            </div>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="9" />
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -7" result="goo" />
                        <feBlend in2="goo" in="SourceGraphic" result="mix" />
                    </filter>
                </defs>
            </svg>
        </template>
        <div v-else class="no-data">
            Нет данных
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        data() {
            return {
            };
        },
        props: {
            loaded: Boolean,
            default: false,
        },
    };
</script>

<style lang="scss">
    @import '@/styles/vars.scss';
    /*
Inspired by https://dribbble.com/shots/2235810-Loader-v6
*/

    $dot-color: $c-primary;
    $dot-size: 20px;
    $circle-size: 55px;

    $animation-time: 5s;


    .dots {
        width: 0;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        filter: url(#goo);
        &-wrap {
            position: relative;
            width: 100%;
            min-height: 380px;
        }
    }

    .no-data {
        font-size: 22px;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dot {
        width: 0;
        height: 0;
        position: absolute;
        left: 0;
        top: 0;

        &:before {
            content: '';
            width: $dot-size;
            height: $dot-size;
            border-radius: 50%;
            background: $dot-color;
            position: absolute;
            left: 50%;
            top: -($circle-size);
            margin-left: -($dot-size/2);
            margin-top: -($dot-size/2);
        }

        @for $i from 0 to 11 {

            @keyframes dot-#{$i} {
                               0% {
                                   transform: rotate(180deg + $i * (360deg / 11));
                               }

                               100% {
                                   transform: rotate(180deg + ($i + 1) * (5 * 360deg / 11) + $i * (360deg / 11));
                               }
                           }

            &:nth-child(#{$i + 1}) {
                transform: rotate($i * (360deg / 11));
                animation: dot-#{$i} $animation-time cubic-bezier(.3, .0, .7, 1) infinite;
            }
        }
    }
</style>
