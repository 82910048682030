<template>
    <div @click="$emit('toggleLive')">
        <svg
                :width="width"
                :height="Math.round(height)"
                :viewBox="`0 0 ${width} ${Math.round(height)}`"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                    x="0.979492"
                    y="0.0141602"
                    :width="rectWidth"
                    :height="height"
                    fill="#28b93c"
            />
            <rect
                    :x="x2"
                    y="0.0141602"
                    :width="rectWidth"
                    :height="height"
                    fill="#28b93c"
            />
        </svg>
    </div>
</template>

<script>
//7.96973 x2
export default {
    name: 'Pause',
    props: {
        height: {
            type: Number,
            required: false,
            default: 19.9726,
        },
        width: {
            type: Number,
            required: false,
            default: 11,
        },
        rectWidth: {
            type: Number,
            required: false,
            default: 2.99589,
        },
    },
    computed: {
        x2() {
            return this.width - this.rectWidth
        },
    },
}
</script>
