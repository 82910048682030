import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        socialReputationMatrixProblems: [],
        selectedPressSources: [],
        socialReputationMatrixLoaded: false,
        socialReputationMatrix: [],
        socialReputationMatrix2: [],
        pressList: {
            data: [],
            total: 0,
        },
        pressList2: {},
        pressPosts: [],
        pressPostsPages: 1,
    },
    mutations: {
        setSelectedPressSources(state, val) {
            state.selectedPressSources = val;
        },
        setSocialReputationMatrix(state, val) {
            state.socialReputationMatrixProblems = val.problems;
            state.socialReputationMatrix = val;
            state.socialReputationMatrixLoaded = true;
        },
        setSocialReputationMatrix2(state, val) {
            state.socialReputationMatrix2 = val;
        },
        // setPressList(state, val) {
        //     state.pressList = val;
        // },
        // setPressList2(state, payload) {
        //     const data = {};
        //     payload.forEach(item => {
        //         data[item.name] = item;
        //     });
        //     state.pressList2 = data;
        // },
        // setPressPosts(state, val) {
        //     state.pressPosts = val;
        // },
    },
    actions: {
        async getSocialReputationMatrix({ dispatch, commit }, {date, tonality, region}) {
            try {
                const res = await axios.get('/social/reputationmatrix', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialReputationMatrix', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialReputationMatrix2({ dispatch, commit }, {date, tonality, region}) {
            try {
                const res = await axios.get('/social/reputationmatrix', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialReputationMatrix2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        // async getPressList({ dispatch, commit }, {date, tonality, sources}) {
        //     try {
        //         const params = {
        //             date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
        //             tonality,
        //             sources,
        //         };
        //         const res = await axios.get('/smi/byposts', {params});
        //         const resData = res.data;
        //         commit('setPressList', resData);
        //         return resData;
        //     } catch (e) {
        //         // socketError({ dispatch, commit }, e);
        //         return false;
        //     }
        // },
        // async getPressList2({ dispatch, commit }, {date, tonality, sources}) {
        //     try {
        //         const params = {
        //             date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
        //             tonality,
        //             sources,
        //         };
        //         const res = await axios.get('/smi/byposts', {params});
        //         const resData = res.data;
        //         commit('setPressList2', resData.data);
        //         return resData;
        //     } catch (e) {
        //         // socketError({ dispatch, commit }, e);
        //         return false;
        //     }
        // },
        // async getPressPosts({ state, dispatch, commit }, {date, tonality, sources, page = 1}) {
        //     try {
        //         const params = {
        //             date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
        //             tonality,
        //             page,
        //             perpage: state.perPage,
        //         };
        //         if (sources && sources.length) {
        //             params.sources = sources;
        //         }
        //         const res = await axios.get('/smi/posts', {params});
        //         const resData = res.data;
        //         commit('setPressPosts', resData);
        //         return resData;
        //     } catch (e) {
        //         // socketError({ dispatch, commit }, e);
        //         return false;
        //     }
        // },
    },
    getters: {
        socialReputationMatrixLoaded(state) {
            return state.socialReputationMatrixLoaded;
        },
        socialReputationMatrix(state) {
            return state.socialReputationMatrix.values;
        },
        socialReputationMatrixIds(state) {
            return state.socialReputationMatrix.ids || {};
        },
        socialReputationMatrix2(state) {
            return state.socialReputationMatrix2.values;
        },
        socialReputationMatrixProblems(state) {
            return state.socialReputationMatrixProblems;
        }
        // pressList(state) {
        //     return state.pressList.data;
        // },
        // pressList2(state) {
        //     return state.pressList.data.map(item => state.pressList2[item.name]);
        // },
        // pressPosts(state) {
        //     return state.pressPosts.data;
        // },
        // pressPostsPages(state) {
        //     return Math.max(1,
        //         Math.ceil((state.pressPosts.total || 0 ) / state.perPage)
        //     );
        // }
    },
};
