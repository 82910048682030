import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        socialQuantityAndCoverageLoaded: false,
        socialQuantityAndCoverage: {
            data: [],
            posts: [],
        },
        socialQuantityAndCoverage2: {
            data: [],
            posts: [],
        },
    },
    mutations: {
        setSocialQuantityAndCoverage(state, val) {
            state.socialQuantityAndCoverage = {
                data: val.data,
                posts: val.posts.map(({date, name}) => ({
                    title: name,
                    date,
                }))
            };
            state.socialQuantityAndCoverageLoaded = true;
        },
        setSocialQuantityAndCoverage2(state, val) {
            state.socialQuantityAndCoverage2 = {
                data: val.data,
                posts: val.posts.map(({date, name}) => ({
                    title: name,
                    date,
                }))
            };
        },
    },
    actions: {
        async getSocialQuantityAndCoverage({ dispatch, commit }, {date, detailization, tonality, region}) {
            try {
                const res = await axios.get('/social/quantityandcoverage', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        groupby: detailization.value,
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialQuantityAndCoverage', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialQuantityAndCoverage2({ dispatch, commit }, {date, detailization, tonality, region}) {
            try {
                const res = await axios.get('/social/quantityandcoverage', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        groupby: detailization.value,
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialQuantityAndCoverage2', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        socialQuantityAndCoverageLoaded(state) {
            return state.socialQuantityAndCoverageLoaded;
        },
        socialQuantityAndCoverage(state) {
            return state.socialQuantityAndCoverage;
        },
        socialQuantityAndCoverage2(state) {
            return state.socialQuantityAndCoverage2;
        }
    },
};
