import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        modalMessageFilters: {},
        modalMessages: {
            data: [],
            total: 0,
        },
        modalMessagesOpen: false,
        modalMessagesHeader: '',

        modalMessageOpen: false,
        modalMessagenHeader: '',
        modalMessagenText: '',

        modalMessagenDate: '',
        modalMessagenDateTime: '',
        modalMessagenStatus: '',
        modalMessagenLink: '',
        modalMessagenRegionName: '',
        modalMessagenRegionBranch: '',
        modalMessagenProblem: '',
        modalMessagenResponsible: '',
        modalMessagenMeasures: '',

        modalMessageHeaders: {},
        modalMessagesAction: null,
    },
    mutations: {
        setModalMessages(state, val) {
            state.modalMessages = val;
            state.modalMessagesOpen = true;
        },
        setModalMessage(state, val) {
            state.modalMessage = val;
            state.modalMessageOpen = true;
        },
        setModalMessagesHeader(state, val) {
            state.modalMessagesHeader = val;
        },

        setModalMessagenHeader(state, val) {
            state.modalMessagenHeader = val;
        },
        setModalMessagenText(state, val) {
            state.modalMessagenText = val;
        },


        setModalMessagenDate(state, val) {
            state.modalMessagenDate = val;
        },
        setModalMessagenDateTime(state, val) {
            state.modalMessagenDateTime = val;
        },
        setModalMessagenStatus(state, val) {
            state.modalMessagenStatus = val;
        },
        setModalMessagenLink(state, val) {
            state.modalMessagenLink = val;
        },
        setModalMessagenRegionName(state, val) {
            state.modalMessagenRegionName = val;
        },
        setModalMessagenRegionBranch(state, val) {
            state.modalMessagenRegionBranch = val;
        },
        setModalMessagenProblem(state, val) {
            state.modalMessagenProblem = val;
        },
        setModalMessagenResponsible(state, val) {
            state.modalMessagenResponsible = val;
        },
        setModalMessagenMeasures(state, val) {
            state.modalMessagenMeasures = val;
        },


        setModalMessageHeaders(state, val) {
            state.modalMessageHeaders = val;
        },
        setModalMessagesOpen(state, val) {
            state.modalMessagesOpen = val;
        },

        setModalMessageOpen(state, val) {
            state.modalMessageOpen = val;
        },

        setModalMessageFilters(state, val) {
            state.modalMessageFilters = val;
        },
        setModalMessagesAction(state, val) {
            state.modalMessagesAction = val;
        },
    },
    actions: {
        async getSocialModalMessages({ state, dispatch, commit }, filters) {
            try {
                const {
                    date,
                    compareDate,
                    tonality,
                    titles,
                    page = 1,
                    problem1,
                    problem2,
                    source,
                    district,
                    districts,
                    region,
                    limit,
                    orderBy: orderby,
                    orderDir: orderdir,
                } = filters;
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    compareDate: (compareDate.startDate && compareDate.endDate) ? compareDate.startDate.substr(0, 5) + '.20' + compareDate.startDate.substr(6, 2) + '-' + compareDate.endDate.substr(0, 5) + '.20' + compareDate.endDate.substr(6, 2) : undefined,
                    tonality,
                    titles,
                    page,
                    problem1,
                    problem2,
                    source,
                    district,
                    districts,
                    region,
                    limit: state.perPage,
                    orderby,
                    orderdir,
                };
                const res = await axios.get('/social/messages', {params});
                const resData = res.data;
                commit('setModalMessagesHeader', 'Сообщения в социальных сетях');
                commit('setModalMessageHeaders', {
                    date: { title: 'Дата', class: 'td--date', format_number: false, ordering: true },
                    text: { title: 'Сообщение', class: 'td--text', format_number: false, ordering: true },
                    coverage: { title: 'Охват', class: '', format_number: true, ordering: true },
                    involvement: { title: 'Вовлеченность', class: '', format_number: true, ordering: true },
                    link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
                });
                commit('setModalMessages', resData);
                commit('setModalMessageFilters', filters);
                commit('setModalMessagesAction', 'getSocialModalMessages');
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },

        async getSocialModalMessage({ state, dispatch, commit }, filters) {
            try {
                const {
                    id,
                    date,
                    datetime,
                    status,
                    link,
                    regionname,
                    regionbranch,
                    problem,
                    responsible,
                    measures,
                } = filters;
                const params = {
                    id,
                    date,
                    datetime,
                    status,
                    link,
                    regionname,
                    regionbranch,
                    problem,
                    responsible,
                    measures,
                };
                const res = await axios.get('/social/message', {params});
                const resData = res.data;
                
                commit('setModalMessagenHeader', 'Полное сообщение');
                commit('setModalMessage', resData.data);
                commit('setModalMessagenText', resData.data);

                commit('setModalMessagenDate', params.date);
                commit('setModalMessagenDateTime', params.datetime);
                commit('setModalMessagenStatus', params.status);
                commit('setModalMessagenLink', params.link);
                commit('setModalMessagenRegionName', params.regionname);
                commit('setModalMessagenRegionBranch', params.regionbranch);
                commit('setModalMessagenProblem', params.problem);
                commit('setModalMessagenResponsible', params.responsible);
                commit('setModalMessagenMeasures', params.measures);

                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },

        async getSmiModalMessages({ state, dispatch, commit }, filters) {
            try {
                const {
                    date,
                    tonality,
                    titles,
                    page = 1,
                    problem2,
                    source,
                    district,
                    orderBy: orderby,
                    orderDir: orderdir,
                    limit,
                } = filters;
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    titles,
                    page,
                    problem2,
                    source,
                    district,
                    limit: state.perPage,
                    orderby,
                    orderdir,
                };
                const res = await axios.get('/smi/messages', {params});
                const resData = res.data;
                commit('setModalMessageHeader', 'Все публикации в СМИ');
                commit('setModalMessageHeaders', {
                    date: { title: 'Дата', class: 'td--date', format_number: false, ordering: true },
                    title: { title: 'Заголовок', class: 'td--text', format_number: false, ordering: true },
                    mediaIndex: { title: 'Медиаиндекс', class: '', format_number: true, ordering: true },
                    link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
                });
                commit('setModalMessages', resData);
                commit('setModalMessageFilters', filters);
                commit('setModalMessagesAction', 'getSmiModalMessages');
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        setModalMessagesOpen({commit}, val) {
            commit('setModalMessagesOpen', val);
        },
        setModalMessageOpen({commit}, val) {
            commit('setModalMessageOpen', val);
        }
    },
    getters: {
        modalMessages(state) {
            return state.modalMessages.data;
        },
        modalMessagesPages(state) {
            return Math.max(1,
                Math.ceil((state.modalMessages.total || 0 ) / state.perPage)
            );
        },
        modalMessageOpen(state) {
            return state.modalMessageOpen;
        },
        modalMessagesOpen(state) {
            return state.modalMessagesOpen;
        },
        modalMessagesHeader(state) {
            return state.modalMessagesHeader;
        },
        modalMessagenHeader(state) {
            return state.modalMessagenHeader;
        },
        modalMessagenText(state) {
            return state.modalMessagenText;
        },


        modalMessagenDate(state) {
            return state.modalMessagenDate;
        },
        modalMessagenDateTime(state) {
            return state.modalMessagenDateTime;
        },
        modalMessagenStatus(state) {
            return state.modalMessagenStatus;
        },
        modalMessagenLink(state) {
            return state.modalMessagenLink;
        },
        modalMessagenRegionName(state) {
            return state.modalMessagenRegionName;
        },
        modalMessagenRegionBranch(state) {
            return state.modalMessagenRegionBranch;
        },
        modalMessagenProblem(state) {
            return state.modalMessagenProblem;
        },
        modalMessagenResponsible(state) {
            return state.modalMessagenResponsible;
        },
        modalMessagenMeasures(state) {
            return state.modalMessagenMeasures;
        },


        modalMessageHeaders(state) {
            return state.modalMessageHeaders;
        },
        modalPage(state) {
            return state.modalPage;
        },
        modalMessageFilters(state) {
            return state.modalMessageFilters;
        },
        modalMessagesAction(state) {
            return state.modalMessagesAction;
        },
    },
};
