<template>
    <section>
        <div class="container">
            <div class="row row--gapped">
                <div class="col-w-50">
                    <router-link
                        :to="$url('/socnet-negativecategory')"
                        class="section-subtitle"
                        ref='title1'
                        :style="{height: equalHeight ? equalHeight : null}"
                    >Категории негативных упоминаний
                    </router-link>
                    <div class="card">
                        <Loading v-if="!chartData.labels || !chartData.labels.length" :loaded="topNegativeCategoriesLoaded"/>
                        <BarChartHorizontal
                            v-else
                            :height="styles.height"
                            :chart-data="chartData"
                            :chart-options="chartOptions"
                        />
                    </div>
                </div>
                <div class="col-w-50">
                    <router-link
                        :to="$url('/socnet-topnegativepublications')"
                        class="section-subtitle"
                        ref='title2'
                        :style="{height: equalHeight ? equalHeight : null}"
                    >Топ-5 источников негативных публикаций
                    </router-link>
                    <div class="card">
                        <Loading v-if="!chartData2.labels || !chartData2.labels.length" :loaded="topNegativeSourcesLoaded"/>
                        <BarChartHorizontal
                            v-else
                            :height="styles2.height"
                            :chart-data="chartData2"
                            :chart-options="chartOptions2"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapGetters} from 'vuex';
import BarChartHorizontal from './Charts/BarChartHorizontal';
import Loading from './Loading';

export default {
    name: 'NegativeCategoryBar',
    components: {
        BarChartHorizontal,
        Loading,
    },
    computed: {
        ...mapGetters({
            progressTime: 'progressTime',
            windowWidth: 'windowWidth',
            horizonBarChartMediaPoints: 'horizonBarChartMediaPoints',
            date: 'date',
            compareDate: 'compareDate',
            region: 'region',
            tonality: 'tonality',
            detailization: 'detailization',
            topNegativeCategories: 'topNegativeCategories',
            topNegativeCategoriesLoaded: 'topNegativeCategoriesLoaded',
            topNegativeSourcesLoaded: 'topNegativeSourcesLoaded',
            topNegativeCategories2Global: 'topNegativeCategories2',
            topNegativeSources: 'topNegativeSources',
            topNegativeSources2Global: 'topNegativeSources2',
        }),
        topNegativeCategories2() {
            return this.compareDate.startDate ? this.topNegativeCategories2Global : {};
        },
        topNegativeSources2() {
            return this.compareDate.startDate ? this.topNegativeSources2Global : {};
        },
        chartDataComputed() {
            return {
                horizonBarChartMediaPoints: this.horizonBarChartMediaPoints,
                topNegativeCategories: this.topNegativeCategories,
                topNegativeSources: this.topNegativeSources,
                topNegativeCategories2: this.topNegativeCategories2,
                topNegativeSources2: this.topNegativeSources2,
            };
        },
        apiQueryParams() {
            return {
                date: this.date,
                tonality: this.tonality,
                region: this.region,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                names: this.topNegativeCategories.map(el => el.name),
                tonality: this.tonality,
                region: this.region,
            }
        },
        apiQueryParamsCompare2() {
            return {
                date: this.compareDate,
                names: this.topNegativeSources.map(el => el.name),
                tonality: this.tonality,
                region: this.region,
            }
        },
    },
    watch: {
        apiQueryParams: {
            handler() {
                this.getTopNegativeCategories(this.apiQueryParams);
                this.getTopNegativeSources(this.apiQueryParams);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.getTopNegativeCategories2(this.apiQueryParamsCompare);
                }
            },
            deep: true,
        },
        apiQueryParamsCompare2: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.getTopNegativeSources2(this.apiQueryParamsCompare2);
                }
            },
            deep: true,
        },
        chartDataComputed: {
            handler() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.setMediaOptions('chartOptions');
                    this.setMediaOptions('chartOptions2');
                    this.fillData();
                }, 500);
            },
            deep: true,
        },
    },
    data() {
        return {
            timeout: null,
            interval: null,
            equalHeight: null,
            chartData: {},
            chartData2: {},
            chartOptions: {
                cornerRadius: 0.8,
                innerPadding: 18,
                scales: {
                    yAxes: [
                        {
                            barThickness: 18,
                            maxBarThickness: 18,
                            ticks: {
                                padding: 6,
                                fontSize: 14,
                            },
                        },
                    ],
                },
            },
            chartOptions2: {
                cornerRadius: 3,
                innerPadding: 18,
                fillTextSuffix: '%',
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                barThickness: 18,
                                maxBarThickness: 18,
                                padding: 6,
                                fontSize: 16,
                            },
                        },
                    ],
                },
            },
            styles: {
                height: 0,
            },
            styles2: {
                height: 0,
            },
        };
    },
    beforeUpdate() {
        this.windowWidth >= 1000 && this.setEqualHeight();
    },
    mounted() {
        this.getTopNegativeCategories(this.apiQueryParams);
        this.getTopNegativeSources(this.apiQueryParams);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getTopNegativeCategories2(this.apiQueryParamsCompare);
            this.getTopNegativeSources2(this.apiQueryParamsCompare2);
        }
    },
    methods: {
        ...mapActions([
            'getTopNegativeCategories',
            'getTopNegativeCategories2',
            'getTopNegativeSources',
            'getTopNegativeSources2',
        ]),
        fillData() {
            // let sum = this.topNegativeCategories.reduce((a, {value: b}) => b ? a + b : a, 0) / 100;
            let labels = this.topNegativeCategories.map(item => item.name);
            let data = this.topNegativeCategories.map(item => Math.round(item.value));
            let height = (data.length + 1) * this.chartOptions.scales.yAxes[0].maxBarThickness * 2 + data.length * this.chartOptions.innerPadding;
            let datasets = [
                {
                    defaultFontColor: '#555555',
                    backgroundColor: '#28b93c',
                    borderColor: '#28b93c',
                    borderWidth: 10,
                    data,
                },
            ];
            if (Object.keys(this.topNegativeCategories2).length) {
                datasets.push({
                    defaultFontColor: '#555555',
                    backgroundColor: '#878787',
                    borderColor: '#878787',
                    borderWidth: 10,
                    data: labels.map(label => Math.round((this.topNegativeCategories2[label] || 0))),
                });
            }
            this.chartData = {
                labels: window.fixWordWrap(labels, 18),
                datasets,
            };

            let sum = this.topNegativeSources.reduce((a, {value: b}) => b ? a + b : a, 0) / 100;
            labels = this.topNegativeSources.map(item => item.name);
            data = this.topNegativeSources.map(item => Math.round(item.value / sum));

            let height2 = (data.length + 1) * this.chartOptions2.scales.yAxes[0].maxBarThickness + data.length * this.chartOptions2.innerPadding;
            if (this.windowWidth >= 1000) {
                height = height2 = Math.max(height, height2);
            }

            datasets = [
                {
                    defaultFontColor: '#555555',
                    backgroundColor: '#28b93c',
                    borderColor: '#28b93c',
                    borderWidth: 10,
                    data,
                },
            ];
            sum = Object.values(this.topNegativeSources2).reduce((a, b) => b ? a + b : a, 0) / 100;
            if (Object.keys(this.topNegativeSources2).length) {
                datasets.push({
                    defaultFontColor: '#555555',
                    backgroundColor: '#878787',
                    borderColor: '#878787',
                    borderWidth: 10,
                    data: labels.map(label => Math.round((this.topNegativeSources2[label] || 0) / sum)),
                });
            }
            this.styles = {...this.styles, height};
            this.styles2 = {...this.styles2, height: height2};
            this.chartData2 = {
                labels: window.fixWordWrap(labels, 18),
                datasets,
            };
        },
        setEqualHeight() {
            const h1 = this.$refs.title1.$el.clientHeight;
            const h2 = this.$refs.title2.$el.clientHeight;
            const nextEqualHeight = h1 === h2 ? (parseFloat(this.equalHeight) === Math.max(h1, h2) ? Math.min(h1, h2) : null) : Math.max(h1, h2);

            if (nextEqualHeight) {
                this.equalHeight = nextEqualHeight + 'px';
            }
        },
        setMediaOptions(opt) {
            const obj = JSON.parse(JSON.stringify(this[opt]));
            if (this.horizonBarChartMediaPoints === 'MOBILE') {
                obj.scales.yAxes[0].ticks.fontSize = 10;
                obj.scales.yAxes[0].ticks.padding = 1;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 16;
                obj.scales.yAxes[0].barThickness = 15;
                obj.scales.yAxes[0].maxBarThickness = 15;
            } else if (this.horizonBarChartMediaPoints === 1000) {
                obj.scales.yAxes[0].ticks.fontSize = 12;
                obj.scales.yAxes[0].ticks.padding = 1;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 16;
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === 1300) {
                obj.scales.yAxes[0].ticks.fontSize = 12;
                obj.scales.yAxes[0].ticks.padding = 4;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 18;
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === 'DESKTOP') {
                obj.scales.yAxes[0].ticks.fontSize = 14;
                obj.scales.yAxes[0].ticks.padding = 6;
                obj.cornerRadius = 0.8;
                obj.innerPadding = 20; // 18
                obj.scales.yAxes[0].barThickness = 18;
                obj.scales.yAxes[0].maxBarThickness = 18;
            } else if (this.horizonBarChartMediaPoints === '4K') {
                obj.scales.yAxes[0].ticks.fontSize = 22;
                obj.scales.yAxes[0].ticks.padding = 22;
                obj.cornerRadius = 1.2;
                obj.innerPadding = 29;
                obj.scales.yAxes[0].barThickness = 29;
                obj.scales.yAxes[0].maxBarThickness = 29;
            }
            this[opt] = obj;
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
    },
};
</script>

<style lang="scss" scoped>
/*.col-w-50:first-child .card {
    padding: 10px 42px 21px 10px;
}
.col-w-50:last-child .card {
    padding: 28px 42px 23px 22px;
}*/
.col-w-50.card {
    padding: 10px 42px 21px 10px;
}

@media (max-width: 1300px) {
    .col-w-50 {
        width: calc(50% - 7px);
    }
    .col-w-50:last-child .card,
    .col-w-50:first-child .card {
        padding: 10px;
    }
}

@media (max-width: 1000px) {
    .row {
        flex-wrap: wrap;
    }
    .col-w-50:last-child .card,
    .col-w-50:first-child .card {
        padding: 8px calc(22 / 588 * 100%);
    }
    .col-w-50 {
        width: 100%;
        min-width: unset;
    }
}

@media (max-width: 768px) {
    .col-w-50:last-child .card,
    .col-w-50:first-child .card {
        padding: 3px calc(16 / 360 * 100%);
    }
}
</style>
