<template>
    <svg
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            style="transition: all ease-out 0.32s"
            d="M23.389 16.0005C23.0517 16.0005 22.7781 16.2654 22.7781 16.5919V18.8519C22.7781 20.1563 21.6817 21.2176 20.3342 21.2176H3.66577C2.31825 21.2176 1.22194 20.1563 1.22194 18.8519V16.5919C1.22194 16.2654 0.948422 16.0005 0.610969 16.0005C0.273516 16.0005 0 16.2653 0 16.5919V18.8519C0 20.8086 1.64447 22.4005 3.66577 22.4005H20.3342C22.3555 22.4005 24 20.8086 24 18.8519V16.5919C24 16.2653 23.7265 16.0005 23.389 16.0005Z"
            :fill="color"
        />
        <path
            style="transition: all ease-out 0.32s"
            d="M19.0234 8.45982C18.7681 8.20318 18.3295 8.18085 18.0436 8.41018L12.6937 12.7026V0.623264C12.6937 0.279115 12.3831 0 11.9998 0C11.6166 0 11.3059 0.27902 11.3059 0.623264V12.7026L5.95605 8.41023C5.6703 8.1809 5.23159 8.20328 4.97619 8.45987C4.72095 8.7166 4.7456 9.11063 5.0314 9.33992L10.844 14.0035C11.1734 14.2679 11.5865 14.4 11.9997 14.4C12.4128 14.4 12.826 14.2679 13.1555 14.0036L18.9681 9.33992C19.254 9.11048 19.2787 8.71646 19.0234 8.45982Z"
            :fill="color"
        />
    </svg>
</template>

<script>
export default {
    name: 'Download',
    props: {
        color: {
            type: String,
            required: false,
            default: '#1C1C1C',
        },
    },
};
</script>
