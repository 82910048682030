<template>
    <section>
        <div class="container">
            <div
                    class="row"
                    :class="{'row--gapped': !isMobile}"
            >
                <div
                        class="col-w-50"
                        :style="{width: isMobile ? '100%' : null}"
                >
                    <router-link
                            :to="$url('/smi-top-publications')"
                            :style="{height: equalHeight ? equalHeight : null}"
                            class="section-subtitle"
                            ref="title1"
                    >Топ 10 СМИ по количеству публикаций
                    </router-link>
                    <div
                            class="card"
                            :style="{height: (windowWidth >= 1000 && equalHeightCard) ? equalHeightCardPx : null}"
                            ref="card1"
                    >
                        <Loading v-show="!chartData.labels || !chartData.labels.length" :loaded="topPressLoaded"/>
                        <BarChartHorizontal
                                v-show="chartData.labels && chartData.labels.length"
                                ref="h1inner"
                                :styles="styles"
                                :chart-data="chartData"
                                :chart-options="chartOptions"
                        />
                    </div>
                </div>
                <div class="col-w-50">
                    <router-link
                            :to="$url('/smi-top')"
                            :style="{height: equalHeight ? equalHeight : null}"
                            class="section-subtitle"
                            ref="title2"
                    >Топ-5 инфоповодов в СМИ
                    </router-link>
                    <div
                            class="card table-wrap"
                            ref="card2"
                            :style="{height: (windowWidth >= 1000 && equalHeightCard) ? equalHeightCardPx : null}"
                    >
                        <Loading
                                v-if="!posts.length"
                                :loaded="infoFeedLoaded"
                                ref="card2content"
                        />
                        <Posts
                                v-else
                                ref="card2content"
                                :headers="postHeaders"
                                :posts="posts"
                        />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import Vue from 'vue';
    import {mapActions, mapGetters} from 'vuex';
    import BarChartHorizontal from './Charts/BarChartHorizontal';
    import Posts from './Posts';
    import Loading from './Loading';

    export default {
        name: 'PressBar',
        components: {
            BarChartHorizontal,
            Posts,
            Loading,
        },
        data() {
            return {
                extraBreakpoint: false,
                equalHeight: null,
                equalHeightCard: null,
                innerPadding: 26,
                chartData: {},
                postHeaders: {
                    date: { title: 'Дата', class: 'td--date', format_number: false },
                    name: { title: 'Тема', class: 'td--text', format_number: false },
                    count: { title: 'Кол-во', class: '', format_number: true },
                    coverage: { title: 'Охват', class: '', format_number: false },
                    mediaIndex: { title: 'Медиаиндекс', class: '', format_number: true },
                },
                chartOptions: {
                    cornerRadius: 4,
                    haveLeftRadius: false,
                    scales: {
                        yAxes: [
                            {
                                barThickness: 18,
                                maxBarThickness: 18,
                                ticks: { fontSize: 14, padding: 14 },
                            },
                        ],
                    },
                },
                styles: {
                    height: 0,
                },
            };
        },
        computed: {
            ...mapGetters([
                'windowWidth',
                'progressTime',
                'isMobile',
                'horizonBarChartMediaPoints',
                'date',
                'compareDate',
                'tonality',
                'region',
                'detailization',
                'topPress',
                'topPressLoaded',
                'infoFeed',
                'infoFeedLoaded',
            ]),
            ...mapGetters({
                topPress2Global: "topPress2",
            }),
            posts() {
                return this.infoFeed ? this.infoFeed.slice(0, 5) : [];
            },
            equalHeightCardPx() {
                return this.equalHeightCard && (this.equalHeightCard + 'px');
            },
            topPress2() {
                return this.compareDate.startDate ? this.topPress2Global : {};
            },
            apiQueryParams() {
                return {
                    date: this.date,
                    progressTime: this.progressTime,
                    tonality: this.tonality,
                }
            },
            apiQueryParamsCompare() {
                return {
                    date: this.compareDate,
                    names: this.topPress.map(el => el.name),
                    progressTime: this.progressTime,
                    tonality: this.tonality,
                }
            },
        },
        watch: {
            posts() {
                Vue.nextTick(() => this.setCardsHeight());
            },
            windowWidth() {
                if (this.windowWidth <= 1400 && this.windowWidth > 1300) {
                    this.setExtraBreakpoint();
                } else {
                    this.setStyles();
                }
            },
            horizonBarChartMediaPoints(val, oldVal) {
                if (!this.extraBreakpoint) {
                    this.setStyles();
                    this.fillData();
                    this.setStyles();
                    setTimeout(() => {
                        this.setStyles();
                    }, 1000)
                }
            },
            extraBreakpoint(val, oldVal) {
                this.setStyles();
                this.fillData();
                this.setStyles();
                setTimeout(() => {
                    this.setStyles();
                }, 1000)
            },
            apiQueryParams: {
                handler() {
                    this.getData();
                },
                deep: true,
            },
            apiQueryParamsCompare: {
                handler() {
                    this.getDataCompare();
                },
                deep: true,
            },
            topPress: {
                handler() {
                    this.setStyles();
                    this.fillData();
                },
                deep: true,
            },
            topPress2: {
                handler() {
                    this.setStyles();
                    this.fillData();
                },
                deep: true,
            },
        },
        updated() {
            Vue.nextTick(() => {
                this.windowWidth >= 1000 && this.setEqualHeight();
            });

        },
        mounted() {
            this.getData();
            this.getDataCompare();
            Vue.nextTick(() => this.setCardsHeight());
        },
        methods: {
            ...mapActions([
                'getTopPress',
                'getTopPress2',
            ]),
            getData() {
                this.getTopPress(this.apiQueryParams);
            },
            getDataCompare() {
                if (this.apiQueryParamsCompare.names.length && this.compareDate.startDate && this.compareDate.endDate) {
                    this.getTopPress2(this.apiQueryParamsCompare);
                }
            },
            setCardsHeight() {
                if (this.$refs.card1 && this.$refs.card2content) {
                    const h1inner = Math.max(...[...this.$refs.card1.childNodes].map(el => el.clientHeight));
                    const h2inner = this.$refs.card2content.$el.clientHeight;
                    // let height = this.styles.height;
                    // if (typeof height === 'string') {
                    //     height = parseFloat(height.substr(0, height.length - 2));
                    // }
                    //this.equalHeightCard = Math.max(h1inner, h2inner);
                    this.equalHeightCard = 700;
                }
            },
            setEqualHeight() {
                Vue.nextTick(() => {
                    let h1, h2, nextEqualHeight;
                    if (this.$refs.title1 && this.$refs.title2) {
                        h1 = this.$refs.title1.$el.clientHeight;
                        h2 = this.$refs.title2.$el.clientHeight;
                        nextEqualHeight = h1 === h2 ? (parseFloat(this.equalHeight) === Math.max(h1, h2) ? Math.min(h1, h2) : null) : Math.max(h1, h2);

                        if (nextEqualHeight) {
                            this.equalHeight = nextEqualHeight;
                        }
                    }
                }, 100);
            },
            fillData() {
                let labels = this.topPress.map(item => item.name);
                const data = this.topPress.map(item => item.value);
                this.equalHeightCard = null;;
                if (!data.length) {
                    this.styles.height = 0;
                } else {
                    this.styles.height = 700 + 'px';
                }
                const datasets = [
                    {
                        defaultFontColor: '#555555',
                        backgroundColor: '#28b93c',
                        borderWidth: 0,
                        data,
                    },
                ];
                if (Object.keys(this.topPress2).length) {
                    datasets.push({
                        defaultFontColor: '#FFF',
                        backgroundColor: '#878787',
                        borderWidth: 0,
                        data: labels.map(label => this.topPress2[label] || 0),
                    });
                }
                this.styles = {
                    ...this.styles,
                };
                this.chartData = {
                    labels: window.fixWordWrap(labels, 18, 3),
                    datasets,
                };
                Vue.nextTick(() => this.setCardsHeight());
            },
            setExtraBreakpoint() {
                const chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
                this.extraBreakpoint = true;
                chartOptions.scales.yAxes[0].ticks.fontSize = 14;
                chartOptions.scales.yAxes[0].ticks.padding = 14;
                this.innerPadding = 20;
                chartOptions.scales.yAxes[0].barThickness = 18;
                chartOptions.scales.yAxes[0].maxBarThickness = 18;
                this.chartOptions = chartOptions;
            },
            setStyles() {
                const chartOptions = JSON.parse(JSON.stringify(this.chartOptions));
                if (this.horizonBarChartMediaPoints === 'MOBILE') {
                    this.extraBreakpoint = false;
                    chartOptions.scales.yAxes[0].ticks.fontSize = 10;
                    chartOptions.scales.yAxes[0].ticks.padding = 1;
                    chartOptions.cornerRadius = 4;
                    this.innerPadding = 16;
                    chartOptions.scales.yAxes[0].barThickness = 15;
                    chartOptions.scales.yAxes[0].maxBarThickness = 15;
                } else if (this.horizonBarChartMediaPoints === 1000) {
                    this.extraBreakpoint = false;
                    chartOptions.scales.yAxes[0].ticks.fontSize = 12;
                    chartOptions.scales.yAxes[0].ticks.padding = 1;
                    chartOptions.cornerRadius = 4;
                    this.innerPadding = 16;
                    chartOptions.scales.yAxes[0].barThickness = 18;
                    chartOptions.scales.yAxes[0].maxBarThickness = 18;
                } else if (this.horizonBarChartMediaPoints === 1300) {
                    this.extraBreakpoint = false;
                    chartOptions.scales.yAxes[0].ticks.fontSize = 12;
                    chartOptions.scales.yAxes[0].ticks.padding = 12;
                    chartOptions.cornerRadius = 4;
                    this.innerPadding = 23;
                    chartOptions.scales.yAxes[0].barThickness = 18;
                    chartOptions.scales.yAxes[0].maxBarThickness = 18;
                } else if (this.horizonBarChartMediaPoints === 'DESKTOP') {
                    this.extraBreakpoint = false;
                    chartOptions.scales.yAxes[0].ticks.fontSize = 14;
                    chartOptions.scales.yAxes[0].ticks.padding = 14;
                    chartOptions.cornerRadius = 4;
                    this.innerPadding = 26;
                    chartOptions.scales.yAxes[0].barThickness = 18;
                    chartOptions.scales.yAxes[0].maxBarThickness = 18;
                } else if (this.horizonBarChartMediaPoints === '4K') {
                    this.extraBreakpoint = false;
                    chartOptions.scales.yAxes[0].ticks.fontSize = 22;
                    chartOptions.scales.yAxes[0].ticks.padding = 22;
                    chartOptions.cornerRadius = 6;
                    this.innerPadding = 41;
                    chartOptions.scales.yAxes[0].barThickness = 29;
                    chartOptions.scales.yAxes[0].maxBarThickness = 29;
                }
                this.chartOptions = chartOptions;
            },
            getRandomInt() {
                return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card.table-wrap {
        padding: 20px calc(22 / 588 * 100%);
        margin-bottom: 0;
        overflow-y:auto;
    }

    @media (min-width: 2320px) {
        .card.table-wrap {
            padding: 30px 40px 40px;
        }

        .card {
            padding: 35px;
        }
    }
    @media (max-width: 1300px) {
        .col-w-50 {
            width: calc(50% - 7px);
        }
        .card {
            padding: 10px;
        }
        .card.table-wrap {
            padding: 10px calc(22 / 588 * 100%);
        }
    }

    @media (max-width: 1000px) {
        .row {
            flex-wrap: wrap;
        }

        .card {
            padding: 8px calc(22 / 588 * 100%);
        }
        .col-w-50 {
            width: 100%;
            min-width: unset;
        }
    }

    @media (max-width: 768px) {
        .card {
            padding: 3px calc(16 / 360 * 100%);

            &.table-wrap {
                border: none;
                padding: 0;
            }
        }
    }
</style>
