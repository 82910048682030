<template>
    <form
            @submit.prevent="submitHandler"
            class="login-modal-inner"
    >
        <div class="login-caption">Авторизация</div>
        <div class="login-sub">
            Войдите в свой аккаунт или
            <router-link
                    class="link"
                    to="/login"
            >зарегистрируйтесь
            </router-link>
        </div>
        <div class="login-input-group">
            <div :class="{
						'login-input-field': true,
						'login-input-error': errMsg,
						'focused': focused.login}">
                <input
                        @focus="focused.login = true"
                        @blur="focused.login = false"
                        placeholder="Логин"
                        v-model="login"
                        type="text"
                        class="login-input"
                >
                <span class="login-input-title">Логин</span>
            </div>
            <div :class="{
						'login-input-field': true,
						'login-input-error': errMsg,
						'focused': focused.password}">
                <input
                        @focus="focused.password = true"
                        @blur="focused.password = false"
                        placeholder="Пароль"
                        v-model="password"
                        :type="passwordType"
                        class="login-input"
                >
                <img
                        @click="passwordType === 'password' ? passwordType = 'text' : passwordType = 'password'"
                        :src="`/img/icons/${passwordType === 'password' ? 'hidden.svg' : 'visible.svg'}`"
                        alt="m"
                        class="login-input-icon"
                >
                <span class="login-input-title">Пароль</span>
            </div>
        </div>
        <div class="login-forgot-wrap">
            <label class="login-checkbox-group">
                <div :class="{'login-checkbox': true, 'checked': remember}">
                    <img
                            v-show="remember"
                            src="/img/icons/check.svg"
                            alt="chk"
                    >
                </div>
                <input
                        v-model="remember"
                        type="checkbox"
                        hidden
                >
                Запомнить меня
            </label>
            <span
                    @click="$emit('toggleForgotPassword')"
                    class="login-forgot"
            >Забыли пароль?</span>
        </div>
        <button
                type="submit"
                :class="{'login-btn': true, 'none-active': !login || !password}"
        >Войти
        </button>
        <div
                v-if="errMsg"
                class="login-error-message"
        >Некорректные данные для входа, попробуйте еще раз
        </div>
    </form>
</template>
<script>
export default {
    name: 'Login',
    model: {
        prop: 'forgotPassword',
        event: 'toggleForgotPassword',
    },
    props: {
        forgotPassword: {
            type: Boolean,
        },
    },
    data: () => ({
        login: '',
        password: '',
        remember: false,

        passwordType: 'password',
        errMsg: '',
        focused: {
            login: false,
            password: false,
        },
    }),
    methods: {
        submitHandler() {
            // console.log(this.login, this.password, this.remember)
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.login {
    &-forgot {
        &-wrap {
            margin-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        text-decoration: underline;
        cursor: pointer;
        font-size: 1.3rem;
        line-height: 1;
    }

    &-checkbox {
        transition: $login-transition;
        width: 22px;
        height: 19px;
        margin: 0;
        border: 1px solid $c-login-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        box-sizing: border-box;
        margin-right: 12px;

        &.checked {
            border-color: $c-login-primary;
        }

        &-group {
            font-size: 1.3rem;
            line-height: 1;
            cursor: pointer;
            outline: none;
            display: flex;
            align-items: center;
        }
    }
}
</style>
