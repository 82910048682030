import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        topNegativeCategoriesLoaded: false,
        topNegativeCategories: [],
        topNegativeCategories2: {},
        negativeCategoriesList: {
            data: [],
        },
        negativeCategoriesList2: {},
    },
    mutations: {
        setTopNegativeCategories(state, val) {
            state.topNegativeCategories = val;
            state.topNegativeCategoriesLoaded = true;
        },
        setTopNegativeCategories2(state, val) {
            const data = {};
            val.forEach(item => {
                data[item.name] = item.value;
            });
            state.topNegativeCategories2 = data;
        },
        setNegativeCategoriesList(state, val) {
            state.negativeCategoriesList = val;
        },
        setNegativeCategoriesList2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item;
            });
            state.negativeCategoriesList2 = data;
        },
    },
    actions: {
        async getTopNegativeCategories({dispatch, commit}, {date, orderBy, limit, region}) {
            const res = await axios.get('/social/negativementionscountbycategories', {
                params: {
                    orderby: orderBy,
                    limit,
                    region,
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                }
            });
            const resData = res.data;
            commit('setTopNegativeCategories', resData.data);
            return resData;
        },
        async getTopNegativeCategories2({dispatch, commit}, {date, orderBy, limit, region, names}) {
            const res = await axios.get('/social/negativementionscountbycategories', {
                params: {
                    orderby: orderBy,
                    limit,
                    region,
                    names,
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                }
            });
            const resData = res.data;
            commit('setTopNegativeCategories2', resData.data);
            return resData;
        },
        async getNegativeCategoriesList({ dispatch, commit }, {date, titles, region, orderBy, orderDir}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    region,
                    titles: titles.length ? titles : undefined,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/social/negativeproblems', {params});
                const resData = res.data;
                commit('setNegativeCategoriesList', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getNegativeCategoriesList2({ dispatch, commit }, {date, titles, region, orderBy, orderDir}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    region,
                    titles: titles.length ? titles : undefined,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/social/negativeproblems', {params});
                const resData = res.data;
                commit('setNegativeCategoriesList2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        topNegativeCategoriesLoaded(state) {
            return state.topNegativeCategoriesLoaded;
        },
        topNegativeCategories(state) {
            return state.topNegativeCategories;
        },
        topNegativeCategories2(state) {
            return state.topNegativeCategories2;
        },
        negativeCategoriesList(state) {
            return state.negativeCategoriesList.data;
        },
        negativeCategoriesList2(state) {
            return state.negativeCategoriesList.data.map(item => state.negativeCategoriesList2[item.name]);
        },
    },
};
