<template>
    <div class="soc-negative-category" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: Категории негативных упоминаний</div>
            </div>
        </div>
        <Form
            ref="form"
            :comparedatePosBreakPoints="{900: 'center', 1440: 'right', 10000: 'right'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'region', 'compareDate']"
            @download="$downloadReport(negativeCategoriesList, $event)"
        />
        <div>
            <div class="container">
                <ChooseChart
                    :activeChart="activeChart"
                    @click="toggleChart"
                />
                <VerticalBarChartComponent
                    v-if="activeChart === 'verticalBar'"
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                    :loaded="topNegativeCategoriesLoaded"
                    v-model="titles"
                />
                <PieChartComponent
                    v-else
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                />
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                    style="margin-bottom: 0"
                >
                    <Posts
                        @click="postClick"
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :postsCompare="negativeCategoriesList2"
                        :posts="negativeCategoriesList"
                    />
                </div>
                <!-- <Pagination /> -->
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import Loading from '@/components/Loading';
import PieChartComponent from '@/components/PieChartComponent';
import Pagination from '@/components/Pagination';
import ChooseChart from '@/components/ChooseChart';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SocNetNegativeCategory',
    mixins: [downloadReport],
    data() {
        return {
            orderData: {},
            activeChart: 'verticalBar',
            titles: [],
            postHeaders: {
                title1: { title: 'Проблема 1 ур', class: 'td--text', format_number: false, ordering: true, },
                title2: { title: 'Проблема 2 ур', class: 'td--text', format_number: false, ordering: true, },
                quantity: { title: 'Количество проблем', class: '', format_number: true, ordering: true, },
            },
            reportColumns: [ 'title1', 'title2', 'quantity' ],
        };
    },
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'topNegativeCategoriesLoaded',
            'topNegativeCategories',
            'negativeCategoriesList',
            'progressTime',
            'userAccessSocmedia',
            'region',
            'tonality',
        ]),
        ...mapGetters({
            topNegativeCategories2Global: "topNegativeCategories2",
            negativeCategoriesList2Global: "negativeCategoriesList2",
        }),
        topNegativeCategories2() {
            return this.compareDate.startDate ? this.topNegativeCategories2Global : {};
        },
        negativeCategoriesList2() {
            return this.compareDate.startDate ? this.negativeCategoriesList2Global : [];
        },
        labels() {
            return this.topNegativeCategories.map(item => item.name);
        },
        data() {
            return this.topNegativeCategories.map(item => item.value);
        },
        data2() {
            return (this.compareDate.startDate && this.compareDate.endDate) ? this.topNegativeCategories.map(item => this.topNegativeCategories2[item.name]) : [];
        },
        apiQueryParams() {
            return {
                date: this.date,
                compareDate: this.compareDate,
                progressTime: this.progressTime,
                orderBy: 'title',
                limit: 10,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                tonality: this.tonality,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                progressTime: this.progressTime,
                orderBy: 'title',
                limit: 10,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                tonality: this.tonality,
            }
        },
        apiQueryParamsTitles() {
            return {
                date: this.date,
                titles: this.titlesToFilter,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                tonality: this.tonality,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsTitlesCompare() {
            return {
                date: this.compareDate,
                titles: this.titlesToFilter,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                tonality: this.tonality,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        titlesToFilter() {
            // return this.titles.length ? this.titles : this.topNegativeCategories.map(item => item.name);
            return this.titles.length ? this.titles : [];
        }
    },
    methods: {
        ...mapActions([
            'getTopNegativeCategories',
            'getTopNegativeCategories2',
            'getNegativeCategoriesList',
            'getNegativeCategoriesList2',
            'getSocialModalMessages',
        ]),
        toggleChart() {
            if (this.activeChart === 'verticalBar') {
                this.activeChart = 'pie';
            } else {
                this.activeChart = 'verticalBar';
            }
            this.$refs.form.reportCompleted = false;
        },
        postClick(post) {
            this.getSocialModalMessages({
                problem2: post.title2,
                ...this.apiQueryParams
            });
        }
    },
    watch: {
        apiQueryParams: {
            handler() {
                setTimeout(() => {
                    this.userAccessSocmedia && this.getTopNegativeCategories(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getTopNegativeCategories2(this.apiQueryParamsCompare);
                }
            },
            deep: true,
        },
        apiQueryParamsTitles: {
            handler() {
                setTimeout(() => {
                    this.userAccessSocmedia && this.getNegativeCategoriesList(this.apiQueryParamsTitles);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsTitlesCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getNegativeCategoriesList2(this.apiQueryParamsTitlesCompare);
                }
            },
            deep: true,
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.getTopNegativeCategories(this.apiQueryParams);
        this.getNegativeCategoriesList(this.apiQueryParamsTitles);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getTopNegativeCategories2(this.apiQueryParamsCompare);
            this.getNegativeCategoriesList2(this.apiQueryParamsTitlesCompare);
        }
    },
    components: {
        Form,
        Posts,
        Pagination,
        VerticalBarChartComponent,
        ChooseChart,
        PieChartComponent,
        Loading,
    },
};
</script>

<style lang="scss">
@media (max-width: 1029px) and (min-width: 1014px) {
    .soc-negative-category {
        .form-field.comparedate .opensright {
            transform: translateX(-20%);
        }
    }
}
</style>
