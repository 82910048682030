<template>
    <div class="links">
        <div class="form-field">
            <div class="container" :class="fixed ? 'fixed-container' : ''">
                <div class="section-title">
                    Данные за <span v-if="dateStore.startDate === dateStore.endDate">{{ dateStore.startDate }}</span>
                    <span v-else>период c {{ dateStore.startDate }} по {{dateStore.endDate}}</span>
                    <span v-if="compareDateStore.startDate">  и с {{ compareDateStore.startDate }} по {{compareDateStore.endDate}}</span>
                </div>
            </div>
        </div>
        <section class="form-field radio-group screenshot-hidden">
            <div class="container" :class="fixed ? 'fixed-container' : ''">
                <RadioInputGroup
                    :groupSolid="false"
                    :options="optionsRadio"
                    v-model="scrollToPage"
                />
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import RadioInputGroup from '@/components/Form/RadioInputGroup';

export default {
    components: {
        RadioInputGroup
    },
    data() {
        return {
            fixed: false,
            scrollToPage: '',
            optionsRadio: [
                {
                    value: 'Сводка',
                    text: 'Сводка',
                },
                {
                    value: 'СМИ',
                    text: 'СМИ',
                },
                {
                    value: 'Соцсети',
                    text: 'Соцсети',
                },
                {
                    value: 'Проблематика',
                    text: 'Проблематика',
                },
            ],
        }
    },

    computed: {
        ...mapGetters({
            'dateStore': 'date',
            'compareDateStore': 'compareDate',
        })
    },

    watch:{
        scrollToPage() {
            let container = null;
            switch (this.scrollToPage) {
                case 'Сводка':
                    container = document.getElementById('widgets');
                    break;
                case 'СМИ':
                    container = document.getElementById('smi');
                    break;
                case 'Соцсети':
                    container = document.getElementById('social');
                    break;
                case 'Проблематика':
                    container = document.getElementById('regions');
                    break;
                default:
                    break;
            }
            window.scrollTo({
                top: container.offsetTop,
                behavior: 'smooth',
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .links{
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .fixed-container{
        position: fixed;
        top: 0;
        padding-top: 30px;
        width: 100%;
        z-index: 999999;
        background-color: white;
    }
    .radio-group {
        padding-bottom: 0;
        padding-top: 0px;
        margin-top: 0px;
    }
</style>
