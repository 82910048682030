<template>
    <div/>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Token',
    mounted() {
    //     const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6IjRmMWcyM2ExMmFhIn0.eyJpc3MiOiJodHRwOlwvXC9kZXYuYWRtaW4uZWNoby5wcm9nbm9zaXMucnUiLCJqdGkiOiI0ZjFnMjNhMTJhYSIsImlhdCI6MTYzNzc2Nzc1MCwiZXhwIjoxNjY5MzAzNzUwLCJ1aWQiOjIwfQ.CU9KhzbOJQAYRaXgyf5-ohBwz0q_NO_JtCVO3olLrl8';
    //    // const redirect = this.$route.query.redirect;
    //     const sessionId = "m1t5g1092nusm8r1lj28u69ip8";
    //     localStorage.setItem('token', token);
    //     axios.defaults.headers = {
    //         Authorization: `Bearer ${token}`,
    //     };
    //     localStorage.setItem('sessionId', sessionId);
    //     if (redirect) {
    //         //window.location.href = redirect;
    //     } else {
    //         this.$router.push('/');
    //     }


        const token = this.$route.query.token;
        const redirect = this.$route.query.redirect;
        const sessionId = this.$route.query.session_id;
        localStorage.setItem('token', token);
        axios.defaults.headers = {
            Authorization: `Bearer ${token}`,
        };
        localStorage.setItem('sessionId', sessionId);
        if (redirect) {
            window.location.href = redirect;
        } else {
            this.$router.push('/');
        }

    }
};
</script>
