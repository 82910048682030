import { HorizontalBar, mixins } from 'vue-chartjs';
import { mergeDeep } from '../../helpers';
import { mapGetters } from 'vuex';
import Vue from 'vue';

const { reactiveProp } = mixins;

export default {
    name: 'BarChartHorizontal',
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
        styles: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters(['is4K']),
    },
    data() {
        return {
            _options: {
                responsive: true,
                maintainAspectRatio: false,
                type: 'horizontalBar',
                legend: {
                    display: false,
                    // labels: {
                    //     usePointStyle: true,
                    // },
                },
                //cornerRadius: 0.8,
                scales: {
                    // scaleLabel: {
                    // 	display: false,
                    // 	labelString: 'A',
                    // },
                    xAxes: [
                        {
                            gridLines: {
                                lineWidth: 0,
                                display: false,
                            },
                            ticks: {
                                display: false,
                                padding: 0,
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            barThickness: 18,
                            maxBarThickness: 18,
                            barPercentage: 1.0,
                            categoryPercentage: 1.0,
                            gridLines: {
                                lineWidth: 0,
                                display: false,
                            },
                            ticks: {
                                padding: 14,
                                fontSize: 14,
                                fontStyle: 500,
                                lineHeight: 1.071,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                    callbacks: {
                        label(tooltipItem) {
                            return tooltipItem.value;
                        },
                    },
                },
                hover: {
                    animationDuration: 0,
                    onHover: function(e) {
                        var point = this.getElementAtEvent(e);
                        if (!this.options.hoverPointer) return;
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                },
                animation: {
                    duration: 1,
                    onProgress: () => {
                        this.$data._options.animation.onComplete();
                    },
                    onComplete: () => {
                        var chartInstance = this.$data._chart.chart;
                        var ctx = chartInstance.ctx;
                        let textMargin = this['is4K'] ? 11 : 6.74;
                        const fontPatch = this['is4K'] ? 2 : 1;

                        ctx.fillStyle = '#fff';
                        ctx.textAlign = 'right';
                        ctx.textBaseline = 'middle';
                        Chart.helpers.each(
                            this.$data._chart.data.datasets.forEach((dataset, i) => {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                Chart.helpers.each(
                                    meta.data.forEach((bar, index) => {
                                        const fillTextSuffix = chartInstance.options.fillTextSuffix || '';
                                        const fillText = dataset.data[index] + fillTextSuffix;

                                        let x = bar._model.x - textMargin;
                                        ctx.textAlign = 'right';
                                        ctx.textBaseline = 'middle';
                                        ctx.fillStyle = '#fff';
                                        if (x - ctx.measureText(fillText).width < bar._model.base + textMargin) {
                                            x = bar._model.x + textMargin;
                                            ctx.textAlign = 'left';
                                            ctx.fillStyle = '#000';
                                        }

                                        ctx.fillText(
                                            fillText,
                                            //Math.max(bar._model.base + textMargin, bar._model.x - textMargin),
                                            x,
                                            bar._model.y + fontPatch /* + bar._xScale.height / 2 */
                                        );
                                    }),
                                    this
                                );
                            }),
                            this
                        );
                    },
                },
            },
            options: {},
            interval: null,
        };
    },
    mounted() {
        this.mergeOptions();
        this.renderChart(this.chartData, this.options);
        this.interval = setInterval(() => {
            const canvas = this.$refs.canvas;
            if (!canvas) return;
            let sHeight = canvas.style.height;
            sHeight = Number(sHeight.substr(0, sHeight.length - 2));
            if (sHeight && this.height !== 400 && sHeight !== this.height) {
                console.log(sHeight, this.height);
                canvas.setAttribute('height', this.height);
                canvas.style.height = this.height + 'px';
                Vue.nextTick(() => this.renderChart(this.chartData, this.options));
            }
        }, 3000)
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    watch: {
        chartData() {
            this.$data._chart.update()
        },
        styles() {
            this.$data._chart.update()
        },
        chartOptions: {
            handler(v) {
                this.mergeOptions();
                this.$data._chart.update()
                // this.renderChart(this.chartData, this.options);
            },
            deep: true,
        },
    },
    methods: {
        mergeOptions() {
            this.options = mergeDeep({}, this.$data._options, this.chartOptions);
            // {
            // 	...this.$data._options,
            // 	...this.chartOptions,
            // 	scales: {
            // 		...this.$data._options.scales,
            // 		...(this.chartOptions.scales ? this.chartOptions.scales : {}),
            // 		xAxes: [
            // 			...(this.chartOptions.scales && this.chartOptions.scales.xAxes ? this.chartOptions.scales.xAxes : this.$data._options.scales.xAxes),
            // 		],
            // 		yAxes: [
            // 			...(this.chartOptions.scales && this.chartOptions.scales.yAxes ? this.chartOptions.scales.yAxes : this.$data._options.scales.yAxes),
            // 		],
            // 	}
            // };
        },
    },
};
