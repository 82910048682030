<template>
    <transition name="fadeHeight" mode="out-in">
        <div
            class="report-download"
            v-if="visible"
        >
            <div class="report-download-wrap">
                <div class="report-download-inner">
                    <div
                        v-if="hasOtherTypes"
                        class="report-download-items"
                    >
                        <div class="report-download-title">Данные таблицы</div>
                        <div class="report-download-items-wrap">
                            <RadioInput
                                class="report-download-item"
                                :hidden="true"
                                v-model="fileTypeLocal"
                                optionValue="xlsx"
                                name="fileType"
                                @change="changeHandler('xlsx')"
                            >
                                .xlsx
                            </RadioInput>
                            <RadioInput
                                class="report-download-item"
                                :hidden="true"
                                v-model="fileTypeLocal"
                                optionValue="csv"
                                name="fileType"
                                @change="changeHandler('csv')"
                            >
                                .csv
                            </RadioInput>
                        </div>
                    </div>
                    <div class="report-download-items">
                        <div class="report-download-title">Скачайте отчет в формате</div>
                        <div class="report-download-items-wrap">
                            <RadioInput
                                class="report-download-item"
                                :hidden="true"
                                v-model="fileTypeLocal"
                                optionValue="pdf"
                                name="fileType"
                                @change="changeHandler('pdf')"
                            >
                                .pdf
                            </RadioInput>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import RadioInput from '@/components/Form/RadioInput';
import { mapGetters } from 'vuex';

export default {
    name: 'ReportDownload',
    model: {
        event: 'changeFileType',
        prop: 'fileType',
    },
    props: {
        hasOtherTypes: {
            type: Boolean,
            required: false,
            default: true,
        },
        visible: {
            type: Boolean,
            required: false,
            default: false,
        },
        fileType: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            fileTypeLocal: null,
        };
    },
    computed: {
        ...mapGetters(['is4K']),
        height() {
            if (this.is4K) {
                return this.visible ? (this.hasOtherTypes ? '235px' : '135px') : '0px';
            } else {
                return this.visible ? (this.hasOtherTypes ? '162px' : '101px') : '0px';
            }
        }
    },
    watch: {
        fileType() {
            this.fileTypeLocal = this.fileType;
        }
    },
    methods: {
        changeHandler(val) {
            this.$emit('changeFileType', val);
            this.toggleVisibility();
        },
        toggleVisibility() {
            this.$emit('toggleVisibility');
        },
    },
    components: {
        RadioInput,
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.report-download {
    transition: $transition;
    overflow: hidden;
    font-family: 'Roboto';
    width: 100%;
    top: calc(100% + 4px);
    left: 0;
    z-index: 1;
    background-color: #fff;
    position: absolute;
    box-shadow: $shadow;
    &-wrap {
        padding: 15px 16px 16px;
    }
    &-item {
        display: inline-block;
        color: $c-dark;
        font-size: 1.4rem;
        line-height: (16/14);
        padding: 3px 11px;
        border-radius: 4px;
        border: 1px solid $c-grey4;
        box-sizing: border-box;
        &:nth-child(n + 1) {
            margin-left: 10px;
        }
        &.checked {
            border-color: $c-dark;
        }
        &:hover {
            border-color: $c-primary;
        }
    }
    &-items {
        &-wrap {
            display: flex;
            justify-content: flex-start;
        }
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-title {
        color: $c-grey;
        font-size: 1.3rem;
        line-height: (16/13);
        margin-bottom: 10px;
    }
}

@media (min-width: 2320px) {
    .report-download {
        &-wrap {
            padding: 20px;
        }
        &-item {
            font-size: 2rem;
            padding: 5px 16px;
            border-radius: 6px;
        }
        &-items {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        &-title {
            font-size: 1.9rem;
            margin-bottom: 15px;
        }
    }
}
</style>

<style lang="scss">
    @import '@/styles/vars.scss';

    .report-download-item {
        cursor: pointer;
    }
    .fadeHeight-enter-active,
    .fadeHeight-leave-active {
        transition: $transition;
        max-height: 180px;
    }
    .fadeHeight-enter,
    .fadeHeight-leave-to
    {
        max-height: 0px;
    }
</style>
