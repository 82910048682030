<template>
    <div class="widget-total" :class="{'w-smi': title === 'СМИ'}">
        <div class="widget-title-wrap section-subtitle">
            <div>{{ title }}</div>
            <div
                v-if="compareDate.startDate && 'value2' in totals[0]"
                class="widget-legend"
            >
                <div class="widget-legend-item">Период 1</div>
                <div class="widget-legend-item">Период 2</div>
            </div>
        </div>
        <div class="widget-values" :class="{'widget-values--single': !compareDate.startDate}">
            <div
                v-for="total in totals"
                :key="total.key"
                class="widget-cell"
                :class="{'w-loyality': total.key == 'Индекс лояльности', 'w-mentions': total.key == 'Всего упоминаний'}"
            >
                <div class="widget-cell-caption">
                    <div class="widget-icon-wrapper">
                        <img
                            :src="iconSource(total.icon)"
                            :alt="total.icon"
                            class="widget-icon"
                        >
                    </div>
                    <div class="widget-cell-title">
                        {{ total.key }}
                        <div v-if="total.help" class="widget-cell-help" v-tooltip="total.help">?</div>
                    </div>
                </div>
                <div class="widget-cell-value widget-cell-value--primary">{{ total.value | format_number(false, total.icon === 'nps' ? 4 : 2) }}</div>
                <template v-if="total.key in differences">
                    <div class="widget-cell-value widget-cell-value--secondary">{{ total.value2 | format_number(false, total.icon === 'nps' ? 4 : 2) }}</div>
                    <div
                        class="widget-cell-difference"
                        :style="{color: differences[total.key].color}"
                    >
                        {{ differences[total.key].value }} %
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { greenColor, greyColor, primaryColor } from '@/styles/vars.scss';
import { mapGetters } from "vuex";

export default {
    name: 'TotalWidget',
    data() {
        return {
            differences: {},
            classes: {},
            classesTimeout: null,
            classesTimeoutSetAt: null,
            lastProgressTime: 0,
        };
    },
    computed: {
        ...mapGetters([ 'isMobile', 'compareDate', 'progressTime']),
    },
    props: {
        title: String,
        totals: Array,
    },
    watch: {
        totals: {
            handler(v, ov) {
                if (this.progressTime > this.lastProgressTime) {
                    this.lastProgressTime = this.progressTime;
                    const classes = {};
                    for (const item of v) {
                        const oldItemValue = ov.find(ovi => ovi.key === item.key).value;
                        if (item.value > oldItemValue) {
                            classes[item.key] = 'increment'
                        } else if (item.value < oldItemValue) {
                            classes[item.key] = 'decrement';
                        }
                    }
                    if (!Object.keys(classes).length) return;
                    clearTimeout(this.classesTimeout);
                    this.classesTimeoutSetAt = Date.now();
                    this.classesTimeout = setTimeout(() => {
                        if (Date.now() - this.classesTimeoutSetAt >= 800) {
                            this.classes = {};
                        }
                    }, 800);
                    this.classes = classes;
                }
                this.updateDifferences();
            },
            deep: true,
        },
        compareDate:{
            handler(val, oldVal) {
                this.updateDifferences();
            },
            deep: true
        },
    },
    created() {
        this.updateDifferences();
    },
    methods: {
        iconSource(icon) {
            return '/img/icons/totals/' + icon + ([ 'hierarchy', 'dislike' ].includes(icon) ? '.png' : '.svg');
        },
        updateDifferences() {
            const differences = {};
            for (const total of this.totals) {
                if (this.compareDate.startDate && 'value2' in total) {
                    if (total.value2 === undefined) continue;
                    let value = this.getDifferenceInPercent(total.value, total.value2);
                    let color = primaryColor;
                    if (value === 0) {
                        color = greyColor;
                    } else if (value > 0) {
                        value = '+' + value;
                        color = greenColor;
                    }
                    differences[total.key] = {
                        value,
                        color,
                    };
                }
            }
            this.differences = differences;
        },
        getDifferenceInPercent(a, b) {
            return a === 0 ? 0 : Math.round(((b - a) * 100) / a);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars';

.widget-total:not(:last-child) {
    margin-bottom: 48px;
}

.widget-title-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.widget-legend {
    display: flex;
    align-items: center;
    margin-left: 16px;

    &-item {
        font-weight: 500;
        font-size: 14px;
        margin-right: 15px;

        &::before {
            content: '';
            position: relative;
            display: inline-block;
            margin-right: 10px;
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: $c-dark;
        }

        &:nth-child(2) {
            &::before {
                background-color: $c-grey;
            }
        }
    }
}

.section-title {
    margin-bottom: 17px;
    margin-left: 4px;
}

.widget-values {
    display: flex;
    justify-content: space-between;
    padding: 0 29px 0 8px;
    margin-bottom: -20px;
}

.widget-values--fitted {
    &::after {
        display: none !important;
    }
}

.widget-cell-caption {
    display: flex;
    align-items: center;
    /*margin-bottom: 4px;*/
}

.widget-icon {
    &-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 21px;
        /*width: 21px;*/
        margin: 4px 8px 4px 0;
        img {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }
}

.widget-cell {
    text-align: center;
    margin-right: 4px;
    margin-bottom: 20px;

    &:last-child {
        margin-right: 0;
    }
}

.widget-cell-title {
    font-size: 14px;
}

.widget-cell-help {
    margin-left: 5px;
    display: inline-block;
    font-size: 12px;
    background-color: rgba(#1c1c1c, .6);
    color: #fff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    cursor: default;
}

.widget-cell-value {
    font-size: 32px;
    font-weight: bold;
    transition: color .3s ease;

    &--secondary {
        color: $c-grey;
    }
}

.widget-cell {
    &.increment {
        .widget-cell-value--primary {
            color: $c-green;
        }
    }
    &.decrement {
        .widget-cell-value--primary {
            color: $c-primary;
        }
    }
}

.widget-cell-difference {
    margin-top: 2px;
    font-weight: bold;
    font-size: 14px;
}

@media (min-width: 2320px) {
    .widget-total:not(:last-child) {
        margin-bottom: 76px;
    }
    .widget-legend {
        margin-left: 26px;

        &-item {
            font-size: 21px;
            margin-right: 25px;

            &::before {
                margin-right: 15px;
                width: 14px;
                height: 14px;
            }
        }
    }
    .section-title {
        margin-bottom: 36px;
    }
    .widget-values {
        padding: 0 40px 0 15px;
    }
    .widget-icon {
        &-wrapper {
            height: unset;
            margin: 7px 12px 7px 0;

            img {
                height: 32px;
                width: 32px;
            }
        }
    }
    .widget-cell {
        margin-right: 7px;
    }
    .widget-cell-title {
        font-size: 22px;
    }
    .widget-cell-value {
        font-size: 51px;
    }
    .widget-cell-difference {
        margin-top: 4px;
        font-size: 20px;
    }
}

@media (max-width: 1300px) {
    .widget-values {
        padding: 0;
    }
    .section-title {
        margin-left: 0;
    }
    .widget-icon {
        &-wrapper {
            margin-right: 3px;
        }
    }

    .widget-cell-value {
        font-size: 28px;
    }
}

@media (max-width: 1200px) {
    .widget-cell {
        width: 25%;
        margin-right: 0;
        padding: 0 15px;
    }
    .widget-values {
        flex-wrap: wrap;
        justify-content: flex-start;

        &::after {
            content: '';
            flex: auto;
            width: 25%;
            min-width: 25%;
        }
    }
    .widget-cell {
        margin-top: 10px;
        flex: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .widget-cell-value {
        text-align: left;
    }
    .section-title {
        margin-bottom: 7px;
    }
}

@media (max-width: 1000px) {
    .widget-total:not(:last-child) {
        margin-bottom: 40px;
    }
    .section-title {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    .widget-values {
        justify-content: flex-start;
        &::after {
            display: none
        }
    }
    .widget-cell {
        flex: unset;
        box-sizing: border-box;
        width: 33.3333333%;
        padding: 0 5px;

        &-title {
            font-size: 12px;
        }

        &-value {
            font-size: 20px;
        }
    }
}


@media (max-width: 738px) {
    .widget-legend-item {
        font-size: 12px;
        margin-right: 8px;
        &::before {
            margin-right: 6px;
        }
    }
    .widget-cell {
        width: 50%;
    }
}

@media (max-width: 600px) {
    * {
        box-sizing: border-box;
    }
    .widget-legend {
        margin: 10px 0;
        width: 100%;
    }
    .widget-cell {
        justify-content: space-between;
        text-align: left;
        flex-direction: row;
        padding: 0 2px;
        width: 100%;
        &-title {
            font-size: 12px;
            padding: 0 2px;
        }
        &-value {
            font-size: 14px;
            padding: 0 2px;
        }
        &-difference {
            font-size: 10px;
            text-align: right;
        }
        &-caption {
            width: 50%;
        }
        &-value {
            width: 20%;
        }
    }
    .widget-values--single {
        .widget-cell-value {
            text-align: right;
        }
    }
    .widget-icon-wrapper  {
        width: 18px;
        margin-right: 8px;
        align-items: flex-start;
    }
}
</style>
