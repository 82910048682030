<template>
    <div class="chart-icons">
        <div class="chart-desc">Выберите вид отображения:</div>
        <div
            @click="toggleChart"
            :class="['chart-icon', activeChart === 'pie' ? 'active' : null]"
        >
            <PieChartIcon :strokeColor="activeChart === 'pie' ? '#28b93c' : '#878787'" />
        </div>
        <div
            @click="toggleChart"
            :class="['chart-icon', activeChart === 'verticalBar' ? 'active' : null]"
        >
            <VerticalBarChartIcon :strokeColor="activeChart === 'verticalBar' ? '#28b93c' : '#878787'" />
        </div>
    </div>
</template>

<script>
import VerticalBarChartIcon from '@/components/icons/VerticalBarChartIcon';
import PieChartIcon from '@/components/icons/PieChartIcon';

export default {
    name: 'ChooseChart',
    components: { VerticalBarChartIcon, PieChartIcon },
    props: {
        activeChart: {
            type: String,
        },
    },
    methods: {
        toggleChart() {
            this.$emit('click');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.chart {
    &-desc {
        color: #1c1c1c;
        font-size: 1.2rem;
        line-height: 1;
        margin-right: 10px;
    }
    &-icons {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    &-icon {
        transition: $transition;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 21px;
        height: 21px;
        line-height: 0;
        box-sizing: border-box;
        border: 1px solid #878787;
        border-radius: 1px;
        margin-right: 7px;
        cursor: pointer;
        svg {
            width: 100%;
        }
        &:last-child {
            margin-right: 0;
        }
        &.active {
            border-color: #28b93c;
        }
        &:hover {
            border-color: #731982;
        }
    }
}

@media (min-width: 2320px) {
    .chart {
        &-desc {
            font-size: 2.2rem;
            margin-right: 20px;
        }
        &-icons {
            margin-bottom: 20px;
        }
        &-icon {
            width: 32px;
            height: 32px;
            border-radius: 2px;
            margin-right: 12px;
            svg {
                height: 24px;
            }
        }
    }
}
</style>
