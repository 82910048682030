export default {
    data() {
      return {
          reportReady: false,
          _reportData: [],
      }
    },
    methods: {
        async $prepareReport(method, params) {
            this.reportReady = false;
            this.$data._reportData = await this[method]({...this[params], page: 1, limit: -1, justReturn: true});
            this.reportReady = true;
        }
    }
};
