import Vue from 'vue';

Vue.filter('format_number', function (value, spaced, precision = 2) {
    if (value !== 0 && (!value || isNaN(value))) return '';
    let suffix = '';
    const suffixes = [ '', 'K', 'M', 'B' ];
    for (let i = 0; i < suffixes.length; i++) {
        const classOfNumber = Math.pow(1000, i);
        const nextClassOfNumber = classOfNumber * 1000;
        if (value < nextClassOfNumber) {
            if (i === 1) {
                break;
            }
            const mod = Math.pow(10, precision);
            value = Math.round(value * mod / classOfNumber) / mod;
            suffix = suffixes[i];
            break;
        }
    }
    return value.toLocaleString('ru-RU', {maximumFractionDigits: precision}) + (spaced ? ' ' : '') + suffix;
});
