<template>
    <svg
        width="15.27"
        height="15.27"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="8.02246"
            cy="8.46826"
            r="7.29608"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
        <circle
            cx="8.02232"
            cy="8.46812"
            r="2.93364"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
        <path
            d="M7.70557 0.975586L7.83945 5.2983"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
        <path
            d="M15.2979 10.1553L11.0855 9.17549"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
        <path
            d="M0.549072 7.70508L4.85926 8.06021"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
        <path
            d="M1.7605 12.5952L5.40409 10.2654"
            :stroke="strokeColor"
            stroke-width="0.676397"
        />
    </svg>
</template>

<script>
export default {
    name: 'PieChartIcon',
    props: {
        strokeColor: {
            type: String,
            required: false,
            default: '#878787',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

svg path,
svg circle {
    transition: $transition;
}
</style>
