<template>
    <div v-if="choosedLabels.length">
        <div class="vertical-bar-chart">
            <Loading v-if="!chartData.labels || !chartData.labels.length" :loaded="loaded"/>
            <BarChartVertical
                v-else
                @onClick="chooseLabel"
                :styles="styles"
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
        </div>
        <div
            v-if="choosedLabels.length"
            class="vertical-bar-labels"
        >
            <div
                v-for="(label, i) in choosedLabels"
                :key="i"
                class="vertical-bar-label"
            >
                <span>{{label}}</span>
                <span @click="unChooseLabel(label)">
                    <CloseIcon />
                </span>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="vertical-bar-chart">
            <Loading v-if="!chartData.labels || !chartData.labels.length" :loaded="loaded"/>
            <BarChartVertical
                v-else
                @onClick="chooseLabel"
                :styles="styles"
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
        </div>
    </div>
</template>

<script>
import BarChartVertical from '@/components/Charts/BarChartVertical';
import CloseIcon from '@/components/icons/Close';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
import { minWidth } from './RiskyMentions.vue?vue&type=style&index=0&lang=scss&module=1';

const _mobileBreakPoint = { windowWidth: 988, minWidth: parseFloat(minWidth) };
export default {
    name: 'VerticalBarChartComponent',
    model: {
        prop: 'selected',
        event: 'changed',
    },
    props: {
        haveSelection: {
            type: Boolean,
            required: false,
            default: true,
        },
        labels: {
            type: Array,
            required: true,
        },
        loaded: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Array,
            required: true,
        },
        data2: {
            type: Array,
            required: true,
        },
        selected: {
            type: Array,
            default: () => [],
        },
        maxRotation: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        ...mapGetters(['windowWidth', 'is4K']),
        isMobileChart() {
            return this.windowWidth <= _mobileBreakPoint.windowWidth;
        },
    },
    watch: {
        windowWidth() {
            this.setStyles();
        },
        isMobileChart(val, oldVal) {
            if (val !== oldVal) {
                this.setStyles();
                this.fillData();
            }
        },
        is4K() {
            this.setStyles();
            this.fillData();
        },
        data: {
            handler() {
                this.fillData();
            },
            deep: true,
        },
        data2: {
            handler() {
                this.fillData();
            },
            deep: true,
        },
        choosedLabels(choosedLabels) {
            // console.log('changed', choosedLabels);
            this.$emit('changed', choosedLabels);
        },
        selected(choosedLabels) {
            this.choosedLabels = choosedLabels;
        }
    },
    data() {
        return {
            choosedLabels: [],
            barThicknessWidth: 18,
            chartData: {},
            chartOptions: {
                cornerRadius: 4,
                haveBottomRadius: false,
                innerPadding: 4,
                barFontSize: 13,
                scales: {
                    xAxes: [{ ticks: { fontSize: 9, maxRotation: 0 } }],
                    yAxes: [{ ticks: { fontSize: 12 } }],
                },
                layout: {
                    padding: {
                        left: 21,
                        right: 54,
                        top: 40,
                        bottom: 40,
                    },
                },
            },
            styles: {
                paddingTop: 8 + 'px',
                height: 402 + 'px',
                marginBottom: -30 + 'px',
                marginLeft: -6 + 'px',
                marginRight: -6 + 'px',
                width: 'unset',
                minWidth: 1070 + 'px',
            },
        };
    },
    mounted() {
        this.setStyles();
        setTimeout(() => this.fillData(), 1000);
    },
    methods: {
        unChooseLabel(label) {
            this.choosedLabels = this.choosedLabels.filter((l) => l !== label);
        },
        chooseLabel(val) {
            if (!this.haveSelection) return;
            if (typeof val === 'string' && !this.choosedLabels.includes(val)) {
                this.choosedLabels = [...this.choosedLabels, val];
            } else if (typeof val === 'object' && !this.choosedLabels.find(el => el === val.join(' '))) {
                this.choosedLabels = [...this.choosedLabels, val.join(' ')];
            }
        },
        fillData() {
            const labels = window.fixWordWrap(this.labels, 12);
            const data = this.data;
            const data2 = this.data2;

            const styles = {
                defaultFontColor: '#28b93c',
                backgroundColor: '#28b93c',
                barThickness: this.barThicknessWidth + this.chartOptions.innerPadding,
            };
            const styles2 = { ...styles, backgroundColor: '#878787' };
            const datasets = [
                {
                    ...styles,
                    data,
                },
            ];
            if (this.data2.length) {
                datasets.push({
                    ...styles2,
                    data: data2,
                });
            }
            datasets.reverse();
            this.chartData = {
                labels,
                datasets,
            };
        },
        setStyles() {
            this.chartOptions.scales.xAxes[0].ticks.maxRotation = this.maxRotation;
            if (this.isMobileChart) {
                this.styles.height = 402 + 'px';
                this.cornerRadius = 4;
                this.styles.marginBottom = '-10px';
                this.barThicknessWidth = 12.32;
                this.chartOptions.innerPadding = 2.74;
                this.chartOptions.barFontSize = 9;
                this.chartOptions.scales.yAxes[0].ticks.fontSize = 9; // 8 by design
                this.chartOptions.scales.xAxes[0].ticks.fontSize = 9; // 8 by design
                this.chartOptions.layout.padding = {
                    left: 15,
                    right: 25,
                    top: 30,
                    bottom: 30,
                };
            } else {
                if (this.is4K) {
                    this.styles.height = 610 + 'px';
                    this.cornerRadius = 6;
                    this.styles.width = 'unset';
                    this.styles.marginBottom = '-20px';
                    this.barThicknessWidth = 29;
                    this.chartOptions.innerPadding = 6.44;
                    this.chartOptions.barFontSize = 20;
                    this.chartOptions.scales.yAxes[0].ticks.fontSize = 19;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 14;
                    this.chartOptions.layout.padding = {
                        left: 31,
                        right: 64,
                        top: 60,
                        bottom: 60,
                    };
                } else {
                    this.styles.height = 402 + 'px';
                    this.cornerRadius = 4;
                    this.styles.width = 'unset';
                    this.styles.marginBottom = '-30px';
                    this.barThicknessWidth = 18;
                    this.chartOptions.innerPadding = 4;
                    this.chartOptions.barFontSize = 13;
                    this.chartOptions.scales.yAxes[0].ticks.fontSize = 12;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 9;
                    this.chartOptions.layout.padding = {
                        left: 21,
                        right: 54,
                        top: 40,
                        bottom: 40,
                    };
                }
            }
        },
    },
    components: {
        BarChartVertical,
        CloseIcon,
        Loading,
    },
};
</script>

<style lang="scss" scoped>
$min-width: 821px;

.vertical-bar-chart {
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}
.vertical-bar {
    &-labels {
        margin-bottom: -34px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &-label {
        margin-bottom: 10px;
        outline: none;
        padding: 6px 7px 6px 10px;
        font-family: 'Roboto';
        font-size: 13px;
        line-height: (16/13);
        color: #333333;
        border: 1px solid #555555;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        align-items: center;
        &:last-child {
            margin-right: 0;
        }
        span:last-child {
            line-height: 0;
            margin-left: 14px;
        }
    }
}
@media (min-width: 2320px) {
    .vertical-bar {
        &-labels {
            margin-bottom: -40px;
            margin-top: 40px;
        }
        &-label {
            margin-bottom: 16px;
            padding: 10px 12px 10px 15px;
            font-size: 18px;
            border: 1px solid #555555;
            border-radius: 6px;
            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }
            span:last-child {
                margin-left: 20px;
                svg {
                    width: 16px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .vertical-bar {
        &-labels {
            margin-top: 20px;
            margin-bottom: -20px;
        }
    }
}
@media (max-width: 768px) {
    .vertical-bar {
        &-label {
            margin-bottom: 6px;
            padding: 5px 5px 5px 7px;
            font-size: 10px;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
            span:last-child {
                margin-left: 8px;
                svg {
                    width: 9px;
                }
            }
        }
    }
}
@media (max-width: 1200px) {
    .vertical-bar-chart {
        overflow-x: auto;
    }
}

:export {
    minWidth: $min-width;
}
</style>
