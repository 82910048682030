<script>
import DateRangePickerCustom from '@/components/DateRangePickerCustom';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'DateRangePickerOpen',
    extends: DateRangePickerCustom,
    mounted() {
        this.open = true;
    },
    methods: {
        togglePicker() {
            this.open = true;
        }
    },
}
</script>
