<template>
    <div class="soc-risky-mentions" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: Рисковые упоминания</div>
            </div>
        </div>
        <Form
            :comparedatePosBreakPoints="{900: 'center', 1440: 'right', 10000: 'right'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'region', 'compareDate']"
            @download="$downloadReport($data._reportData, $event)"
            @prepare="$prepareReport('getSocialRiskyMentionMessages', 'apiQueryParamsTitles')"
        />
        <div>
            <div class="container">
                <VerticalBarChartComponent
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                    :loaded="socialRiskyMentionsLoaded"
                    v-model="titles"
                />
            </div>
        </div>
        <section>
            <div class="container">
                <div class="table-wrap">
                    <Posts
                        @click="postClick"
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :posts="socialRiskyMentionMessages"
                    />
                </div>
                <Pagination
                        v-model="page"
                        :pages="socialRiskyMentionMessagesPages"
                />
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import Pagination from '@/components/Pagination';
import downloadReport from '@/mixins/downloadReport';
import prepareReport from '@/mixins/prepareReport';
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'SocNetRiskyMentions',
    data() {
        return {
            orderData: {},
            postHeaders: {
                dateTime: { title: 'Дата', class: 'td--date', format_number: false, ordering: true, type: 'dateTimeStatus'},
                description: { title: 'Описание', class: 'td--text', format_number: false, ordering: true, },
                link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false, },
                region: { title: 'Округ', class: 'td--text td--center', format_number: false, ordering: true, type: 'nameBranch' },
                title2: { title: 'Проблема 2 ур.', class: 'td--text', format_number: false, ordering: true, },
                responsible: { title: 'Ответственный', class: 'td--text td--center', format_number: false, ordering: false, },
                measures: { title: 'Принятые меры', class: 'td--text', format_number: false, ordering: false, },
            },
            reportColumns: [ '_id', 'dateTime', 'description', 'link', 'region', 'title2', 'responsible', 'measures' ],
            titles: [],
            page: 1,
            progressTime: 0,
            timeout: null,
            timeout2: null,
            timeout3: null,
        };
    },
    components: {
        Form,
        Posts,
        Pagination,
        VerticalBarChartComponent,
    },
    mixins: [ downloadReport, prepareReport ],
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'socialRiskyMentionsLoaded',
            'socialRiskyMentions',
            'socialRiskyMentionMessages',
            'socialRiskyMentionMessagesPages',
            'userAccessSocmedia',
            'region',
            // 'progressTime',
        ]),
        ...mapGetters({
            socialRiskyMentions2Global: "socialRiskyMentions2",
        }),
        socialRiskyMentions2() {
            return this.compareDate.startDate ? this.socialRiskyMentions2Global : {};
        },
        labels() {
            return this.socialRiskyMentions.map(item => item.name);
        },
        data() {
            return this.socialRiskyMentions.map(item => item.value);
        },
        data2() {
            return this.socialRiskyMentions.map(item => this.socialRiskyMentions2[item.name]);
        },
        apiQueryParams() {
            return {
                date: this.date,
                limit: 10,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                limit: 10,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
            }
        },
        apiQueryParamsTitles() {
            return {
                date: this.date,
                titles: this.titlesToFilter,
                progressTime: this.progressTime,
                page: this.page,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        titlesToFilter() {
            return this.titles.length ? this.titles : undefined;
        }
    },
    watch: {
        apiQueryParams() {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.userAccessSocmedia && this.getSocialRiskyMentions(this.apiQueryParams);
            }, 2000);
        },
        apiQueryParamsCompare() {
            clearTimeout(this.timeout2);
            this.timeout2 = setTimeout(() => {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getSocialRiskyMentions2(this.apiQueryParamsCompare);
                }
            }, 2000);
        },
        apiQueryParamsTitles() {
            clearTimeout(this.timeout3);
            this.timeout3 = setTimeout(() => {
                this.userAccessSocmedia && this.getSocialRiskyMentionMessages(this.apiQueryParamsTitles);
            }, 2000);
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.timeout = setTimeout(() => {
            this.getSocialRiskyMentions(this.apiQueryParams);
        }, 500);
        this.timeout2 = setTimeout(() => {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.getSocialRiskyMentions2(this.apiQueryParamsCompare);
            }
        }, 500);
        this.timeout3 = setTimeout(() => {
            this.getSocialRiskyMentionMessages(this.apiQueryParamsTitles);
        }, 500);
        this.interval = setInterval(() => {
            this.progressTime = Date.now();
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    methods: {
        ...mapActions([
            'getSocialRiskyMentions',
            'getSocialRiskyMentions2',
            'getSocialRiskyMentionMessages',
            'getSocialModalMessage',
        ]),
        postClick(post) {
            this.getSocialModalMessage({
                id: post._id,
                date: post.dateTime.date,
                datetime: post.dateTime.time,
                status: post.dateTime.status,
                link: post.link,
                regionname: post.region.name,
                regionbranch: post.region.branch,
                problem: post.title2,
                responsible: post.responsible,
                measures: post.measuresf,

            });
        },
    }
};
</script>

<style lang="scss">
@media (max-width: 1029px) and (min-width: 1014px) {
    .soc-risky-mentions {
        .form-field.comparedate .opensright {
            transform: translateX(-20%);
        }
    }
}
</style>
