<template>
    <svg
        width="14.91"
        height="13.98"
        viewBox="0 0 16 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.870961"
            y="8.33556"
            width="3.87451"
            height="5.77328"
            rx="0.474551"
            :stroke="strokeColor"
            stroke-width="0.693582"
        />
        <rect
            x="6.04015"
            y="0.827748"
            width="3.87451"
            height="13.2816"
            rx="0.474551"
            :stroke="strokeColor"
            stroke-width="0.693582"
        />
        <rect
            x="11.2098"
            y="4.33312"
            width="3.87451"
            height="9.77619"
            rx="0.474551"
            :stroke="strokeColor"
            stroke-width="0.693582"
        />
    </svg>
</template>

<script>
export default {
    name: 'VerticalBarChartIcon',
    props: {
        strokeColor: {
            type: String,
            required: false,
            default: '#878787',
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

svg rect {
    transition: $transition;
}
</style>
