import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        selectedPressSources: [],
        socialRiskyMentionsLoaded: false,
        socialRiskyMentions: [],
        socialRiskyMentions2: {},
        socialRiskyMentionMessages: {
            data: [],
            total: 0,
        },
        pressPosts: [],
        pressPostsPages: 1,
    },
    mutations: {
        setSelectedPressSources(state, val) {
            state.selectedPressSources = val;
        },
        setSocialRiskyMentions(state, val) {
            state.socialRiskyMentions = val;
            state.socialRiskyMentionsLoaded = true;
        },
        setSocialRiskyMentions2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item.value;
            });
            state.socialRiskyMentions2 = data;
        },
        setSocialRiskyMentionMessages(state, val) {
            state.socialRiskyMentionMessages = {
                ...val,
                data: val.data.map(message => {
                    if (!message.dateTime.status) {
                        message.dateTime.status = 'Новый';
                    }
                    if (!message.region.branch) {
                        message.region.branch = '-';
                    }
                    if (!message.responsible) {
                        message.responsible = '-';
                    }
                    if (!message.measures) {
                        message.measures = '-';
                    }
                    return message;
                })
            };
        },
    },
    actions: {
        async getSocialRiskyMentions({ dispatch, commit }, {date, tonality, region}) {
            try {
                const res = await axios.get('/social/riskymentions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialRiskyMentions', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialRiskyMentions2({ dispatch, commit }, {date, tonality, region, names}) {
            try {
                const res = await axios.get('/social/riskymentions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        names,
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setSocialRiskyMentions2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialRiskyMentionMessages({ state, dispatch, commit }, {date, tonality, titles, limit, page = 1, region, orderBy, orderDir, justReturn}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    titles,
                    page,
                    region,
                    orderby: orderBy,
                    orderdir: orderDir,
                    limit: limit || state.perPage,
                };
                const res = await axios.get('/social/riskymentionmessages', {params});
                const resData = res.data;
                if (!justReturn) {
                    commit('setSocialRiskyMentionMessages', resData);
                }
                return resData.data;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        // async getPressPosts({ state, dispatch, commit }, {date, tonality, sources, page = 1}) {
        //     try {
        //         const params = {
        //             date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
        //             tonality,
        //             page,
        //             perpage: state.perPage,
        //         };
        //         if (sources && sources.length) {
        //             params.sources = sources;
        //         }
        //         const res = await axios.get('/smi/posts', {params});
        //         const resData = res.data;
        //         commit('setPressPosts', resData);
        //         return resData;
        //     } catch (e) {
        //         // socketError({ dispatch, commit }, e);
        //         return false;
        //     }
        // },
    },
    getters: {
        socialRiskyMentionsLoaded(state) {
            return state.socialRiskyMentionsLoaded;
        },
        socialRiskyMentions(state) {
            return state.socialRiskyMentions;
        },
        socialRiskyMentions2(state) {
            return state.socialRiskyMentions2;
        },
        socialRiskyMentionMessages(state) {
            return state.socialRiskyMentionMessages.data;
        },
        socialRiskyMentionMessagesPages(state) {
            return Math.max(1,
                Math.ceil((state.socialRiskyMentionMessages.total || 0 ) / state.perPage)
            );
        },
        // pressPosts(state) {
        //     return state.pressPosts.data;
        // },
        // pressPostsPages(state) {
        //     return Math.max(1,
        //         Math.ceil((state.pressPosts.total || 0 ) / state.perPage)
        //     );
        // }
    },
};
