<template>
    <div class="doughnut-chart-wrap">
        <div
            v-if="data2.length"
            class="doughnut-chart doughnut-chart-compare"
        >
            <DoughnutChart
                class="doughnut-chart-part"
                :styles="styles"
                :chart-options="chartOptions"
                :chartData="chartData"
                @outerRadiusChanged="outerRadiusChanged"
            />
            <DoughnutChart
                class="doughnut-chart-part"
                :styles="styles"
                :chart-options="chartOptions2"
                :chartData="chartData2"
            />
        </div>
        <div
            v-else
            class="doughnut-chart"
        >
            <DoughnutChart
                :styles="styles"
                :chart-options="chartOptions"
                :chartData="chartData"
            />
        </div>
        <div class="doughnut-chart-labels">
            <div class="labels-col">
                <div
                    v-for="(label, i) in labels.slice(0, Math.ceil(labels.length/2))"
                    :key="i"
                    class="doughnut-chart-label"
                >
                    <div
                        class="doughnut-chart-label-circle"
                        :style="{backgroundColor: colors[i] ? colors[i] : colors[i % colors.length]}"
                    />
                    <span>{{typeof label === 'string' ? label : label.join(' ')}}</span>
                </div>
            </div>
            <div class="labels-col">
                <div
                    v-for="(label, i) in labels.slice(Math.ceil(labels.length/2))"
                    :key="i"
                    class="doughnut-chart-label"
                >
                    <div
                        class="doughnut-chart-label-circle"
                        :style="{backgroundColor: colors[i + Math.ceil(labels.length/2)]
                            ? colors[i + Math.ceil(labels.length/2)]
                            : colors[(i + Math.ceil(labels.length/2)) % colors.length]
                         }"
                    />
                    <span>{{typeof label === 'string' ? label : label.join(' ')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DoughnutChart from '@/components/Charts/DoughnutChart';
import { mapGetters } from 'vuex';

export default {
    name: 'PieChartComponent',
    components: { DoughnutChart },
    computed: mapGetters(['isMobile', 'is4K']),
    props: {
        labels: {
            type: Array,
            required: true,
        },
        data: {
            type: Array,
            required: true,
        },
        data2: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    watch: {
        isMobile() {
            this.setStyles();
        },
        is4K() {
            this.setStyles();
        },
        data() {
            this.fillData();
        },
        data2() {
            this.fillData();
        },
    },
    data() {
        return {
            chartData: {},
            chartData2: {},
            styles: {
                height: '352px',
                width: this.isMobile ? '295px' : '392px',
                margin: '0 auto',
            },
            chartOptions: {
                circumference: 2 * Math.PI,
                rotation: -0.5 * Math.PI,
                layout: {
                    padding: {
                        top: 30,
                        bottom: 30,
                        left: 0,
                        right: 0,
                    },
                },
                totalCount: {
                    text: 0,
                    fontSize: 14,
                },
                chartPoints: {
                    lineWidth: 24,
                    horizontalLineWidth: 28,
                    fontSize: 14,
                },
                cutoutPercentage: 34.93,
            },
            chartOptions2: {
                circumference: 2 * Math.PI,
                rotation: -0.5 * Math.PI,
                layout: {
                    padding: {
                        top: 30,
                        bottom: 30,
                        left: 0,
                        right: 0,
                    },
                },
                totalCount: {
                    text: 0,
                    fontSize: 14,
                },
                chartPoints: {
                    lineWidth: 24,
                    horizontalLineWidth: 28,
                    fontSize: 14,
                },
                cutoutPercentage: 34.93,
            },
            colors: ['#28b93c', '#28b93c', '#28b93c', '#555555', '#656565', '#878787', '#B7B7B7', '#C4C4C4', '#E0E0E0', '#F8F8F8'],
        };
    },
    methods: {
        outerRadiusChanged(outerRadius) {
            if (this.chartOptions2.outerRadius !== outerRadius) {
                this.chartOptions2.outerRadius = outerRadius;
                this.fillData();
            }
        },
        fillData() {
            // console.log(this.styles.width);
            const initData = this.data;
            const total = initData.reduce((a, b) => a + b, 0);
            this.chartOptions.totalCount.text = total;

            this.chartOptions = {
                ...this.chartOptions,
                circumference: 2 * Math.PI,
                rotation: -0.5 * Math.PI,
                layout: {
                    padding: {
                        top: 30,
                        bottom: 30,
                        left: 0,
                        right: 0,
                    },
                },
            };
            this.styles = {
                ...this.styles,
                width: this.isMobile ? '295px' : '392px',
                margin: '0 auto',
            };

            let data = [];
            let backgroundColor = [];
            let labels = [];
            initData.forEach((d, i) => {
                const dataPercent = Math.round((d / total) * 100);
                data.push(dataPercent);
                backgroundColor.push(this.colors[i] ? this.colors[i] : this.colors[i % this.colors.length]);
                labels.push(dataPercent + '%');
            });

            const datasets = [
                {
                    data,
                    backgroundColor,
                    borderWidth: 0,
                },
            ];
            this.chartData = {
                labels,
                datasets,
            };

            if (this.data2.length) {
                this.chartOptions.totalCount.text = null;
                this.chartOptions2.totalCount.text = null;

                this.chartOptions = {
                    ...this.chartOptions,
                    circumference: Math.PI,
                    rotation: Math.PI / 2,
                    layout: {
                        padding: {
                            top: this.chartOptions.layout.padding.top,
                            bottom: this.chartOptions.layout.padding.bottom,
                            left: 50,
                        },
                    },
                };
                this.chartOptions2 = {
                    ...this.chartOptions2,
                    circumference: Math.PI,
                    rotation: -Math.PI / 2,
                    layout: {
                        padding: {
                            top: this.chartOptions2.layout.padding.top,
                            bottom: this.chartOptions2.layout.padding.bottom,
                            right: 50,
                        },
                    },
                };
                this.styles = {
                    ...this.styles,
                    margin: null,
                    width: parseFloat(this.styles.width) / 2 + 'px',
                };
                const initData = this.data2;

                let data = [];
                let backgroundColor = [];
                let labels = [];
                const reversedColors = this.colors;
                initData.forEach((d, i) => {
                    const dataPercent = Math.round((d / total) * 100);
                    data.push(dataPercent);
                    backgroundColor.push(reversedColors[i] ? reversedColors[i] : reversedColors[i % reversedColors.length]);
                    labels.push(dataPercent + '%');
                });

                const datasets = [
                    {
                        data,
                        backgroundColor,
                        borderWidth: 0,
                    },
                ];
                this.chartData2 = {
                    labels,
                    datasets,
                };
                this.chartData.datasets[0].backgroundColor.reverse();
                this.chartData.datasets[0].data.reverse();
                this.chartData.labels.reverse();
            }
        },
        setStyles() {
            if (this.isMobile) {
                this.chartOptions.totalCount.fontSize = 13;
                this.chartOptions2.totalCount.fontSize = 13;
                this.styles = {
                    height: '260px',
                    width: '300px',
                    margin: '0 auto',
                };
                this.chartOptions.layout.padding = {
                    top: 20,
                    bottom: 20,
                    left: 0,
                    right: 0,
                };
                this.chartOptions2.layout.padding = {
                    top: 20,
                    bottom: 20,
                    left: 0,
                    right: 0,
                };
                this.chartOptions.chartPoints = {
                    lineWidth: 15,
                    horizontalLineWidth: 22,
                    fontSize: 10,
                };
                this.chartOptions2.chartPoints = {
                    lineWidth: 15,
                    horizontalLineWidth: 22,
                    fontSize: 10,
                };
            } else {
                if (this.is4K) {
                    this.chartOptions.totalCount.fontSize = 18;
                    this.chartOptions2.totalCount.fontSize = 18;
                    this.styles = {
                        height: '430px',
                        width: '490px',
                        margin: '0 auto',
                    };
                    this.chartOptions.layout.padding = {
                        top: 40,
                        bottom: 40,
                        left: 0,
                        right: 0,
                    };
                    this.chartOptions2.layout.padding = {
                        top: 40,
                        bottom: 40,
                        left: 0,
                        right: 0,
                    };
                    this.chartOptions.chartPoints = {
                        lineWidth: 30,
                        horizontalLineWidth: 40,
                        fontSize: 18,
                    };
                    this.chartOptions2.chartPoints = {
                        lineWidth: 30,
                        horizontalLineWidth: 40,
                        fontSize: 18,
                    };
                } else {
                    this.chartOptions.totalCount.fontSize = 14;
                    this.chartOptions2.totalCount.fontSize = 14;
                    this.styles = {
                        height: '352px',
                        width: '392px',
                        margin: '0 auto',
                    };
                    this.chartOptions.layout.padding = {
                        top: 30,
                        bottom: 30,
                        left: 0,
                        right: 0,
                    };
                    this.chartOptions2.layout.padding = {
                        top: 30,
                        bottom: 30,
                        left: 0,
                        right: 0,
                    };
                    this.chartOptions.chartPoints = {
                        lineWidth: 24,
                        horizontalLineWidth: 28,
                        fontSize: 14,
                    };
                    this.chartOptions2.chartPoints = {
                        lineWidth: 24,
                        horizontalLineWidth: 28,
                        fontSize: 14,
                    };
                }
            }
        },
    },
    mounted() {
        this.setStyles();
        setTimeout(() => this.fillData(), 1000);
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.doughnut-chart {
    &-compare {
        overflow: hidden;
        width: 50%;
        display: flex;
        justify-content: center;
        flex-shrink: 0;
    }
    &-part {
        position: relative;
        &:first-child {
            margin-right: 13px;
            transition: $transition;
            &::after {
                position: absolute;
                content: 'Период 1';
                display: inline-block;
                top: 0;
                left: 0;
                transform: translate(-50%);
                font-size: 1.4rem;
                color: $c-dark;
            }
        }
        &:last-child {
            margin-left: 13px;
            transition: $transition;
            &::before {
                position: absolute;
                content: 'Период 2';
                display: inline-block;
                top: 0;
                right: 0;
                transform: translate(50%);
                font-size: 1.4rem;
                color: $c-dark;
            }
        }
    }
}

.doughnut-chart {
    flex-shrink: 0;
    width: 50%;
    &-wrap {
        display: flex;
        padding: 29px 0 15px calc(7 / 1200 * 100%);
        border: 1px solid $c-grey4;
        box-sizing: border-box;
        border-radius: 4px;
    }
    &-labels {
        padding: 35px calc(43 / 1200 * 100%) 0;
        width: 50%;
        display: flex;
        .labels-col {
            &:first-child {
                margin-right: calc(48 / 514 * 100%);
            }
        }
    }
    &-label {
        word-break: break-all;
        display: flex;
        align-items: center;
        margin-bottom: 17px;
        &:last-child {
            margin-bottom: 0;
        }
        span {
            max-width: 172px;
            font-size: 1.4rem;
            color: $c-dark;
            line-height: 1.1;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &-label-circle {
        flex-shrink: 0;
        margin-right: 15px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 50%;
        width: 16px;
        height: 16px;
    }
}

@media (min-width: 2320px) {
    .doughnut-chart {
        &-wrap {
            padding: 35px 0 22px;
        }
        &-label-circle {
            margin-right: 20px;
            width: 22px;
            height: 22px;
        }
        &-label {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                max-width: 250px;
                font-size: 2rem;
            }
        }
        &-labels {
            padding-top: 50px;
        }
    }
}

@media (max-width: 1100px) {
    .doughnut-chart {
        width: 100%;
        &-wrap {
            padding: 25px 0;
            flex-wrap: wrap;
        }
        &-labels {
            flex-direction: column;
            width: 100%;
            .labels-col:first-child {
                margin-right: 0;
            }
            .labels-col {
                text-align: center;
            }
        }
        &-label-circle {
            margin-right: 10px;
            width: 13px;
            height: 13px;
        }
        &-label {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 15px;
            &:last-child {
                margin-bottom: 15px;
                margin-right: 0;
            }
            span {
                max-width: 150px;
                font-size: 1.2rem;
            }
        }
    }
}
@media (max-width: 768px) {
    .doughnut-chart {
        &-wrap {
            padding: 20px 0;
        }
        &-labels {
            padding: 15px calc(18 / 340 * 100%) 0;
            width: 100%;
            justify-content: center;
            flex-direction: row;
            .labels-col:first-child {
                margin-right: calc(17 / 304 * 100%);
            }
            .labels-col {
                text-align: left;
            }
        }
        &-label-circle {
            margin-right: 6px;
            width: 11px;
            height: 11px;
        }
        &-label {
            display: flex;
            margin-right: 0;
            margin-bottom: 11px;
            span {
                max-width: 125px;
                font-size: 1rem;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        &-part {
            max-width: calc(50% - 8px);
            //padding-top: 20px;
            &:first-child {
                margin-right: 8px;
                &::after {
                    font-size: 1rem;
                    top: 5px;
                    left: 4%;
                    transform: translate(0);
                }
            }
            &:last-child {
                margin-left: 8px;
                &::before {
                    font-size: 1rem;
                    top: 5px;
                    right: 4%;
                    transform: translate(0);
                }
            }
        }
    }
}
</style>
