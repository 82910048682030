<template>
    <form
            @submit.prevent="submitHandler"
            class="login-modal-inner"
    >
        <div class="login-caption">Восстановление доступа</div>
        <div class="login-sub">
            Мы отправим вам письмо со ссылкой для сброса пароля
        </div>
        <div class="login-input-group">
            <div :class="{
						'login-input-field': true,
						'login-input-error': errMsg,
						'focused': focused}">
                <input
                        @focus="focused = true"
                        @blur="focused = false"
                        placeholder="Email"
                        v-model="email"
                        type="text"
                        class="login-input"
                >
                <div
                        v-if="errMsg"
                        class="login-error-message"
                >Данный email отсутствует в системе
                </div>
                <span class="login-input-title">Email</span>
            </div>
        </div>
        <button
                type="submit"
                :class="{'login-btn': true, 'none-active': !email}"
        >Отправить
        </button>

        <div
                class="close"
                @click="$emit('toggleForgotPassword')"
        >
            <CloseIcon/>
        </div>
    </form>
</template>
<script>
import CloseIcon from '@/components/icons/Close.vue'

export default {
    name: 'Login',
    model: {
        prop: 'forgotPassword',
        event: 'toggleForgotPassword',
    },
    props: {
        forgotPassword: {
            type: Boolean,
        },
    },
    data: () => ({
        email: '',
        errMsg: '',
        focused: false,
    }),
    methods: {
        submitHandler() {
            // console.log(this.email)
        },
    },
    components: {
        CloseIcon,
    },
}
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 20px;
}

.login-modal-inner {
    padding: 40px 12px;
}

.login-input-group {
    margin-bottom: 28px;
}

.login-error-message {
    bottom: -12px;
    left: 3px;
}
</style>
