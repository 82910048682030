import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        press: {},
        press2: {},
        social: {},
        social2: {},
    },
    mutations: {
        setPress(state, val) {
            state.press = val;
        },
        setPress2(state, val) {
            state.press2 = val;
        },
        setSocial(state, val) {
            state.social = val;
        },
        setSocial2(state, val) {
            state.social2 = val;
        },
    },
    actions: {
        async getPressTotals({dispatch, commit}, {date, tonality}) {
            try {
                const res = await axios.get('/smi/totals', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        // tonality,
                    }
                });
                const resData = res.data;
                commit('setPress', resData);
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressTotals2({dispatch, commit}, {date, tonality}) {
            const res = await axios.get('/smi/totals', {
                params: {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    // tonality,
                }
            });
            const resData = res.data;
            commit('setPress2', resData);
            return resData;
        },
        async getSocialTotals({dispatch, commit}, {date, tonality, region}) {
            const res = await axios.get('/social/totals', {
                params: {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    region,
                }
            });
            const resData = res.data;
            commit('setSocial', resData);
            return resData;
        },
        async getSocialTotals2({dispatch, commit}, {date, tonality, region}) {
            const res = await axios.get('/social/totals', {
                params: {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    region,
                }
            });
            const resData = res.data;
            commit('setSocial2', resData);
            return resData;
        },
    },
    getters: {
        widgetTotals(state) {
            const totals = [];
            let index = 0;
            if (Object.keys(state.press).length) {
                totals.push({
                    title: 'СМИ',
                    totals: []
                });
                const fields = [
                    {
                        key: 'messages',
                        title: 'Количество сообщений',
                        icon: 'messages',
                    },
                    {
                        key: 'media_index',
                        title: 'Медиаиндекс',
                        icon: 'statistics',
                        help: 'Интегральный показатель компании «Медиалогия», учитывающий влиятельность СМИ, заметность публикации и тональность сообщения',
                    },
                    {
                        key: 'potential_coverage',
                        title: 'Потенциальный охват',
                        icon: 'hierarchy',
                        help: 'Сумма потенциальных просмотров всех публикаций с упоминанием бренда\n',
                    },
                    {
                        key: 'positive',
                        title: 'Позитив',
                        icon: 'ascending-bars-graphic',
                    },
                    {
                        key: 'negative',
                        title: 'Негатив',
                        icon: 'descending-bars-graphic',
                    },
                    {
                        key: 'neutral',
                        title: 'Нейтрал',
                        icon: 'constant-bars-graphic',
                    },
                ];
                const isComparing = Object.keys(state.press2).length > 0;
                for (const field of fields) {
                    const key = field.key;
                    if (key in state.press) {
                        totals[index].totals.push({
                            key: field.title,
                            help: field.help,
                            icon: field.icon,
                            value: state.press[key] || 0,
                            value2: isComparing ? ((state.press2 && key in state.press2 && state.press2[key]) ? state.press2[key] : 0) : undefined,
                        });
                    }
                }
                index++;
            }

            if (Object.keys(state.social).length) {
                totals.push({
                    title: 'Социальные сети',
                    totals: []
                });
                const socFields = [
                    {
                        key: 'mentions',
                        title: 'Всего упоминаний',
                        icon: 'index',
                    },
                    {
                        key: 'constructive_negative',
                        title: 'Конструктивный негатив',
                        help: 'Сообщения негативной тональности, содержащие конструктивную критику компании. К подобным сообщениям относятся публикации с указанием адреса торговой точки и четким описанием проблемы, публикации имеющие большой охват и вовлеченность\n',
                        icon: 'dislike',
                    },
                    {
                        key: 'risky_messages',
                        title: 'Рисковые сообщения',
                        icon: 'message-bars-graphic',
                    },
                    {
                        key: 'potential_negative_coverage',
                        title: 'Потенциальный охват негатива',
                        icon: 'line-graph',
                    },
                    {
                        key: 'loyality_index',
                        title: 'Индекс лояльности',
                        help: 'Отношение количества позитивных сообщений к негативным\n',
                        icon: 'nps',
                    },
                ];
                const isComparing = Object.keys(state.social2).length > 0;
                for (const field of socFields) {
                    const key = field.key;
                    if (key in state.social) {
                        totals[index].totals.push({
                            key: field.title,
                            icon: field.icon,
                            help: field.help,
                            value: state.social[key] || 0,
                            value2: isComparing ? ((state.social2 && key in state.social2 && state.social2[key]) ? state.social2[key] : 0) : undefined,
                        });
                    }
                }
                index++;
            }
            // console.log(totals);
            return totals;
        }
    },
};
