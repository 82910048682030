/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    // if (isObject(target) && isObject(source)) {
    for (const key in source) {
        if (isObject(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: {} });
            mergeDeep(target[key], source[key]);
        } else if (Array.isArray(source[key])) {
            if (!target[key]) Object.assign(target, { [key]: [] });
            mergeDeep(target[key], source[key]);
        } else if (Array.isArray(target)) {
            target[key] = source[key];
        } else {
            Object.assign(target, { [key]: source[key] });
        }
    }
    // }

    return mergeDeep(target, ...sources);
}

export function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(context, args), wait)
    }
}

window.fixWordWrap = (collection, to) => {
    return collection.map((c, i) => {
        if (c.length >= to && c.includes(' ')) {
            const subColl = [];
            const longest = Math.max(c.split(' ').sort( (a, b) => b.length - a.length)[0].length, to);

            let isAdded = false;

            c.split(' ').forEach(sc => {
                if (isAdded) {
                    const lastSubColl = subColl[subColl.length - 1];
                    if ((lastSubColl + ` ${sc}`).length < longest) {
                        subColl[subColl.length - 1] += ` ${sc}`;
                    } else {
                        isAdded = false;
                    }
                }

                if (sc >= longest) {
                    subColl.push(sc)
                } else if (!isAdded) {
                    isAdded = true;
                    subColl.push(sc)
                }
            });
            return subColl.slice(0, 4);
        } else {
            return c;
        };
    })
};


import XLSX from "xlsx";

window.createTableFile = function(aoa, type, fn, dl) {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(wb, ws);
    return dl ?
        XLSX.write(wb, {bookType:type, bookSST:true, type: 'array'}) :
        XLSX.writeFile(wb, fn || ('SheetJSTableExport.' + (type || 'xlsx')));
};

window.download = function(content, contentType, fileName) {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([content], {type: contentType}));
    link.download = fileName;
    link.click();
};

window.downloadReport = function(columns, headers, rows, type, fn) {
    const aoa = [];
    const arr = [];
    for (const col of columns) {
        if (headers[col].type === 'dateTimeStatus') {
            arr.push('Дата');
            arr.push('Время');
            arr.push('Статус');
        } else if (headers[col].type === 'nameBranch') {
            arr.push('Округ');
            arr.push('Филиал');
        } else {
            arr.push(headers[col].title);
        }
    }
    aoa.push(arr);
    for (const row of rows) {
        const arr = [];
        for (const col of columns) {
            if (headers[col].type === 'dateTimeStatus') {
                arr.push(row[col].dates);
                arr.push(row[col].time);
                arr.push(row[col].status);
            } else if (headers[col].type === 'nameBranch') {
                arr.push(row[col].name);
                arr.push(row[col].branch);
            } else {
                arr.push(row[col]);
            }
        }
        aoa.push(arr);
    }
    const data = createTableFile(aoa, type, fn, true);
    download(data, 'application/binary', fn + '.' + type);
};


export const toBlob = (canvas) =>
    new Promise((res, rej) => {
        canvas.toBlob(blob => res(blob));
    });
