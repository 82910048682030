<template>
    <div class="datepicker-footer-input-group">
        <input
            class="datepicker-footer-input"
            v-model="startInput"
            v-mask="'##.##.####'"
        />
        <span>{{ separator }}</span>
        <input
            class="datepicker-footer-input"
            v-model="endInput"
            v-mask="'##.##.####'"
            @focus="secondInputFocus"
        />
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';
import moment from 'moment';

export default {
    name: 'DatePickerInputGroup',
    model: {
        prop: 'inputDateRange',
        event: 'input',
    },
    directives: {
        mask,
    },
    props: {
        inputDateRange: {
            type: Object,
            required: true,
        },
        separator: {
            type: String,
            required: false,
            default: '-',
        },
        // from: {
        // 	type: Boolean,
        // 	required: false,
        // 	default: false,
        // },
    },
    data() {
        return {
            startInput: '',
            endInput: '',
        };
    },
    watch: {
        inputDateRange: {
            handler(val) {
                this.startInput = val.startDate ? moment(val.startDate, 'Y-MM-DD').format('DD.MM.YYYY') : val.startDate;
                this.endInput = val.endDate ? moment(val.endDate, 'Y-MM-DD').format('DD.MM.YYYY') : val.endDate;
            },
            deep: true,
        },
        startInput() {
            if (this.startInput && this.startInput.length === 10) {
                this.inputDateRange.startDate = moment(this.startInput, 'DD.MM.YYYY').format('Y-MM-DD');
            }
        },
        endInput() {
            if (this.endInput && this.endInput.length === 10) {
                this.inputDateRange.endDate = moment(this.endInput, 'DD.MM.YYYY').format('Y-MM-DD');
            }
        },
        // startInput() {
        // 	// this.$emit('input-date', {
        // 	// 	startDate: this.startInput,
        // 	// 	endDate: this.endInput,
        // 	// })
        // 	if (this.inputDateRange.startDate !== this.startInput) {
        // 		this.inputDateRange.startDate = this.startInput
        // 	}
        // 	console.log('DatePickerInputGroup', this.startInput, 'startInput')
        // },
        // endInput() {
        // 	// this.$emit('input-date', {
        // 	// 	startDate: this.startInput,
        // 	// 	endDate: this.endInput,
        // 	// })
        // 	if (this.inputDateRange.startDate !== this.startInput) {
        // 		this.inputDateRange.startDate = this.startInput
        // 	}
        // 	console.log('DatePickerInputGroup', this.endInput, 'endInput')
        // },
    },
    created() {
        this.startInput = moment(this.inputDateRange.startDate, 'Y-MM-DD').format('DD.MM.YYYY');
        this.endInput = moment(this.inputDateRange.endDate, 'Y-MM-DD').format('DD.MM.YYYY');
    },
    methods: {
        secondInputFocus() {
            if (!this.inputDateRange.endDate) {
                this.inputDateRange.endDate = this.inputDateRange.startDate;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.datepicker-footer-input {
    box-sizing: border-box;
    max-width: 86px;
    outline: none;
    border-radius: 4px;
    border: 1px solid $c-grey4;
    color: $c-grey;
    font-size: 1.3rem;
    line-height: (16/13);
    padding: 5px 10px;
    pointer-events: none;
    user-select: none;

    &:focus {
        border-color: $c-dark2;
        color: $c-dark2;
    }

    &-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
            margin: 0 5px;
        }
    }
}

@media (min-width: 2320px) {
    .datepicker-footer-input-group span {
        margin: 0 10px;
        font-size: 2rem;
    }
    .date-result-col-inputs {
        .datepicker-footer-input {
            max-width: 114px;
            font-size: 1.8rem;
            padding: 8px 10px;
        }
    }
}

@media (max-width: 1200px) {
    .date-result-col-inputs {
        .datepicker-footer-input {
            max-width: 70px;
            font-size: 1.1rem;
            padding: 5px;
        }
    }
}
@media (max-width: 1000px) {
    .date-result-col-inputs {
        .datepicker-footer-input {
            max-width: 65px;
            font-size: 1rem;
            padding: 7px;
        }
    }
}
@media (max-width: 660px) {
    .date-result-col-input {
        width: 100%;
        .datepicker-footer-input {
            width: 100%;
            max-width: 100%;
            font-size: 1.2rem;
        }
    }
}
</style>
