export default {
    methods: {
        $url(url) {
            url += url.indexOf('?') !== -1 ? '&' : '?';
            url += `date=${this.date.startDate}-${this.date.endDate}`;
            if (this.compareDate.startDate) {
                url += `&compareDate=${this.compareDate.startDate}-${this.compareDate.endDate}`;
            }
            if (this.tonality) {
                url += `&tonality=${this.tonality}`;
            }
            if (this.region) {
                url += `&region=${this.region}`;
            }
            if (this.detailization && this.detailization.value) {
                url += `&detailization=${this.detailization.value}`;
            }
            return url;
        }
    }
};
