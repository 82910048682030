<template>
    <div
        class="bubble-wrap"
        :style="{opacity: loading ? 0 : 1}"
    >
        <Loading v-if="!matrixData.length" :loaded="socialReputationMatrixLoaded"/>
        <div v-else class="bubble-labels">
            <template v-for="(matrixItem, i) in this.matrixesMerged">
                <div
                    :style="{top: labelPositions.hasOwnProperty(i) ? labelPositions[i][null] + 'px' : 0}"
                    @click="toggleMatrixItem(i)"
                    :class="{'bubble-label': true, 'open': activeMatrixItems.length && activeMatrixItems.includes(i), 'clickable': matrixItem.children && matrixItem.children.length}"
                    :key="i + matrixItem.name"
                >
                    <span>{{ matrixItem.name }}</span>
                    <ArrowDown
                        class="bubble-label-icon"
                        :width="8"
                        :height="5"
                        :fill="iconFill(i)"
                    />
                </div>
                <template v-if="activeMatrixItems.includes(i) && labelPositions.hasOwnProperty(i)">
                    <div
                        v-for="(childMatrixItem, j) in matrixItem.children"
                        :style="{top: labelPositions[i][j] + 'px'}"
                        :key="childMatrixItem.name"
                        class="bubble-label bubble-label-child"
                    >
                        {{ childMatrixItem.name }}
                    </div>
                </template>

            </template>
        </div>
        <div v-if="matrixData.length" class="bubble-chart">
            <BubbleChart
                @click="onBubbleClick"
                :styles="styles"
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
        </div>
    </div>
</template>

<script>
import { darkColor2 } from '@/styles/vars.scss';
import BubbleChart from './Charts/BubbleChart';
import Loading from './Loading';
import ArrowDown from '@/components/icons/ArrowDown';
import {mapGetters, mapState} from 'vuex';

const _mobileBreakPoint = { windowWidth: 842, minWidth: 842 };

export default {
    name: 'BubbleChartComponent',
    components: {
        BubbleChart,
        ArrowDown,
        Loading,
    },
    props: {
        hoverPointer: {
            type: Boolean,
            default: false,
        }
    },
    watch: {
        windowWidth() {
            this.setStyles();
        },
        isMobileChart(val, oldVal) {
            this.fillData();
        },
        is4K(val, oldVal) {
            this.fillData();
        },
        matrixData: {
            handler(val, oldVal) {
                this.setStyles();
                this.fillData();
                this.setStyles();
            },
            deep: true,
        },
        matrixData2: {
            handler(val, oldVal) {
                this.setStyles();
                this.fillData();
                this.setStyles();
            },
            deep: true,
        },
        compareDate: {
            handler(val, oldVal) {
                this.setStyles();
                this.fillData();
                this.setStyles();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(['windowWidth', 'is4K', 'compareDate', 'socialReputationMatrixLoaded' ]),
        ...mapGetters({
            matrixData: 'socialReputationMatrix',
            matrixData2Global: 'socialReputationMatrix2',
            xLabels: 'socialReputationMatrixIds',
        }),
        ...mapGetters({
            problems: 'socialReputationMatrixProblems',
        }),
        matrixesMerged() {
            return this.mergeMatrixes(this.matrixData, this.matrixData2);
        },
        matrixData2() {
            return this.compareDate.startDate ? this.matrixData2Global : [];
        },
        iconFill() {
            return (idx) => (this.activeMatrixItems.length && this.activeMatrixItems.includes(idx) ? '#fff' : darkColor2);
        },
        isMobileChart() {
            return this.windowWidth <= _mobileBreakPoint.windowWidth;
        },
        innerPadding() {
            return this.is4K ? 104 : 64;
        },
    },
    data() {
        return {
            loading: true,
            fontSize: 12,
            yGridLineDefaultColor: '#F2F2F2',
            yGridLineActiveColor: '#28b93c',
            activeMatrixItems: [],
            labelPositions: {},
            colors: [
                [
                    {
                        bg: '#28b93c',
                        fill: '#fff',
                    },
                    {
                        bg: '#28b93c',
                        fill: '#fff',
                    },
                    {
                        bg: '#28b93c',
                        fill: '#fff',
                    },
                ],
                [
                    {
                        bg: '#6A6A6A',
                        fill: '#fff',
                    },
                    {
                        bg: '#656565',
                        fill: '#fff',
                    },
                    {
                        bg: '#555555',
                        fill: '#fff',
                    },
                ],
            ],
            childColors: [
                [
                    {
                        bg: '#B7B7B7',
                        fill: '#000',
                    },
                    {
                        bg: '#878787',
                        fill: '#fff',
                    },
                    {
                        bg: '#1C1C1C',
                        fill: '#fff',
                    },
                ],
                [
                    {
                        bg: '#E0E0E0',
                        fill: '#000',
                    },
                    {
                        bg: '#C4C4C4',
                        fill: '#000',
                    },
                    {
                        bg: '#555555',
                        fill: '#fff',
                    },
                ],
            ],
            chartData: {},
            chartData2: {},
            chartOptions: {
                hoverPointer: this.hoverPointer,
                xLabels: {},
                tooltips: {
                    enabled: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                zeroLineWidth: 0,
                            },
                            ticks: {
                                fontSize: 10,
                                min: 0,
                                max: 10,
                                stepSize: 1,
                                padding: 20,
                                maxRotation: 0,
                                autoSkip: false,
                                callback: function (value, index, values) {
                                    return this.chart.options.xLabels[index];
                                },
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                padding: 0,
                                fontSize: 14,
                                min: 0,
                                max: 5,
                                stepSize: 1,
                            },
                        },
                    ],
                },
            },
            styles: {
                height: 0,
                width: 'unset',
                minWidth: 800 + 'px',
            },
            styles2: {
                height: 0,
            },
        };
    },
    mounted() {
        this.setStyles();
        setTimeout(() => {
            this.fillData();
        }, 1000);
    },
    methods: {
        mergeMatrixes(matrix, matrix2) {
            const mergedMatrixData = Array.isArray(matrix) ? JSON.parse(JSON.stringify(matrix)) : [];
            if (Array.isArray(matrix2)) {
                for (const matrixItem of matrix2) {
                    const same = mergedMatrixData.find((mi) => mi.name === matrixItem.name);
                    let newGroup = null;
                    if (!same) {
                        newGroup = {
                            name: matrixItem.name,
                            values: [],
                            children: [],
                        };
                    }
                    let group = same || newGroup;
                    if (Array.isArray(matrixItem.children)) {
                        if (!group.children) {
                            group.children = [];
                        }
                        const newChildren = [];
                        for (const childMatrixItem of matrixItem.children) {
                            const sameChild = group.children.find((mi) => {
                                return mi.name === childMatrixItem.name
                            });
                            let newChild = null;
                            if (!sameChild) {
                                newChild = {
                                    name: childMatrixItem.name,
                                    values: []
                                };
                            }
                        }
                        group.children = [...group.children, ...newChildren];
                    }
                    if (!same) {
                        mergedMatrixData.push(newGroup);
                    }
                }
            }
            return this.problems ? mergedMatrixData.map(item => {
                if (item.children && item.children.length) {
                    const problem1 = this.problems.find(el => el.name === item.name);
                    if (problem1 && problem1.children) {
                        item.children = item.children.sort((a,b) => {
                            return problem1.children.findIndex(el => el.name === a.name) - problem1.children.findIndex(el => el.name === b.name)
                        });
                    }
                }
                return item;
            }).sort((a,b) => {
                if (a.name === 'Все') {
                    return 1;
                } else if (b.name === 'Все') {
                    return -1;
                }
                return this.problems.findIndex(el => el.name === a.name) - this.problems.findIndex(el => el.name === b.name)
            }) : mergedMatrixData;
        },
        fillData() {
            // this.loading = true;
            this.chartOptions.xLabels = {};
            let maxRegionKey = 0;
            Object.keys(this.xLabels).forEach((regionKey) => {
                this.chartOptions.xLabels[regionKey] = this.xLabels[regionKey];
                maxRegionKey = Math.max(maxRegionKey, Number(regionKey));
            });
            this.chartOptions.scales.xAxes[0].ticks.max = maxRegionKey + 1;
            let { labelPositions, yGridLineColor, yLineWidth, backgroundColor, data, y } = this.getChartData(
                this.matrixesMerged,
                this.colors[0],
                this.childColors[0]
            );
            let backgroundColor2, data2;
            if (this.compareDate.startDate) {
                const a = this.getChartData(this.mergeMatrixes(this.matrixData2, this.matrixData), this.colors[1], this.childColors[1]);
                backgroundColor2 = a.backgroundColor;
                data2 = a.data;
            }

            const res = this.makeDatasetsSameRadius(data, data2);
            data = res.data;
            if (this.compareDate.startDate) {
                data2 = res.data2;
            }

            const yGridLineDefaultColor = '#F2F2F2';
            const yGridLineColorList = [];
            const { pointStyle, pointStyle2, rotation, rotation2 } = this.getPointOptions(data, data2);
            this.labelPositions = labelPositions;

            // this.chartOptions.elements = {
            // 	point: {
            // 		pointStyle: 'triangle'
            // 	}
            // }
            this.styles.height = (y + 0.5) * this.innerPadding + 'px';
            // this.chartOptions.scales.yAxes[0].ticks.callback = () => '';
            this.chartOptions.scales.yAxes[0].ticks.display = false;
            this.chartOptions.scales.yAxes[0].gridLines = {
                display: true,
                drawBorder: false,
                lineWidth: yLineWidth,
                color: yGridLineColor,
            };
            this.chartOptions.scales.yAxes[0].ticks.min = 0.5;
            this.chartOptions.scales.yAxes[0].ticks.max = y + 0.5;
            this.chartData = {
                datasets: [
                    {
                        defaultFontColor: '#555555',
                        backgroundColor,
                        borderColor: backgroundColor,
                        hoverBackgroundColor: backgroundColor,
                        hoverRadius: 0,
                        data,
                        pointStyle,
                        rotation,
                    },
                    {
                        defaultFontColor: '#555555',
                        backgroundColor: backgroundColor2,
                        borderColor: backgroundColor2,
                        hoverBackgroundColor: backgroundColor2,
                        hoverRadius: 0,
                        data: data2,
                        pointStyle: pointStyle2,
                        rotation: rotation2,
                    },
                ],
            };

            this.loading = false;
        },
        setStyles() {
            if (this.isMobileChart) {
                // this.styles.width = _mobileBreakPoint.minWidth + 'px';
                this.fontSize = 10;
                this.chartOptions.scales.xAxes[0].ticks.fontSize = 9; //  7 by design
            } else {
                if (this.is4K) {
                    // this.styles.width = 'unset';
                    this.fontSize = 19;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 14;
                } else {
                    // this.styles.width = 'unset';
                    this.fontSize = 12;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 10;
                }
            }
        },
        getChartData(matrixData, colors, childColors) {
            if (!matrixData || !matrixData.length) return {};
            let data = [];
            let maxValue = 0;
            let minValue = Infinity;
            let position = null;
            const yLineWidth = [0];
            const yGridLineColorList = [this.yGridLineDefaultColor];
            const backgroundColor = [];
            const labelPositions = {};

            for (let i = 0; i < matrixData.length; i++) {
                const category = matrixData[i];
                if (!category.values) continue;
                for (const value of category.values) {
                    if (maxValue < value.value) {
                        maxValue = value.value;
                    }
                    if (minValue > value.value) {
                        minValue = value.value;
                    }
                }
            }
            let y = 0;
            for (let i = matrixData.length - 1; i >= 0; i--) {
                const category = matrixData[i];
                if (this.activeMatrixItems.includes(i) && category.children && category.children.length) {
                    let maxValue = 0;
                    let minValue = Infinity;
                    for (let j = category.children.length - 1; j >= 0; j--) {
                        if (!category.children[j].values) continue;
                        for (const value of category.children[j].values) {
                            if (maxValue < value.value) {
                                maxValue = value.value;
                            }
                            if (minValue > value.value) {
                                minValue = value.value;
                            }
                        }
                    }
                    const colorsLen = childColors.length;
                    for (let j = category.children.length - 1; j >= 0; j--) {
                        y++;
                        // console.log(category.children[j].name, y);
                        if (category.children[j].values) {
                            for (const value of category.children[j].values) {
                            if (!(value.region_id in this.chartOptions.xLabels)) continue;
                            const val = value.value;
                            let v = Math.max((val / maxValue) * 21, 15);
                            const fz = this.fontSize * 0.8;

                            if (this.is4K) {
                                v = Math.max((val / maxValue) * 40, 28);
                            }
                            let bgColor;
                            let color;
                            for (let c = 1; c <= colorsLen; c++) {
                                if (val - minValue <= ((maxValue - minValue) * c) / colorsLen) {
                                    bgColor = childColors[c - 1].bg;
                                    color = childColors[c - 1].fill;
                                    break;
                                }
                            }
                            data.push({
                                x: value.region_id,
                                y,
                                r: v,
                                fontColor: color,
                                fontSize: fz,
                                value: this.$options.filters.format_number(val),
                            });
                            backgroundColor.push(bgColor);
                        }
                        }
                        yLineWidth.push(1);
                        yGridLineColorList.push(this.yGridLineDefaultColor);
                    }
                }
                y++;
                // console.log(category.name, y);
                const colorsLen = colors.length;
                if (category.values) {
                    for (const value of category.values) {
                        if (!(value.region_id in this.chartOptions.xLabels)) continue;
                        const val = value.value;
                        let v = Math.max((val / maxValue) * 29.5, 18.5);
                        if (this.is4K) {
                            v = Math.max((val / maxValue) * 47.5, 30);
                        }
                        let bgColor;
                        let color;
                        for (let c = 1; c <= colorsLen; c++) {
                            if (val - minValue <= ((maxValue - minValue) * c) / colorsLen) {
                                bgColor = colors[c - 1].bg;
                                color = colors[c - 1].fill;
                                break;
                            }
                        }
                        data.push({
                            x: value.region_id,
                            y,
                            r: v,
                            fontColor: color,
                            fontSize: this.fontSize,
                            value: this.$options.filters.format_number(val),
                        });
                        backgroundColor.push(bgColor);
                    }
                }
                yLineWidth.push(1);
                yGridLineColorList.push(this.activeMatrixItems.includes(i) ? this.yGridLineActiveColor : this.yGridLineDefaultColor);
            }
            yGridLineColorList.push(this.yGridLineDefaultColor);
            yLineWidth.push(0);

            const fz = this.isMobileChart ? 8 : this.is4K ? 4 : 12;
            let yRealInnerPadding = ((y + 1.5) * this.innerPadding - fz * 1.4) / (y + 1.5);
            for (let i = 0; i < matrixData.length; i++) {
                position = position ? position + yRealInnerPadding : 0.5 * yRealInnerPadding;
                if (!labelPositions.hasOwnProperty(i)) {
                    labelPositions[i] = {};
                }
                labelPositions[i][null] = position;
                if (this.activeMatrixItems.includes(i) && matrixData[i].children) {
                    for (let j = 0; j < matrixData[i].children.length; j++) {
                        position += yRealInnerPadding;
                        labelPositions[i][j] = position;
                    }
                }
            }

            return {
                labelPositions,
                data,
                backgroundColor,
                y,
                yLineWidth,
                yGridLineColor: this.activeMatrixItems.length ? yGridLineColorList.reverse() : this.yGridLineDefaultColor,
            };
        },
        getPointOptions(data, data2) {
            if (!data2 || !data2.length) {
                return {
                    pointStyle: 'circle',
                    pointStyle2: 'circle',
                    rotation: 0,
                    rotation2: 0,
                };
            }
            const pointStyle = [];
            const pointStyle2 = [];
            const rotation = [];
            const rotation2 = [];
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                const i2 = data2.findIndex((el2) => el.x === el2.x && el.y === el2.y);
                pointStyle.push(i2 === -1 ? 'circle' : 'semicircle');
                rotation.push(Math.PI);
            }
            for (let i2 = 0; i2 < data2.length; i2++) {
                const el2 = data2[i2];
                const i = data.findIndex((el) => el.x === el2.x && el.y === el2.y);
                pointStyle2.push(i === -1 ? 'circle' : 'semicircle');
                rotation2.push(0);
            }
            return {
                pointStyle,
                pointStyle2,
                rotation,
                rotation2,
            };
        },
        makeDatasetsSameRadius(data, data2) {
            if (!data2 || !data2.length) {
                return {
                    data,
                    data2,
                };
            }
            const d = [];
            const d2 = [];
            for (let i = 0; i < data.length; i++) {
                const el = data[i];
                const i2 = data2.findIndex((el2) => el.x === el2.x && el.y === el2.y);
                d.push({
                    ...data[i],
                    r: Math.max(data[i].r, i2 === -1 ? -Infinity : data2[i2].r),
                });
            }
            for (let i2 = 0; i2 < data2.length; i2++) {
                const el2 = data2[i2];
                const i = data.findIndex((el) => el.x === el2.x && el.y === el2.y);
                d2.push({
                    ...data2[i2],
                    r: Math.max(data2[i2].r, i === -1 ? -Infinity : data[i].r),
                });
            }
            return {
                data: d,
                data2: d2,
            };
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
        toggleMatrixItem(i) {
            const haveChild = this.matrixesMerged[i].children && this.matrixesMerged[i].children.length;
            const oldActiveIsEmpty = !this.activeMatrixItems.length;
            if (!haveChild) {
                return;
            }
            if (this.activeMatrixItems.includes(i)) {
                this.activeMatrixItems = [];
            } else {
                this.activeMatrixItems = [i];
            }
            if (oldActiveIsEmpty && !haveChild) {
                return;
            }
            this.fillData();
        },
        onBubbleClick(bubble) {
            const matrixData = [];
            for (let i = this.matrixesMerged.length - 1; i >= 0; i--) {
                const category = this.matrixesMerged[i];
                if (this.activeMatrixItems.includes(i) && category.children && category.children.length) {
                    for (let j = category.children.length - 1; j >= 0; j--) {
                        matrixData.push({
                            level: 2,
                            y: category.children[j].name
                        });
                    }
                }
                if (category.values) {
                    matrixData.push({
                        level: 1,
                        y: category.name
                    });
                }
            }

            this.$emit('click', {
                ...matrixData[bubble.y - 1],
                x: this.chartOptions.xLabels[bubble.x],
                xArray: Object.values(this.chartOptions.xLabels).slice(1),
            });
        }
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

$min-width: 706px;


.bubble-wrap {
    transition: $transition;
}

@media (max-width: 1690px) {
    .bubble-wrap {
        overflow-x: auto;
    }
}

.bubble-wrap {
    //min-width: $min-width;
    box-sizing: border-box;
    border: 1px solid $c-grey4;
    border-radius: 4px;
    padding: 40px 24px;
    display: flex;
}

.bubble-labels {
    position: relative;
    width: calc(162 / 1152 * 100%);
    min-width: 145px;
    flex-shrink: 0;
    margin-right: 14px;
}

.bubble-label {
    color: $c-dark2;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1;
    box-sizing: border-box;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(-50%);
    box-shadow: none;
    border: 1px solid $c-grey4;
    border-radius: 4px;
    background-color: transparent;
    text-align: right;
    width: 100%;
    max-width: 100%;
    padding: 7px 10px 7px 10px;
    &.clickable {
        cursor: pointer;
        .bubble-label-icon {
            display: inline;
        }
    }

    span {
        display: inline-block;
        width: 87%;
        vertical-align: middle;
    }

    &-icon {
        display: none;
        transition: all ease-out 0.12s;
        vertical-align: middle;
        margin-left: 8px;
    }

    &.open &-icon {
        transform: rotate(180deg);
        transform-origin: center;
    }

    &.open {
        font-weight: 700;
        color: $c-white;
        background-color: $c-primary;
    }

    &-child {
        word-break: break-word;
        padding-right: 0;
        padding-left: 0;
        width: 90%;
        color: #828282;
        line-height: (15/12);
        font-weight: 400;
        border: none;
    }
}

.bubble-chart {
    width: 100%;
}

@media (min-width: 2320px) {
    .bubble-wrap {
        padding: 40px 38px;
    }
    .bubble-labels {
        margin-right: 22px;
    }
    .bubble-label {
        padding: 11px 15px;
        line-height: (20/19);
        font-size: 1.9rem;
    }
    .bubble-label-icon {
        width: 13px;
        height: 13px;
        margin-left: 10px;
    }
}

@media (max-width: 1200px) {
    .bubble-labels {
        min-width: 130px;
    }
    .bubble-label {
        padding: 7px;
        font-size: 1.1rem;

        span {
            word-break: break-word;
            width: 85%;
        }

        &.open {
            font-weight: 500;
        }
    }
}

@media (max-width: 1000px) {
    .bubble-labels {
        min-width: 120px;
    }
    .bubble-label {
        padding: 7px 6px;
        font-size: 1rem;

        span {
            width: 84%;
        }
    }
}

@media (max-width: 848px) {
    .bubble-wrap {
        padding: 20px 18px;
    }
    .bubble-labels {
        min-width: 116px;
    }
    .bubble-label {
        padding: 7px 5px;
        font-size: 0.8rem;
    }
}
</style>
