<template>
    <div class="map-wrap">
        <Loading v-show="!socialProblemMap.length" :loaded="socailProblemMapLoaded"/>
        <div ref="mapEl" v-show="socialProblemMap.length" class="map">
            <template v-for="(pos, region) in regionPositions">
                <div class="bar-wrap" :style="getBarStyle(region)">
                    <Bar :key="region" :value="regionValues[region]" :quantity="regionQuantities[region]" @mouseover="regionHovered(region, $event)"
                         @mouseleave="regionHoverEnd" :value2="compareDate.startDate && regionValues2[region]"/>
                    <div class="region"
                         :style="{color: ['Кавказский', 'Сибирский','Дальневосточный'].includes(region) ? '#FFF' : '#1C1C1C'}">{{
                            region
                        }}
                    </div>
                </div>
            </template>
            <div :style="svgWrapStyle">
            <svg :style="svgStyle" viewBox="-50 0 1550 850" xmlns="http://www.w3.org/2000/svg" version="1.2">
                <g>
                    <template v-for="item in regions">
                        <template v-for="(path, key2) in item.paths">
                            <RegionPath
                                v-if="(regionNames.includes(item.region) || true) && item.region in regionStyle"
                                :key="item.region + key2"
                                :index="key2"
                                :pathStyle="regionStyle[item.region]"
                                :pathClass="regionClass[item.region]"
                                :path="path"
                                :pathInlineStyle="pathStyles[regionClass[item.region]]"
                                :ref="setPathRef"
                                @pathEl="pathPosition(item.region, key2, $event)"
                            />
                        </template>
                    </template>
                </g>
            </svg>
            </div>
            <div class="bar-chart-wrap" v-if="barChartData.data && barChartData.data.length" :style="barChartStyles" @mouseleave="regionHoverEnd">
                <BarChart :data="barChartData"/>
            </div>
        </div>
    </div>
</template>

<script>
import RegionPath from './RegionPath'
import Bar from './Bar'
import Loading from '../Loading'
import BarChart from './BarChart'
import data_json from '../../data/map.json'
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            regions: data_json,
            regionStyle: {
                 "Центральный": {
                    fill: '#E0E0E0',
                    stroke: '#E0E0E0',
                },

                "Северо-Западный": {
                    fill: '#C4C4C4',
                    stroke: '#C4C4C4',
                },

                "Южный": {
                   fill: '#B5B5B5',
                    stroke: '#B5B5B5',
                },

                "Северо-Кавказский": {
                     fill: '#656565',
                    stroke: '#656565',
                },
                "Приволжский": {
                     fill: '#cfcfcf',
                    stroke: '#cfcfcf',
                },

                 "Уральский": {
                    fill: '#B7B7B7',
                    stroke: '#B7B7B7',
                },

                "Сибирский": {
                    fill: '#c3c3c3',
                    stroke: '#878787',
                },
               
                "Дальневосточный": {
                    fill: '#B7B7B7',
                    stroke: '#B7B7B7',
                },
                
            },
            regionClass: {
                "Центральный": "path1",
                "Северо-Западный": "path2",
                "Южный": "path3",
                "Северо-Кавказский": "path4",
                "Приволжский": "path5",
                "Уральский": "path6",
                "Сибирский": "path7",
                "Дальневосточный": "path8",
            },
            pathStyles: {
                path1: { transform: 'translate(201px, 145px)', transformOrigin: 'center center'},
                path2: { transform: 'translate(201px, 144px)', transformOrigin: 'center center'},
                path3: { transform: 'translate(203px, 145px)', transformOrigin: 'center center'},
                path4: { transform: 'translate(203px, 147px)', transformOrigin: 'center center'},
                path5: { transform: 'translate(201px, 143px)', transformOrigin: 'center center'},
                path6: { transform: 'translate(202px, 143px)', transformOrigin: 'center center'},
                path7: { transform: 'translate(203px, 143px)', transformOrigin: 'center center'},
                path8: { transform: 'translate(202px, 145px)', transformOrigin: 'center center',}
            },
            positions: {},
            regionPositions: {},
            barChartRegion: null,
            barChartData: {},
            barChartStyles: {},
            _paths: {},
        }
    },
    components: {
        RegionPath,
        Bar,
        BarChart,
        Loading,
    },
    computed: {
        ...mapGetters([ 'windowWidth', 'is4K', 'isMobile', 'compareDate', 'socialProblemMap', 'socailProblemMapLoaded']),
        ...mapGetters({
            'regionNames': 'regions',
            socialProblemMap2Global: "socialProblemMap2",
        }),
        socialProblemMap2() {
            return this.compareDate.startDate ? this.socialProblemMap2Global : [];
        },
        svgStyle() {
            // let minX = Infinity;
            // let maxX = -Infinity;
            // let minY = Infinity;
            // let maxY = -Infinity;
            // const regionPositions = this.regionPositions;
            // for (const region in regionPositions) {
            //     minX = Math.min(minX, regionPositions[region].minX);
            //     maxX = Math.max(maxX, regionPositions[region].maxX);
            //     minY = Math.min(minY, regionPositions[region].minY);
            //     maxY = Math.max(maxY, regionPositions[region].maxY);
            // }
            // console.log('rect', minX, maxX, minY, maxY);
            return {
                overflow: 'visible',
                zIndex: 0,
                position: 'absolute',
            };
        },
        svgWrapStyle() {
            let scale;
            if (this.windowWidth < 892) {
                scale = 1;
            } else {
                scale = Math.max(0.85, 0.9 * this.windowWidth / 1366);
                scale = Math.min(scale, 0.9);
            }
            // let translateX = 40;
            let translateY = - 50 * (1 - scale) / scale;
            // if (this.isMobile) {
            //     translateX = -190;
            // }
            return {
                maxWidth: '1800px',
                height: '100%',
                transform: `scale(${scale}) translateY(${translateY}%)`,
            }
        },
        // regionsSum() {
        //     return this.socialProblemMap.reduce((a, {value: b}) => b ? a + b : a, 0) / 100;
        // },
        regionValues() {
            const rv = {};
            this.regionNames.forEach(item => {
                rv[item] = 0;
            });
            this.socialProblemMap.forEach(item => {
                rv[item.name] = (item.value || 0);
            });
            return rv;
        },
        regionQuantities() {
            const rv = {};
            this.regionNames.forEach(item => {
                rv[item] = 0;
            });
            this.socialProblemMap.forEach(item => {
                rv[item.name] = (item.quantity || 0);
            });
            return rv;
        },
        regionValues2() {
            const rv = {};
            this.regionNames.forEach(item => {
                rv[item] = 0;
            });
            this.socialProblemMap2.forEach(item => {
                rv[item.name] = (Math.round((item.value || 0))) || 0;
            });
            return rv;
        }
    },
    methods: {
        setPathRef(region, val) {
            // console.log(region, val);
        },
        pathPosition(region, index, el) {
            if (!(region in this.$data._paths)) {
                this.$data._paths[region] = [];
            }
            this.$data._paths[region][index] = el;
        },
        updatePathPositions() {
            const positions = {};
            for (const region in this.$data._paths) {
                if (!(region in positions)) {
                    positions[region] = [];
                }
                for (let i = 0; i < this.$data._paths[region].length; i++) {
                    const position = this.getCoords(this.$data._paths[region][i]);
                    positions[region][i] = {
                        left: position.left,
                        top: position.top,
                        right: position.left + this.$data._paths[region][i].getBoundingClientRect().width,
                        bottom: position.top + this.$data._paths[region][i].getBoundingClientRect().height,
                    };
                }
            }
            this.positions = positions;
        },
        getRegionBoxStyle(region) {
            const mapElRect = this.$refs.mapEl ? this.getCoords(this.$refs.mapEl) : { left: 0, top: 0 };
            const pos = this.regionPositions[region];
            const offsetX = mapElRect.left;
            const offsetY = mapElRect.top;
            const left = pos.minX - offsetX;
            const top = pos.minY - offsetY;
            return {
                position: 'absolute',
                border: '3px solid #000',
                left: left + 'px',
                top: top + 'px',
                width: pos.maxX - pos.minX + 'px',
                height: pos.maxY - pos.minY + 'px'
            }
        },
        getBarStyle(region) {
            const mapElRect = this.$refs.mapEl ? this.getCoords(this.$refs.mapEl) : { left: 0, top: 0 };
            const pos = this.regionPositions[region];
            if (!pos) {
                return {
                    display: 'none'
                }
            }
            const offsetX = mapElRect.left;
            const offsetY = mapElRect.top;
            const left = pos.minX - offsetX;
            const top = pos.minY - offsetY;

            let l = 0.5;
            let t = 0.7;
            switch (region) {
                case "Центральный":
                    l = 0.5;
                    t = 0.5;
                    break;
                case "Северо-Западный":
                    l = 0.55;
                    t = 0.8;
                    break;
                case "Южный":
                    l = 0.55;
                    t = 0.5;
                    break;
                case "Северо-Кавказский":
                    l = 0.3;
                    t = 0.7;
                break;
                case "Приволжский":
                    l = 0.45;
                    t = 0.40;
                break;
                case "Уральский":
                    l = 0.55;
                    t = 0.50;
                break;
                case "Сибирский":
                    l = 0.48;
                    t = 0.7;
                    break;

                case "Дальневосточный":
                    l = 0.35;
                    t = 0.55;
                break;
            }

            

            return {
                position: 'absolute',
                left: left + (pos.maxX - pos.minX) * l + this.$refs.mapEl.scrollLeft + 'px',
                top: top + (pos.maxY - pos.minY) * t + 'px',
                transform: 'translate(-50%, -100%)'
                // width: pos.maxX - pos.minX + 'px',
                // height: pos.maxY - pos.minY + 'px'
            }
        },
        getCoords(elem) {
            const box = elem.getBoundingClientRect();

            const body = document.body;
            const docEl = document.documentElement;

            const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

            const clientTop = docEl.clientTop || body.clientTop || 0;
            const clientLeft = docEl.clientLeft || body.clientLeft || 0;

            const top = box.top + scrollTop - clientTop;
            const left = box.left + scrollLeft - clientLeft;

            return { top: Math.round(top), left: Math.round(left) };
        },
        regionHovered(region, e) {
            if (this.barChartRegion === region) return;
            if (!e.target)  return;

            this.barChartData = {};
            let item = this.socialProblemMap.find(item => item.name === region);
            if (item) {
                this.barChartData = {
                    labels: item.details.map(el => el.name),
                    data: item.details.map(el => el.value),
                };
            }

            item = this.socialProblemMap2.find(item => item.name === region);
            this.barChartData.data2 = [];
            if (this.compareDate.startDate && item) {
                this.barChartData.data2 = item.details.map(el => el.value);
            }
            this.barChartRegion = region;
            let left = Math.min(Math.max(270, e.target.closest('.bar-wrap').offsetLeft - 50, this.$refs.mapEl.parentNode.scrollLeft + 264 + 20), this.$refs.mapEl.clientWidth - 30) + 'px';
            let top = Math.min(Math.max(0, e.target.closest('.bar-wrap').offsetTop - 100), this.$refs.mapEl.clientHeight - 200) + 'px';
            let transform;
            if (this.isMobile) {
                top = '50%';
                left = this.$refs.mapEl.parentNode.scrollLeft + (this.$refs.mapEl.parentNode.clientWidth) / 2 + 'px';
                transform = `translate(-50%, -50%)`;
            }
            this.barChartStyles = {
                left: left,
                top: top,
                transform,
                opacity: 1,
            };
        },
        regionHoverEnd(e) {
            const x = e.clientX, y = e.clientY;
            const el = document.elementFromPoint(x, y);
            if (el && (el.classList.contains('bar-chart-wrap') || el.closest('.bar-chart-wrap') || el.classList.contains('bar-wrap') || el.closest('.bar-wrap'))) {
                return;
            }
            this.barChartStyles = {
                ...this.barChartStyles,
                opacity: 0,
            };
            this.barChartRegion = null;
            setTimeout(() => {
                if (this.barChartStyles.opacity !== 0) return;
                this.barChartStyles = {
                    ...this.barChartStyles,
                    opacity: 0,
                    zIndex: -1,
                };
            }, 400);
        },
        getRandomInt() {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
        },
    },
    watch: {
        windowWidth() {
            this.updatePathPositions();
        },
        positions: {
            handler(v) {
                const regionPositions = {};
                for (const region in this.positions) {
                    let minX = Infinity;
                    let maxX = -Infinity;
                    let minY = Infinity;
                    let maxY = -Infinity;
                    for (const path of this.positions[region]) {
                        minX = Math.min(minX, path.left)
                        maxX = Math.max(maxX, path.right)
                        minY = Math.min(minY, path.top)
                        maxY = Math.max(maxY, path.bottom)
                    }
                    regionPositions[region] = {
                        minX,
                        maxX,
                        minY,
                        maxY,
                    }
                }
                this.regionPositions = regionPositions;
            },
            deep: true,
        },
    },
    mounted() {
        setInterval(() => this.updatePathPositions(), 1000);
    }
};
</script>

<style lang="scss" scoped>
.map {
    position: relative;
    height: (546 / 1366) * 100vw;
    min-height: 376px;
    max-height: 874px;
    overflow: hidden;
    cursor: default;
    width: (1100 / 1366) * 100vw;
    min-width: 680px;
    max-width: 1926px;
    background-color: #fff;

    &-wrap {
        overflow-x: auto;
    }
}

.bar-wrap {
    text-align: center;
    font-weight: 500;
    font-size: 10px;
    z-index: 1;
}

svg {

    .state {
        stroke-width: 1;
        /*stroke: #fff;*/
        -webkit-transition: stroke 0.5s, stroke-width 0.5s;
        -o-transition: stroke 0.5s, stroke-width 0.5s;
        transition: stroke 0.5s, stroke-width 0.5s;
    }
}

svg .state:hover,
.state.active {
    //cursor: pointer;
    stroke-width: 1;
    /*stroke: #000;*/
}

/*path {
    !*fill: #2F80ED;*!
    transform-origin: center center;
    //opacity: 0.75;
}

.path1 {
    //transform: translate();
}

.path2 {
    transform: translate(178px, 105px)
}

.path3 {
    transform: translate(183px, 486px)
}

.path4 {
    transform: translate(261px, -4px)
}

.path5 {
    transform: translate(410.5px, -4.5px)
}

.path6 {
    transform: translate(759px, -4px)
}

.path7 {
    transform: translate(405px, 586px)
}

.path8 {
    transform: translate(332px, 635px)
}*/

.regionActive {
    /*fill: #28b93c;*/
    //opacity: 0.75;
}

p {
    margin: 0 0 10px;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
}

.close:focus,
.close:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.75;
}

.region {
    position: absolute;
    top: calc(100% + 6px);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
}

.bar-chart-wrap {
    z-index: 1000;
    //display: none;
    position: absolute;
    //top: 100px;
    //left: 200px;
    width: 238px;
    background-color: #fff;
    padding: 13px;
    transform: translateX(-100%);
    box-shadow: 0 3.79px 12.65px #E0E0E0;
    opacity: 0;
    transition: opacity .3s ease, top .3s ease, left .3s ease;
}

@media (max-width: 1000px) {
    .bar-wrap {
        font-size: 8px;
    }
    .region {
        top: calc(100% + 4px);
    }
}
</style>
