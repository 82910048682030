<template>
    <section id="regions">
        <div class="container">
            <h2 class="section-title">Проблематика</h2>
            <router-link
                :to="$url('/socnet-negativemessages')"
                class="section-subtitle"
            >Коэффициенты негативных сообщений по округам</router-link>
            <div class="map-chart">
                <RussianMap />
            </div>
        </div>
    </section>
</template>

<script>
import RussianMap from '@/components/Map/RussianMap';
import {mapGetters} from "vuex";

export default {
    name: 'NegativeMessageRegions',
    components: {
        RussianMap,
    },
    computed: {
        ...mapGetters(['date', 'compareDate', 'tonality', 'region', 'detailization']),
    }
};
</script>

<style lang="scss" scoped>
.map-chart {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 60px;
}
</style>
