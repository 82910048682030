<template>
    <section class="section">
        <div class="container">
            <router-link
                :to="$url('/socnet-mentions')"
                class="section-subtitle"
            >Матрица репутационных рисков
            </router-link>
            <BubbleChartComponent />
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";
import BubbleChartComponent from '@/components/BubbleChartComponent';

export default {
    name: 'ReputationMatrixBubble',
    components: {
        BubbleChartComponent,
    },
    computed: {
        ...mapGetters({
            date: 'date',
            compareDate: 'compareDate',
            region: 'region',
            tonality: 'tonality',
            detailization: 'detailization',
        }),
    },
};
</script>
