import Vue from 'vue';
import VTooltip from 'v-tooltip'
import VModal from 'vue-js-modal'
import axios from 'axios';
import _ from 'lodash';
import App from './App.vue';
import './filters';
import router from './router';
import store from './store';

import Chart from 'chart.js';
import { fontFamily } from '@/styles/vars.scss';
import { debounce } from '@/helpers';
import $url from '@/mixins/url';

// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//     for(let registration of registrations) {
//         registration.unregister()
//     } });

window._ = _;


Chart.defaults.global.defaultFontFamily = fontFamily;

// Chart.elements.Rectangle.prototype.inRange = function (chartX, chartY) {
//  // console.log('inRange:', chartX, chartY);
//  return true;
//  return (chartX >= this.x - this.width / 2 && chartX <= this.x + this.width / 2) && (chartY >= this.y && chartY <= (this.base + 5));
// };

// localStorage.setItem('token', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6IjRmMWcyM2ExMmFhIn0.eyJpc3MiOiJodHRwOlwvXC9hZG1pbi5lY2hvLnByb2dub3Npcy5ydSIsImp0aSI6IjRmMWcyM2ExMmFhIiwiaWF0IjoxNjIxNDkwNTE2LCJleHAiOjE2MjQwODI1MTYsInVpZCI6MX0.9M-cBZjdq9tUA3wqDFQvhcWG7GPpl2x9aw_8M3-Iy_c');

function clipArc(ctx, arc) {
    var startAngle = arc.startAngle;
    var endAngle = arc.endAngle;
    var pixelMargin = arc.pixelMargin;
    var angleMargin = pixelMargin / arc.outerRadius;
    var x = arc.x;
    var y = arc.y;

    // Draw an inner border by cliping the arc and drawing a double-width border
    // Enlarge the clipping arc by 0.33 pixels to eliminate glitches between borders
    ctx.beginPath();
    ctx.arc(x, y, arc.outerRadius, startAngle - angleMargin, endAngle + angleMargin);
    if (arc.innerRadius > pixelMargin) {
        angleMargin = pixelMargin / arc.innerRadius;
        ctx.arc(x, y, arc.innerRadius - pixelMargin, endAngle + angleMargin, startAngle - angleMargin, true);
    } else {
        ctx.arc(x, y, pixelMargin, endAngle + Math.PI / 2, startAngle - Math.PI / 2);
    }
    ctx.closePath();
    ctx.clip();
}

function drawFullCircleBorders(ctx, vm, arc, inner) {
    var endAngle = arc.endAngle;
    var i;

    if (inner) {
        arc.endAngle = arc.startAngle + TAU;
        clipArc(ctx, arc);
        arc.endAngle = endAngle;
        if (arc.endAngle === arc.startAngle && arc.fullCircles) {
            arc.endAngle += TAU;
            arc.fullCircles--;
        }
    }

    ctx.beginPath();
    ctx.arc(arc.x, arc.y, arc.innerRadius, arc.startAngle + TAU, arc.startAngle, true);
    for (i = 0; i < arc.fullCircles; ++i) {
        ctx.stroke();
    }

    ctx.beginPath();
    ctx.arc(arc.x, arc.y, vm.outerRadius, arc.startAngle, arc.startAngle + TAU);
    for (i = 0; i < arc.fullCircles; ++i) {
        ctx.stroke();
    }
}

function drawBorder(ctx, vm, arc) {
    var inner = vm.borderAlign === 'inner';

    if (inner) {
        ctx.lineWidth = vm.borderWidth * 2;
        ctx.lineJoin = 'round';
    } else {
        ctx.lineWidth = vm.borderWidth;
        ctx.lineJoin = 'bevel';
    }

    if (arc.fullCircles) {
        drawFullCircleBorders(ctx, vm, arc, inner);
    }

    if (inner) {
        clipArc(ctx, arc);
    }

    ctx.beginPath();
    ctx.arc(arc.x, arc.y, vm.outerRadius, arc.startAngle, arc.endAngle);
    ctx.arc(arc.x, arc.y, arc.innerRadius, arc.endAngle, arc.startAngle, true);
    ctx.closePath();
    ctx.stroke();
}

Chart.elements.Arc.prototype.draw = function(chartArea) {
    var TAU = Math.PI * 2;
    var ctx = this._chart.ctx;
    var vm = this._view;
    var pixelMargin = vm.borderAlign === 'inner' ? 0.33 : 0;
    var arc = {
        x: vm.x,
        y: vm.y,
        innerRadius: vm.innerRadius,
        outerRadius: Math.max(this._chart.options.outerRadius || vm.outerRadius - pixelMargin, 0),
        pixelMargin: pixelMargin,
        startAngle: vm.startAngle,
        endAngle: vm.endAngle,
        fullCircles: Math.floor(vm.circumference / TAU),
    };
    var i;

    ctx.save();

    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;

    if (arc.fullCircles) {
        arc.endAngle = arc.startAngle + TAU;
        ctx.beginPath();
        ctx.arc(arc.x, arc.y, arc.outerRadius, arc.startAngle, arc.endAngle);
        ctx.arc(arc.x, arc.y, arc.innerRadius, arc.endAngle, arc.startAngle, true);
        ctx.closePath();
        for (i = 0; i < arc.fullCircles; ++i) {
            ctx.fill();
        }
        arc.endAngle = arc.startAngle + (vm.circumference % TAU);
    }

    ctx.beginPath();
    ctx.arc(arc.x, arc.y, arc.outerRadius, arc.startAngle, arc.endAngle);
    ctx.arc(arc.x, arc.y, arc.innerRadius, arc.endAngle, arc.startAngle, true);
    ctx.closePath();
    ctx.fill();

    if (vm.borderWidth) {
        drawBorder(ctx, vm, arc);
    }

    ctx.restore();
};

Chart.elements.Point.prototype.draw = function(chartArea) {
    var vm = this._view;
    var ctx = this._chart.ctx;
    var pointStyle = vm.pointStyle;
    var rotation = vm.rotation;
    var radius = vm.radius;
    var x = vm.x;
    var y = vm.y;
    var globalDefaults = Chart.defaults.global;
    var defaultColor = globalDefaults.defaultColor; // eslint-disable-line no-shadow

    if (vm.skip) {
        return;
    }

    // Clipping for Points.
    if (chartArea === undefined || Chart.helpers.canvas._isPointInArea(vm, chartArea)) {
        ctx.strokeStyle = vm.borderColor || defaultColor;
        ctx.lineWidth = Chart.helpers.valueOrDefault(vm.borderWidth, globalDefaults.elements.point.borderWidth);
        ctx.fillStyle = vm.backgroundColor || defaultColor;
        Chart.helpers.canvas.drawPoint(ctx, pointStyle, radius, x, y, rotation);
    }
};
Chart.helpers.canvas.drawPoint = function(ctx, style, radius, x, y, rotation) {
    // console.log('Chart.helpers.drawPoint', style, radius, x, y, rotation)
    var PI = Math.PI;
    var RAD_PER_DEG = PI / 180;
    var DOUBLE_PI = PI * 2;
    var HALF_PI = PI / 2;
    var QUARTER_PI = PI / 4;
    var TWO_THIRDS_PI = (PI * 2) / 3;

    var type, xOffset, yOffset, size, cornerRadius;
    rotation = rotation || 0;
    var rad = rotation * RAD_PER_DEG;

    if (style && typeof style === 'object') {
        type = style.toString();
        if (type === '[object HTMLImageElement]' || type === '[object HTMLCanvasElement]') {
            ctx.save();
            ctx.translate(x, y);
            ctx.rotate(rad);
            ctx.drawImage(style, -style.width / 2, -style.height / 2, style.width, style.height);
            ctx.restore();
            return;
        }
    }

    if (isNaN(radius) || radius <= 0) {
        return;
    }

    ctx.beginPath();

    switch (style) {
        // Default includes circle

        case 'semicircle':
            ctx.arc(x, y, radius, rotation, rotation + PI);
            ctx.closePath();
            break;
        default:
            ctx.arc(x, y, radius, 0, DOUBLE_PI);
            ctx.closePath();
            break;
        case 'triangle':
            ctx.moveTo(x + Math.sin(rad) * radius, y - Math.cos(rad) * radius);
            rad += TWO_THIRDS_PI;
            ctx.lineTo(x + Math.sin(rad) * radius, y - Math.cos(rad) * radius);
            rad += TWO_THIRDS_PI;
            ctx.lineTo(x + Math.sin(rad) * radius, y - Math.cos(rad) * radius);
            ctx.closePath();
            break;
        case 'rectRounded':
            // NOTE: the rounded rect implementation changed to use `arc` instead of
            // `quadraticCurveTo` since it generates better results when rect is
            // almost a circle. 0.516 (instead of 0.5) produces results with visually
            // closer proportion to the previous impl and it is inscribed in the
            // circle with `radius`. For more details, see the following PRs:
            // https://github.com/chartjs/Chart.js/issues/5597
            // https://github.com/chartjs/Chart.js/issues/5858
            cornerRadius = radius * 0.516;
            size = radius - cornerRadius;
            xOffset = Math.cos(rad + QUARTER_PI) * size;
            yOffset = Math.sin(rad + QUARTER_PI) * size;
            ctx.arc(x - xOffset, y - yOffset, cornerRadius, rad - PI, rad - HALF_PI);
            ctx.arc(x + yOffset, y - xOffset, cornerRadius, rad - HALF_PI, rad);
            ctx.arc(x + xOffset, y + yOffset, cornerRadius, rad, rad + HALF_PI);
            ctx.arc(x - yOffset, y + xOffset, cornerRadius, rad + HALF_PI, rad + PI);
            ctx.closePath();
            break;
        case 'rect':
            if (!rotation) {
                size = Math.SQRT1_2 * radius;
                ctx.rect(x - size, y - size, 2 * size, 2 * size);
                break;
            }
            rad += QUARTER_PI;
        /* falls through */
        case 'rectRot':
            xOffset = Math.cos(rad) * radius;
            yOffset = Math.sin(rad) * radius;
            ctx.moveTo(x - xOffset, y - yOffset);
            ctx.lineTo(x + yOffset, y - xOffset);
            ctx.lineTo(x + xOffset, y + yOffset);
            ctx.lineTo(x - yOffset, y + xOffset);
            ctx.closePath();
            break;
        case 'crossRot':
            rad += QUARTER_PI;
        /* falls through */
        case 'cross':
            xOffset = Math.cos(rad) * radius;
            yOffset = Math.sin(rad) * radius;
            ctx.moveTo(x - xOffset, y - yOffset);
            ctx.lineTo(x + xOffset, y + yOffset);
            ctx.moveTo(x + yOffset, y - xOffset);
            ctx.lineTo(x - yOffset, y + xOffset);
            break;
        case 'star':
            xOffset = Math.cos(rad) * radius;
            yOffset = Math.sin(rad) * radius;
            ctx.moveTo(x - xOffset, y - yOffset);
            ctx.lineTo(x + xOffset, y + yOffset);
            ctx.moveTo(x + yOffset, y - xOffset);
            ctx.lineTo(x - yOffset, y + xOffset);
            rad += QUARTER_PI;
            xOffset = Math.cos(rad) * radius;
            yOffset = Math.sin(rad) * radius;
            ctx.moveTo(x - xOffset, y - yOffset);
            ctx.lineTo(x + xOffset, y + yOffset);
            ctx.moveTo(x + yOffset, y - xOffset);
            ctx.lineTo(x - yOffset, y + xOffset);
            break;
        case 'line':
            xOffset = Math.cos(rad) * radius;
            yOffset = Math.sin(rad) * radius;
            ctx.moveTo(x - xOffset, y - yOffset);
            ctx.lineTo(x + xOffset, y + yOffset);
            break;
        case 'dash':
            ctx.moveTo(x, y);
            ctx.lineTo(x + Math.cos(rad) * radius, y + Math.sin(rad) * radius);
            break;
    }

    ctx.fill();
    ctx.stroke();
};
Chart.elements.Rectangle.prototype.draw = function() {
    var ctx = this._chart.ctx;
    var vm = this._view;
    var left, right, top, bottom, signX, signY, borderSkipped, radius;
    var borderWidth = vm.borderWidth;

    // If radius is less than 0 or is large enough to cause drawing errors a max
    //      radius is imposed. If cornerRadius is not defined set it to 0.
    var cornerRadius = this._chart.config.options.cornerRadius;
    var chartType = this._chart.config.options.type;
    var haveBottomRadius = this._chart.config.options.haveBottomRadius;
    var innerPadding = this._chart.config.options.innerPadding;
    var haveLeftRadius = this._chart.config.options.haveLeftRadius;

    if (typeof innerPadding === 'undefined') {
        innerPadding = 0;
    }
    if (cornerRadius < 0) {
        cornerRadius = 0;
    }
    if (typeof cornerRadius == 'undefined') {
        cornerRadius = 0;
    }

    if (typeof haveLeftRadius == 'undefined') {
        haveLeftRadius = true;
    }

    if (typeof haveBottomRadius == 'undefined') {
        haveBottomRadius = true;
    }

    if (!vm.horizontal) {
        // bar
        left = vm.x - vm.width / 2 + innerPadding / 2;
        right = vm.x + vm.width / 2 - innerPadding / 2;
        top = vm.y;
        bottom = vm.base;
        signX = 1;
        signY = bottom > top ? 1 : -1;
        borderSkipped = vm.borderSkipped || 'bottom';
    } else {
        // horizontal bar
        left = vm.base;
        right = vm.x;
        top = vm.y - vm.height / 2;
        bottom = vm.y + vm.height / 2;
        signX = right > left ? 1 : -1;
        signY = 1;
        borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
        // borderWidth shold be less than bar width and bar height.
        var barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
        borderWidth = borderWidth > barSize ? barSize : borderWidth;
        var halfStroke = borderWidth / 2;
        // Adjust borderWidth when bar top position is near vm.base(zero).
        var borderLeft = left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
        var borderRight = right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
        var borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
        var borderBottom = bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
        // not become a vertical line?
        if (borderLeft !== borderRight) {
            top = borderTop;
            bottom = borderBottom;
        }
        // not become a horizontal line?
        if (borderTop !== borderBottom) {
            left = borderLeft;
            right = borderRight;
        }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    var corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
    ];

    // Find first (starting) corner with fallback to 'bottom'
    var borders = ['bottom', 'left', 'top', 'right'];
    var startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
        startCorner = 0;
    }

    function cornerAt(index) {
        return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    var corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);

    for (var i = 1; i < 4; i++) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId == 4) {
            nextCornerId = 0;
        }

        let nextCorner = cornerAt(nextCornerId);

        let width = corners[2][0] - corners[1][0];
        let height = corners[0][1] - corners[1][1];
        let x = corners[1][0];
        let y = corners[1][1];

        //console.log(this._view, corners[2][0] - corners[1][0],444)

        var radius = cornerRadius;
        // Fix radius being too large

        if (radius > Math.abs(height) / 2) {
            radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
            radius = Math.floor(Math.abs(width) / 2);
        }

        if (height < 0) {
            // Negative values in a standard bar chart
            let x_tl = x;
            let x_tr = x + width;
            let y_tl = y + height;
            let y_tr = y + height;

            let x_bl = x;
            let x_br = x + width;
            let y_bl = y;
            let y_br = y;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
        } else if (width < 0) {
            // Negative values in a horizontal bar chart
            let x_tl = x + width;
            let x_tr = x;
            let y_tl = y;
            let y_tr = y;

            let x_bl = x + width;
            let x_br = x;
            let y_bl = y + height;
            let y_br = y + height;

            // Draw
            ctx.moveTo(x_bl + radius, y_bl);
            ctx.lineTo(x_br - radius, y_br);
            ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
            ctx.lineTo(x_tr, y_tr + radius);
            ctx.quadraticCurveTo(x_tr, y_tr, x_tr - radius, y_tr);
            ctx.lineTo(x_tl + radius, y_tl);
            ctx.quadraticCurveTo(x_tl, y_tl, x_tl, y_tl + radius);
            ctx.lineTo(x_bl, y_bl - radius);
            ctx.quadraticCurveTo(x_bl, y_bl, x_bl + radius, y_bl);
        } else {
            let x_bl = x + width;
            let x_br = x;
            let y_bl = y + height;
            let y_br = y + height;

            //Positive Value
            ctx.moveTo(x + radius, y);
            ctx.lineTo(x + width - radius, y); //top left
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius);

            if (chartType === 'verticalBar' && !haveBottomRadius) {
                ctx.lineTo(x_bl, y_bl); //bottom left
                ctx.quadraticCurveTo(x_bl, y_bl, x_bl, y_bl);
                ctx.lineTo(x_br, y_br); //bottom right
                ctx.quadraticCurveTo(x_br, y_br, x_br, y_br);
            } else {
                ctx.lineTo(x_bl, y_bl - radius); //bottom left
                ctx.quadraticCurveTo(x_bl, y_bl, x_bl - radius, y_bl);

                if (chartType === 'horizontalBar' && !haveLeftRadius) {
                    ctx.lineTo(x_br, y_br); //bottom right
                    ctx.quadraticCurveTo(x_br, y_br, x_br, y_br);
                } else {
                    ctx.lineTo(x_br + radius, y_br); //bottom right
                    ctx.quadraticCurveTo(x_br, y_br, x_br, y_br - radius);
                }
            }
            if (chartType === 'horizontalBar' && !haveLeftRadius) {
                ctx.lineTo(x, y); // top right
                ctx.quadraticCurveTo(x, y, x, y);
            } else {
                ctx.lineTo(x, y + radius); // top right
                ctx.quadraticCurveTo(x, y, x + radius, y);
            }
        }
    }

    ctx.fill();
    if (borderWidth) {
        ctx.stroke();
    }
};

document.body.onclick= function(e){
    var elementPagination =  document.querySelector('.vm--modal .pagination-wrap .pagination-item:nth-child(2)');
    if(elementPagination){
        e=window.event? event.srcElement: e.target;
        if(e.className && e.className.indexOf('orderable')!=-1) {
            
            if (typeof(elementPagination) != 'undefined' && elementPagination != null) {
                elementPagination.click();
            }   
        }
    }
}

// axios.defaults.baseURL = 'http://localhost:8080';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false;

window.addEventListener('resize', () => store.dispatch('setResizing', true));
window.addEventListener(
    'resize',
    () => {
        store.dispatch('setWindowWidth', window.innerWidth);
        store.dispatch('setResizing', false);
    },
);
store.dispatch('setWindowWidth', window.innerWidth);
store.dispatch('setResizing', false);

const token = localStorage.getItem('token');

if (token) {
    axios.defaults.headers = {
        Authorization: `Bearer ${token}`,
    };
    // await store.dispatch('auth/refreshToken');
}

Vue.prototype.$skin = process.env.VUE_APP_SKIN;
Vue.prototype.$email = process.env.VUE_APP_EMAIL;
Vue.prototype.$adminUrl = process.env.VUE_APP_ADMIN_URL;
Vue.prototype.$checkAccess = function(access) {
    if (access === false) {
        this.$router.push('/');
    }
};

Vue.use(VModal);
Vue.use(VTooltip, {
    defaultBoundariesElement: 'window',
    defaultTrigger: 'hover focus click',
});

Vue.mixin($url);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
