<template>
    <svg
            :width="width"
            :height="height"
            viewBox="0 0 14 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
    >
        <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.728457 0.21967C1.03307 -0.0732233 1.52693 -0.0732233 1.83154 0.21967L7 5.18934L12.1685 0.21967C12.4731 -0.0732233 12.9669 -0.0732233 13.2715 0.21967C13.5762 0.512563 13.5762 0.987437 13.2715 1.28033L7.55154 6.78033C7.24693 7.07322 6.75307 7.07322 6.44846 6.78033L0.728457 1.28033C0.423848 0.987437 0.423848 0.512563 0.728457 0.21967Z"
                :fill="fill"
        />
    </svg>
</template>

<script>
export default {
    name: 'ArrowDown',
    props: {
        fill: {
            type: String,
            required: false,
            default: '#1C1C1C',
        },
        width: {
            type: Number,
            required: false,
            default: 14,
        },
        height: {
            type: Number,
            required: false,
            default: 7,
        },
    },
}
</script>
