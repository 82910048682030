import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        pressMentionsLoaded: false,
        pressMentions: {
            data: [],
            posts: [],
        },
        pressMentions2: {
            data: [],
            posts: [],
        },
        pressMessages: {
            data: [],
            total: 0,
        },
        pressMessages2: {
            data: [],
            total: 0,
        },
        perPage: 50,
    },
    mutations: {
        setPressMentions(state, val) {
            state.pressMentions = {
                data: val.data,
                posts: val.posts.map(({date, name}) => ({
                    title: name,
                    date,
                }))
            };
            state.pressMentionsLoaded = true;
        },
        setPressMentions2(state, val) {
            state.pressMentions2 = {
                data: val.data,
                posts: val.posts.map(({date, name}) => ({
                    title: name,
                    date,
                }))
            };
        },
        setPressMessages(state, val) {
            state.pressMessages = val;
        },
    },
    actions: {
        async getPressMentions({ dispatch, commit }, {date, detailization, tonality, region}) {
            try {
                const res = await axios.get('/smi/mentions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        groupby: detailization.value,
                        // tonality,
                        // region,
                    }
                });
                const resData = res.data;
                commit('setPressMentions', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressMentions2({ dispatch, commit }, {date, detailization, tonality, region}) {
            try {
                const res = await axios.get('/smi/mentions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        groupby: detailization.value,
                        // tonality,
                        // region,
                    }
                });
                const resData = res.data;
                commit('setPressMentions2', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressMessages({ state, dispatch, commit }, {date, detailization, tonality, region, page, orderBy, orderDir}) {
            try {
                const res = await axios.get('/smi/posts', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        groupby: detailization.value,
                        perpage: state.perPage,
                        orderby: orderBy,
                        orderdir: orderDir,
                        page,
                        tonality,
                        region,
                    }
                });
                const resData = res.data;
                commit('setPressMessages', resData);
                return resData;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        pressMentionsLoaded(state) {
            return state.pressMentionsLoaded;
        },
        pressMentions(state) {
            return state.pressMentions;
        },
        pressMentions2(state) {
            return state.pressMentions2;
        },
        pressMessages(state) {
            return state.pressMessages.data;
        },
        pressMessages2(state) {
            return state.pressMessages2.data;
        },
        pressMessagesPages(state) {
            return Math.max(1,
                Math.ceil(
                    Math.max(
                        state.pressMessages.total || 0,
                        state.pressMessages2.total || 0
                    ) / state.perPage)
            );
        },
    },
};
