import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        socialProblems: {
            data: [],
        },
        socialProblems2: {},
    },
    mutations: {
        setSocialProblems(state, val) {
            state.socialProblems = val;
        },
        setSocialProblems2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.title2] = item;
            });
            state.socialProblems2 = data;
        },
    },
    actions: {
        async getSocialProblems({ dispatch, commit }, {date, tonality, region, orderBy, orderDir}) {
            try {
                const res = await axios.get('/social/problems', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        region,
                        tonality,
                        orderby: orderBy,
                        orderdir: orderDir,
                    }
                });
                const resData = res.data;
                commit('setSocialProblems', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialProblems2({ dispatch, commit }, {date, tonality, region, orderBy, orderDir}) {
            try {
                const res = await axios.get('/social/problems', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        region,
                        tonality,
                        orderby: orderBy,
                        orderdir: orderDir,
                    }
                });
                const resData = res.data;
                commit('setSocialProblems2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        socialProblems(state) {
            return state.socialProblems.data;
        },
        socialProblems2(state) {
            return state.socialProblems.data.map(item => state.socialProblems2[item.title2]);
        },
    },
};
