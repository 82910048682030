<template>
    <div :class="{'radio-label-group': true, 'solid-group': groupSolid}">
        <RadioInput
            v-for="(option, idx) in options"
            :key="idx"
            v-model="fastChoused"
            :period="period"
            :optionValue="option.value"
            :hidden="true"
            name="fastChouse"
            @change="updateValue(fastChoused)"
        >{{ option.text }}
        </RadioInput>
    </div>
</template>
<script>
import RadioInput from '@/components/Form/RadioInput';

export default {
    name: 'RadioInputGroup',
    model: {
        event: 'change',
    },
    props: {
        groupSolid: {
            type: Boolean,
            required: false,
            default: true,
        },
        options: {
            type: Array,
            required: true,
        },
        period:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            fastChoused: '',
        };
    },
    methods: {
        updateValue(value) {
            this.$emit('change', value);
        },
    },
    components: {
        RadioInput,
    },
    mounted(){
        console.log(this.period);
    }
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.radio-label-group.solid-group {
    flex-basis: 100%;

    .radio-label {
        margin: 0;
        padding: 14px calc(2% + 1px) 14px calc(2% + 2px);
        font-size: 1.3rem;
        line-height: (19/13);
        font-weight: 400;
        border-radius: 0;
        border-color: $c-grey4;

        &.checked,
        &:hover {
            border-color: $c-primary;
        }

        &:first-child {
            padding: 14px calc(2% + 1px) 14px calc(3% + 2px);
            border-bottom-left-radius: 4px;
            border-top-left-radius: 4px;
        }

        &:last-child {
            padding: 14px calc(3% + 2px);
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
        }

        &:not(:first-child) {
            border-left: none;
        }

        &:not(:first-child).checked::before,
        &:not(:first-child):hover::before {
            position: absolute;
            content: '';
            display: block;
            left: -1px;
            top: 50%;
            transform: translateY(-50%);
            background-color: $c-primary;
            height: calc(100% + 3px);
            width: 1px;
        }
    }
}

.radio-label {
    &-group {
        width: 100%;
        display: inline-flex;
        overflow: hidden;
    }

    font-size: 1.6rem;
    font-weight: 500;
    line-height: (19/16);
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    position: relative;
    color: $c-dark;
    border: 1px solid $c-dark;
    display: inline-block;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 75px;
    border-radius: 4px;

    &:first-child {
        padding-left: 24px;
        padding-right: 22px;
        flex-shrink: 2;
    }

    &:nth-child(2) {
        padding-left: 20px;
        padding-right: 19px;
    }

    &:nth-child(3) {
        padding-left: 19px;
        padding-right: 18px;
    }

    &:last-child {
        margin-right: 0;
        padding-left: 15px;
        padding-right: 15px;
        flex-shrink: 2;
    }

    &.checked {
        border-color: $c-primary;
    }

    &:hover {
        //background-color: $c-primary;
        border-color: $c-primary;
        //color: $c-white;
    }
}

@media (min-width: 2320px) {
    .radio-label-group.solid-group {
        min-width: 660px;
        .radio-label {
            &:first-child,
            &:last-child {
                padding: 22px 21px;
            }
            &:first-child {
                border-bottom-left-radius: 6px;
                border-top-left-radius: 6px;
            }
            &:last-child {
                border-bottom-right-radius: 6px;
                border-top-right-radius: 6px;
            }
            line-height: (31/20);
            padding: 22px 19px;
            font-size: 2rem;
        }
    }
    .radio-label {
        font-size: 2.5rem;
        padding-top: 17px;
        padding-bottom: 16px;

        padding-left: 30px;
        padding-right: 30px;
        margin-right: 120px;
        border-radius: 6px;

        &:first-child {
            padding-left: 38px;
            padding-right: 38px;
        }

        &:nth-child(2) {
            padding-left: 32px;
            padding-right: 30px;
        }

        &:nth-child(3) {
            padding-left: 30px;
            padding-right: 28px;
        }

        &:last-child {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}

@media (min-width: 1700px) and (max-width: 2319px) {
    .radio-label-group.solid-group {
        .radio-label {
            &:first-child,
            &:last-child {
                padding: 14px;
            }

            padding: 14px;
            font-size: 1.4rem;
            line-height: (18/14);
        }
    }
}

@media (max-width: 1013px) {
    .radio-label-group .radio-label {
        margin-right: 65px;
        font-size: 1.4rem;
        padding-left: 15px;
        padding-right: 15px;
        &:hover {
            border-color: $c-dark;
        }

        &:last-child {
            margin-right: 0;
        }
    }
    .radio-label-group.solid-group .radio-label {
        &:first-child,
        &:last-child {
            padding: 14px 9px;
        }

        padding: 14px 9px;
        font-size: 1.2rem;
        line-height: (18/12);
    }
}

@media (max-width: 620px) {
    .radio-label-group {
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -4px;
        transform: translateX(4px);

        .radio-label {
            padding-left: calc(15 / 585 * 100%);
            padding-right: calc(15 / 585 * 100%);
            margin: 0 4px;
            flex-grow: 1;
            margin-bottom: 8px;
        }
    }
    .radio-label-group.solid-group {
        flex-wrap: nowrap;
        .radio-label {
            &:first-child,
            &:last-child {
                flex-shrink: unset;
                padding: 10px 7px;
            }

            line-height: (16/10);
            padding: 10px 7px;
            font-size: 1rem;
            width: 100px;
        }
    }
}

@media (max-width: 576px) {
    .radio-label-group .radio-label {
        font-size: 1.1rem;
        padding-top: 8px;
        padding-bottom: 8px;
    }
}
</style>
