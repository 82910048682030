import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        infoFeedLoaded: false,
        infoFeed: {
            data: [],
            total: 0,
        },
    },
    mutations: {
        setInfoFeed(state, val) {
            state.infoFeed = val;
            state.infoFeedLoaded = true;
        },
    },
    actions: {
        async getInfoFeed({ state, dispatch, commit }, filters) {
            try {
                const {
                    date,
                    tonality,
                    page = 1,
                    limit,
                    orderBy: orderby,
                    orderDir: orderdir,
                    justReturn,
                } = filters;
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    page,
                    limit: limit || state.perPage,
                    orderby,
                    orderdir,
                };
                const res = await axios.get('/smi/infofeed', {params});
                const resData = res.data;
                if (!justReturn) {
                    commit('setInfoFeed', resData);
                }
                return resData.data;
            } catch (e) {
                console.error(e);
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        infoFeedLoaded(state) {
            return state.infoFeedLoaded;
        },
        infoFeed(state) {
            return state.infoFeed.data;
        },
        infoFeedPages(state) {
            return Math.max(1,
                Math.ceil((state.infoFeed.total || 0 ) / state.perPage)
            );
        },
    },
};
