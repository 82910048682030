import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Token from '../views/Token.vue';
import LoginPage from '../views/LoginPage.vue';
import SmiTop from '../views/SmiTop.vue';
import SmiMentioning from '../views/SmiMentioning.vue';
import SmiTopPublications from '../views/SmiTopPublications.vue';
import SocNetQuantity from '../views/SocNetQuantity.vue';
import SocNetNegativeCategory from '../views/SocNetNegativeCategory.vue';
import SocNetTopNegativePublications from '../views/SocNetTopNegativePublications.vue';
import SocNetNegativeMessages from '../views/SocNetNegativeMessages.vue';
import SocNetTopNegativeSubjects from '../views/SocNetTopNegativeSubjects.vue';
import SocNetMentions from '../views/SocNetMentions.vue';
import SocNetRiskyMentions from '../views/SocNetRiskyMentions.vue';
import Logout from "../views/Logout";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/token',
        name: 'Token',
        component: Token,
        meta: {
            layout: 'empty'
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
        meta: {
            layout: 'empty'
        },
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/smi-top',
        name: 'SmiTop',
        component: SmiTop,
    },
    {
        path: '/smi-mentioning',
        name: 'SmiMentioning',
        component: SmiMentioning,
    },
    {
        path: '/smi-top-publications',
        name: 'SmiTopPublications',
        component: SmiTopPublications,
    },
    {
        path: '/socnet-quantity',
        name: 'SocNetQuantity',
        component: SocNetQuantity,
    },
    {
        path: '/socnet-negativecategory',
        name: 'SocNetNegativeCategory',
        component: SocNetNegativeCategory,
    },
    {
        path: '/socnet-topnegativepublications',
        name: 'SocNetTopNegativePublications',
        component: SocNetTopNegativePublications,
    },
    {
        path: '/socnet-negativemessages',
        name: 'SocNetNegativeMessages',
        component: SocNetNegativeMessages,
    },
    {
        path: '/socnet-topnegativesubjects',
        name: 'SocNetTopNegativeSubjects',
        component: SocNetTopNegativeSubjects,
    },
    {
        path: '/socnet-mentions',
        name: 'SocNetMentions',
        component: SocNetMentions,
    },
    {
        path: '/socnet-riskymentions',
        name: 'SocNetRiskyMentions',
        component: SocNetRiskyMentions,
    },
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    },
    base: process.env.BASE_URL,
    routes
});

export default router;
