import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        selectedPressSources: [],
        topPressLoaded: false,
        topPress: [],
        topPress2: {},
        pressList: {
            data: [],
            total: 0,
        },
        pressList2: {},
        pressPosts: [],
        pressPostsPages: 1,
    },
    mutations: {
        setSelectedPressSources(state, val) {
            state.selectedPressSources = val;
        },
        setTopPress(state, val) {
            state.topPress = val;
            state.topPressLoaded = true;
        },
        setTopPress2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item.value;
            });
            state.topPress2 = data;
        },
        setPressList(state, val) {
            state.pressList = val;
        },
        setPressList2(state, payload) {
            const data = {};
            payload.forEach(item => {
                data[item.name] = item;
            });
            state.pressList2 = data;
        },
        setPressPosts(state, val) {
            state.pressPosts = val;
        },
    },
    actions: {
        async getTopPress({ dispatch, commit }, {date, tonality}) {
            try {
                const res = await axios.get('/smi/postscount', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                    }
                });
                const resData = res.data;
                commit('setTopPress', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getTopPress2({ dispatch, commit }, {date, tonality, names}) {
            try {
                const res = await axios.get('/smi/postscount', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        names,
                        tonality,
                    }
                });
                const resData = res.data;
                commit('setTopPress2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressList({ dispatch, commit }, {date, tonality, sources, orderBy, orderDir}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    sources,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/smi/byposts', {params});
                const resData = res.data;
                commit('setPressList', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressList2({ dispatch, commit }, {date, tonality, sources, orderBy, orderDir}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    sources,
                    orderby: orderBy,
                    orderdir: orderDir,
                };
                const res = await axios.get('/smi/byposts', {params});
                const resData = res.data;
                commit('setPressList2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getPressPosts({ state, dispatch, commit }, {date, tonality, sources, page = 1}) {
            try {
                const params = {
                    date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                    tonality,
                    page,
                    perpage: state.perPage,
                };
                if (sources && sources.length) {
                    params.sources = sources;
                }
                const res = await axios.get('/smi/posts', {params});
                const resData = res.data;
                commit('setPressPosts', resData);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        topPressLoaded(state) {
            return state.topPressLoaded;
        },
        topPress(state) {
            return state.topPress;
        },
        topPress2(state) {
            return state.topPress2;
        },
        pressList(state) {
            return state.pressList.data;
        },
        pressList2(state) {
            return state.pressList.data.map(item => state.pressList2[item.name]);
        },
        pressPosts(state) {
            return state.pressPosts.data;
        },
        pressPostsPages(state) {
            return Math.max(1,
                Math.ceil((state.pressPosts.total || 0 ) / state.perPage)
            );
        }
    },
};
