<template>
    <div :class="['date-result-wrap', $router.currentRoute.path !== '/' ? 'date-result-mb' : null]">
        <div class="container">
            <div v-if="showResult" class="date-result">
                <div class="date-result-title">Выбранные периоды:</div>
                <div class="row date-result-col-wrap">
                    <div class="date-result-col">
                        <div
                            v-for="(inputDateRange, i ) in inputDateRangeGroup"
                            :key="i"
                            class="date-result-row"
                        >
                            <div class="date-result-col-title">Период {{ i + 1 }}</div>
                            <div class="date-result-col-inputs">
                                <DatePickerInputGroup
                                    class="date-result-col-input"
                                    v-model="inputDateRangeGroup[i]"
                                />
                                <div
                                    class="date-result-col-close"
                                    @click="$emit('remove', i)"
                                >
                                    <Close />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        class="btn btn-grey small"
                        @click="$emit('remove', null)"
                    >Сбросить все
                    </div>
                </div>
            </div>
            <div v-else class="date-result date-result--default">
                <div class="date-result-title">
                    Данные за <span v-if="currentDate.startDate === currentDate.endDate">{{ currentDate.startDate }}</span>
                        <span v-else>период с {{ currentDate.startDate }} по {{currentDate.endDate}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DatePickerInputGroup from '@/components/Form/DatePickerInputGroup';
import Close from '@/components/icons/Close';

export default {
    name: 'DatePickerResult',
    model: {
        prop: 'inputDateRangeGroup',
        event: 'input',
    },
    props: {
        inputDateRangeGroup: {
            type: Array,
            required: true,
        },
        currentDate: {
            type: Object,
            default: {}
        },
        showResult: {
            type: Boolean,
            default: false
        }
    },

    components: {
        DatePickerInputGroup,
        Close,
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.date-result {
    margin-top: 16px;
    padding: 10px 62px 10px 16px;
    display: flex;
    align-items: center;
    &--default{
        padding: 20px 62px 0px 0px;
        .data-result-title{
            margin-bottom: 0; 
        }
    }
    &-mb {
        margin-bottom: 20px;
    }
    &-wrap {
        background-color: $c-grey5;
        //margin-bottom: 30px;
    }

    &-title {
        margin-right: 29px;
        color: #000;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: (17/14);
    }

    &-row {
        display: flex;
        align-items: center;

        &:first-child {
            margin-right: 48px;
        }
    }

    &-col {
        display: flex;
        align-items: center;

        &-wrap {
            flex-grow: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-inputs {
            display: flex;
            align-items: center;
        }

        &-close {
            line-height: 0;
            cursor: pointer;
            margin-left: 14px;
        }

        &-title {
            margin-right: 14px;
            color: $c-dark2;
            font-size: 1.3rem;
            font-weight: 500;
            line-height: (16/13);
        }
    }
}

@media (min-width: 2320px) {
    .date-result {
        margin-top: 20px;
        padding: 15px 60px 15px 60px;
        &-mb {
            margin-bottom: 30px;
        }
        &-wrap {
            margin-bottom: 48px;
        }
        &-title {
            margin-right: 40px;
            font-size: 2rem;
        }
        &-row:first-child {
            margin-right: 60px;
        }
        &-col {
            &-close {
                margin-left: 20px;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }

            &-title {
                margin-right: 20px;
                font-size: 1.9rem;
            }
        }
        .btn {
            padding: 0 50px;
            height: 38px;
        }
    }
}

@media (max-width: 1330px) {
    .date-result {
        padding: 10px 0;
    }
}
@media (max-width: 1200px) {
    .date-result {
        &-title {
            margin-right: 15px;
            font-size: 1.2rem;
            flex-basis: 0;
        }
        &-row {
            &:first-child {
                margin-right: 25px;
            }
        }
        .btn {
            font-size: 1.1rem;
        }
        &-col {
            &-close {
                margin-left: 10px;
            }
            &-title {
                margin-right: 10px;
                font-size: 1.1rem;
            }
        }
    }
}
@media (max-width: 1000px) {
    .date-result {
        &-mb {
            margin-bottom: 15px;
        }
        &-title {
            font-size: 1rem;
            margin-right: 10px;
        }
        .btn {
            height: 21px;
            padding-left: 10px;
            padding-right: 10px;
        }
        &-row {
            flex-shrink: 0;
            &:first-child {
                margin-right: 20px;
            }
        }
        &-col {
            &-close {
                margin-left: 6px;
                svg {
                    width: 10px;
                }
            }
            &-title {
                flex-shrink: 0;
                margin-right: 8px;
                font-size: 1rem;
            }
        }
    }
}

@media (max-width: 660px) {
    .date-result {
        padding: 15px 0;
        margin: 0 auto;
        flex-wrap: wrap;
        &--default{
            padding-bottom: 0;
            .date-result-title{
                margin-bottom: 0;
            }
        }
        &-mb {
            margin-bottom: 10px;
        }
        &-title {
            font-size: 1.3rem;
            margin-bottom: 12px;
            flex-basis: 100%;
            //text-align: center;
            margin-right: 0;
        }
        &-col {
            flex-basis: 100%;
            justify-content: center;
            margin-right: 0;
            flex-wrap: wrap;
            &-title {
                font-size: 1.2rem;
                margin-right: 10px;
            }
            &-inputs {
                width: 100%;
            }
        }
        &-row {
            margin-bottom: 12px;
            flex-basis: 100%;
            justify-content: flex-start;
            &:first-child {
                margin-right: 0;
                .date-result-col-title {
                    margin-right: 12px;
                }
            }
        }
        &-col-wrap {
            flex-wrap: wrap;
            .btn {
                height: 27px;
                width: 100%;
            }
        }
    }
}
</style>