import axios from 'axios';
// import socketError from '../utils/socketError';

export default {
    // namespaced: true,

    state: {
        perPage: 50,
        selectedPressSources: [],
        socailProblemMapLoaded: false,
        socialProblemMap: [],
        socialProblemMap2: [],
        socialProblemDistrictMessages: {
            data: [],
            total: 0,
        },
        socialProblemDistrictMessages2: {},
        pressPosts: [],
        pressPostsPages: 1,
    },
    mutations: {
        setSocialProblemMap(state, val) {
            state.socialProblemMap = val || [];
            state.socailProblemMapLoaded = true;
        },
        setSocialProblemMap2(state, payload) {
            state.socialProblemMap2 = payload;
        },
    },
    actions: {
        async getSocialProblemMap({ dispatch, commit }, {date, tonality}) {
            try {
                const res = await axios.get('/social/regions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                    }
                });
                const resData = res.data;
                commit('setSocialProblemMap', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
        async getSocialProblemMap2({ dispatch, commit }, {date, tonality}) {
            try {
                const res = await axios.get('/social/regions', {
                    params: {
                        date: date.startDate.substr(0, 5) + '.20' + date.startDate.substr(6, 2) + '-' + date.endDate.substr(0, 5) + '.20' + date.endDate.substr(6, 2),
                        tonality,
                    }
                });
                const resData = res.data;
                commit('setSocialProblemMap2', resData.data);
                return resData;
            } catch (e) {
                // socketError({ dispatch, commit }, e);
                return false;
            }
        },
    },
    getters: {
        socialProblemMap(state) {
            return state.socialProblemMap;
        },
        socailProblemMapLoaded(state) {
            return state.socailProblemMapLoaded;
        },
        socialProblemMap2(state) {
            return state.socialProblemMap2;
        },
    },
};
