import { Doughnut, mixins, generateChart } from 'vue-chartjs';
import { mergeDeep } from '../../helpers';

const { reactiveProp, reactiveData } = mixins;

Chart.defaults.DoughnutWithTotal = Chart.defaults.doughnut;
Chart.controllers.DoughnutWithTotal = Chart.controllers.doughnut.extend({
    draw: function(ease) {
        const text = this.chart.options.totalCount.text;
        if (text !== null) {
            const ctx = this.chart.ctx;
            const font = ctx.font.split(' ');
            const height = this.chart.chartArea.bottom + this.chart.chartArea.top;
            const width = this.chart.chartArea.right + this.chart.chartArea.left;

            ctx.textBaseline = 'middle';
            ctx.textAlign = 'center';
            ctx.fillStyle = '#000';
            ctx.font = `500 ${this.chart.options.totalCount.fontSize}px ${font[font.length - 1]}`;
            ctx.fillText(text, width / 2, height / 2);
        }
        Chart.controllers.doughnut.prototype.draw.call(this, ease);
    },
});
const DoughnutWithTotal = generateChart('DoughnutWithTotal', 'DoughnutWithTotal');

export default {
    name: 'DoughnutChart',
    extends: DoughnutWithTotal,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            _options: {
                responsive: true,
                maintainAspectRatio: false,
                type: 'doughnut',
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
                hover: { animationDuration: 0, mode: null },
                animation: {
                    duration: 1,
                    onComplete: () => {
                        this.$emit('outerRadiusChanged', this.$data._chart.outerRadius);
                        var chartInstance = this.$data._chart.chart;
                        var ctx = chartInstance.ctx;

                        ctx.fillStyle = '#1C1C1C';
                        ctx.textAlign = 'center';
                        Chart.helpers.each(
                            this.$data._chart.data.datasets.forEach((dataset, i) => {
                                var meta = chartInstance.controller.getDatasetMeta(i);

                                Chart.helpers.each(
                                    meta.data.forEach((bar, index) => {
                                        const fillText = bar._model.label;
                                        const font = ctx.font.split(' ');
                                        let fixHeight = 2;
                                        ctx.font = `700 ${this.chartOptions.chartPoints.fontSize}px ${font[font.length - 1]}`;

                                        const PI = Math.PI;
                                        const middleAngel = (bar._model.startAngle + bar._model.endAngle) / 2;
                                        const fullRadius = bar._model.outerRadius;
                                        const x = bar._model.x + Math.cos(middleAngel) * fullRadius;
                                        const y = bar._model.y + Math.sin(middleAngel) * fullRadius;

                                        let horizontalLineWidth = null;
                                        const lineWidth = this.chartOptions.chartPoints.lineWidth;
                                        let lineAngel = null;
                                        if (middleAngel > 0 && middleAngel <= PI / 2) {
                                            lineAngel = (30 * PI) / 180;
                                            horizontalLineWidth = this.chartOptions.chartPoints.horizontalLineWidth;
                                            ctx.textBaseline = 'top';
                                        } else if (middleAngel > PI / 2 && middleAngel <= PI) {
                                            lineAngel = (150 * PI) / 180;
                                            horizontalLineWidth = -this.chartOptions.chartPoints.horizontalLineWidth;
                                            ctx.textBaseline = 'top';
                                        } else if (middleAngel > PI && middleAngel <= 1.5 * PI) {
                                            lineAngel = (210 * PI) / 180;
                                            horizontalLineWidth = -this.chartOptions.chartPoints.horizontalLineWidth;
                                            ctx.textBaseline = 'bottom';
                                            fixHeight = 0;
                                        } else if (middleAngel > -0.5 * PI && middleAngel <= 0) {
                                            lineAngel = (330 * PI) / 180;
                                            horizontalLineWidth = this.chartOptions.chartPoints.horizontalLineWidth;
                                            ctx.textBaseline = 'bottom';
                                            fixHeight = 0;
                                        }

                                        const line_end_x = x + Math.cos(lineAngel) * lineWidth;
                                        const line_end_y = y + Math.sin(lineAngel) * lineWidth;
                                        ctx.beginPath();
                                        ctx.moveTo(x, y);
                                        ctx.lineTo(line_end_x, line_end_y);
                                        ctx.strokeStyle = '#1C1C1C';
                                        ctx.stroke();

                                        //horizon line
                                        ctx.beginPath();
                                        ctx.moveTo(line_end_x, line_end_y);
                                        ctx.lineTo(line_end_x + horizontalLineWidth, line_end_y);
                                        ctx.strokeStyle = '#1C1C1C';
                                        ctx.stroke();

                                        ctx.fillStyle = '#1C1C1C';
                                        ctx.fillText(fillText, line_end_x + horizontalLineWidth / 2, line_end_y + fixHeight);
                                    }),
                                    this
                                );
                            }),
                            this
                        );
                    },
                },
            },
            options: {},
        };
    },
    mounted() {
        this.mergeOptions();
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartOptions: {
            handler(v) {
                this.mergeOptions();
                this.renderChart(this.chartData, this.options);
            },
            deep: true,
        },
    },
    methods: {
        mergeOptions() {
            this.options = mergeDeep({}, this.$data._options, this.chartOptions);
        },
    },
};
