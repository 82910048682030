<template>
    <div class="smi-top" v-if="userAccessSmi">
        <div>
            <div class="container">
                <div class="section-subtitle">СМИ: ТОП инфоповодов</div>
            </div>
        </div>
        <Form
            :datePosBreakPoints="{620: 'center', 900: 'left', 10000: 'center'}"
            :fieldsList="['fastChoose', 'date']"
            @download="$downloadReport($data._reportData, $event)"
            @prepare="$prepareReport('getInfoFeed', 'apiQueryParams')"
        />
        <section style="margin-top: 0">
            <div class="container">
                <div class="table-wrap">
                    <Posts
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :posts="infoFeed"
                    />
                </div>
                <Pagination v-model="page" :pages="infoFeedPages"/>
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import Pagination from '@/components/Pagination';
import downloadReport from '@/mixins/downloadReport';
import prepareReport from '@/mixins/prepareReport';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SmiTop',
    data() {
        return {
            page: 1,
            orderData: {},
            postHeaders: {
                date: { title: 'Дата', class: 'td--date', format_number: false, ordering: true },
                name: { title: 'Тема', class: 'td--text', format_number: false, ordering: true },
                count: { title: 'Кол-во', class: '', format_number: true, ordering: true },
                coverage: { title: 'Охват', class: '', format_number: true, ordering: true },
                mediaIndex: { title: 'Медиаиндекс', class: '', format_number: true, ordering: true },
                tonality: { title: 'Тональность', class: '', format_number: false, ordering: true },
                link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
            },
            reportColumns: [ 'date', 'name', 'count', 'coverage', 'mediaIndex', 'tonality', 'link' ],
        };
    },
    components: {
        Form,
        Posts,
        Pagination,
    },
    mixins: [ downloadReport, prepareReport ],
    watch: {
        apiQueryParams: {
            handler() {
                setTimeout(() => {
                    this.userAccessSmi && this.getInfoFeed(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        userAccessSmi(userAccessSmi) {
            this.$checkAccess(userAccessSmi);
        },
    },
    mounted() {
        this.$checkAccess(this.userAccessSmi);
        this.userAccessSmi && this.getInfoFeed(this.apiQueryParams);
    },
    computed: {
        ...mapGetters([
            'date',
            'tonality',
            'compareDate',
            'progressTime',
            'infoFeed',
            'infoFeedPages',
            'userAccessSmi',
        ]),
        apiQueryParams() {
            return {
                date: this.date,
                tonality: this.tonality,
                progressTime: this.progressTime,
                page: this.page,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
                userAccessSmi: this.userAccessSmi,
            }
        },
    },
    methods: {
        ...mapActions([
            'getInfoFeed',
        ]),
    }
};
</script>

<style lang="scss">
@media (max-width: 1309px) {
    .smi-top {
        .form-field {
            &.btns-wrap {
                padding-top: 8px;
            }
        }
    }
}
@media (max-width: 620px) {
    .smi-top {
        .form-field {
            &.btns-wrap {
                padding-top: 10px;
                max-width: unset;
            }
        }
    }
}
</style>
