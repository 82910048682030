<template>
    <section class="form-section screenshot-hidden">
        <div :class="{'container': true, 'form-wrap': true, 'form-pb': isOpenResult}">
            <form>
                <div class="form-inner">
                    <div
                        v-if="fieldsList.includes('fastChoose')"
                        class="form-field fastchoosed"
                    >
                        <div class="form-field-title">Быстрый выбор периода</div>
                        <RadioInputGroup
                            :options="optionsRadioSolid"
                            :period="fastChoused"
                            v-model="fastChoused"
                        />
                    </div>

                    <div
                        v-if="fieldsList.includes('date')"
                        class="form-field date"
                    >
                        <div class="form-field-title">Дата</div>
                        <DatePicker
                            :positionBreakPoints="datePosBreakPoints"
                            title="Выберите дату"
                            inputTitle="Выбор дат"
                            v-model="date"
                        />
                    </div>
                    <div
                        v-if="fieldsList.includes('dateMonth')"
                        class="form-field date"
                    >
                        <div class="form-field-title">Дата</div>
                        <DatePicker
                            :positionBreakPoints="datePosBreakPoints"
                            precision="month"
                            title="Выберите месяц"
                            inputTitle="Выбор месяца"
                            v-model="date"
                        />
                    </div>
                    <div
                        v-if="fieldsList.includes('detailization')"
                        class="form-field detailization"
                    >
                        <Select
                            :options="optionsSelectByDateFiltered"
                            v-model="detailization"
                        />
                    </div>

                    <div
                        v-if="fieldsList.includes('tonality')"
                        class="form-field tonality"
                    >
                        <div class="form-field-title">Тональность</div>
                        <Select
                            :options="optionsSelectTonality"
                            v-model="tonality"
                        />
                    </div>
                    <div
                        v-if="fieldsList.includes('region')"
                        class="form-field region"
                    >
                        <div class="form-field-title">Округ</div>
                        <Select
                            :options="regionOptions"
                            v-model="region"
                        />
                    </div>
                    <div
                        v-if="fieldsList.includes('compareDate')"
                        class="form-field comparedate"
                    >
                        <div class="form-field-title">Сравнить с периодом</div>
                        <DatePicker
                            :positionBreakPoints="comparedatePosBreakPoints"
                            title="Выберите периоды"
                            inputTitle="Выбор периода"
                            inputTitleAfter="Выбраны периоды"
                            v-model="compareDate"
                            :otherDate="date"
                            @update-other-date="date=$event"
                        />
                    </div>
                    <div
                        v-if="fieldsList.includes('compareDateMonth')"
                        class="form-field comparedate"
                    >
                        <div class="form-field-title">Сравнить с периодом</div>
                        <DatePicker
                            :positionBreakPoints="comparedatePosBreakPoints"
                            title="Выберите периоды"
                            inputTitle="Выбор периода"
                            inputTitleAfter="Выбраны периоды"
                            v-model="compareDate"
                            :otherDate="date"
                            precision="month"
                            @update-other-date="date=$event"
                        />
                    </div>

                    <div class="form-field btns-wrap">
                        <button
                            @click.prevent="completeReportHandler"
                            :class="{'btn': true,  'btn-loading': loading}"
                        >Подготовить отчет
                        </button>
                        <div class="btn-wrap">
                            <button
                                @click.prevent="downloadReportHandler"
                                @mouseenter="btnColor = '#fff'"
                                @mouseleave="btnColor = '#1C1C1C'"
                                :class="{'btn': true, 'btn-none-active': true, 'btn-disabled': !reportCompleted, 'active': showFileTypesDropdown}"
                            >
                                <Download
                                    :color="btnColor"
                                    class="btn-icon"
                                />
                                <span>Скачать отчет</span>
                            </button>
                            <ReportDownload
                                @click-outside="showFileTypesDropdown = false"
                                @toggleVisibility="showFileTypesDropdown = !showFileTypesDropdown"
                                v-model="fileType"
                                :hasOtherTypes="tableReport"
                                :visible="showFileTypesDropdown"
                            />
                        </div>
                    </div>
                </div>

                <!-- <div
                    v-if="this.$router.currentRoute.path === '/'"
                    class="form-field links"
                >
                    <RadioInputGroup
                        :groupSolid="false"
                        :options="optionsRadio"
                        v-model="scrollToPage"
                    />
                </div> -->
            </form>
        </div>
        <DatePickerResult
        v-if="isOpenResult"
            @remove="remove"
            :inputDateRangeGroup="[date, compareDate]"
            :currentDate="dateStore"
            :showResult="isOpenResult"
        />
    </section>
</template>
<script>
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

import RadioInputGroup from '@/components/Form/RadioInputGroup';
import DatePickerResult from '@/components/Form/DatePickerResult';
import Select from '@/components/Form/Select';
import DatePicker from '@/components/Form/DatePicker';
import ReportDownload from '@/components/Form/ReportDownload';
import Download from '@/components/icons/Download';
import moment from 'moment';
import {mapActions, mapGetters, mapState} from 'vuex';
import {toBlob} from '@/helpers';

export default {
    name: 'Form',
    props: {
        fieldsList: {
            type: Array,
            required: true,
        },
        tableReport: {
            type: Boolean,
            default: true,
        },
        datePosBreakPoints: {
            type: Object,
            required: false,
            default: () => ({
                620: 'center',
                890: 'right',
            }),
        },
        comparedatePosBreakPoints: {
            type: Object,
            required: false,
            default: () => ({
                620: 'center',
                890: 'center',
                1010: 'left',
            }),
        },
    },
    data() {
        return {
            fastChoused: '',
            scrollToPage: '',
            tonality: '',
            region: '',
            detailization: null,
            fileType: null,
            reportImage: null,
            _reportPdf: null,
            isOpenResult: false,
            date: { startDate: null, endDate: null },
            compareDate: { startDate: null, endDate: null },
            reportCompleted: false,
            loading: false,
            showFileTypesDropdown: false,
            optionsRadioSolid: [
                {
                    value: 'Сегодня',
                    text: 'Сегодня',
                },
                {
                    value: 'Вчера',
                    text: 'Вчера',
                },
                {
                    value: 'Неделя',
                    text: 'Неделя',
                },
                {
                    value: 'Месяц',
                    text: 'Месяц',
                },
                {
                    value: 'Квартал',
                    text: 'Квартал',
                },
                {
                    value: 'Год',
                    text: 'Год',
                },
            ],
            optionsRadio: [
                {
                    value: 'Сводка',
                    text: 'Сводка',
                },
                {
                    value: 'СМИ',
                    text: 'СМИ',
                },
                {
                    value: 'Соцсети',
                    text: 'Соцсети',
                },
                {
                    value: 'Проблематика',
                    text: 'Проблематика',
                },
            ],
            optionsSelectTonality: [
                {value: null, title: 'Все'},
                {value: 'negative', title: 'Негативная'},
                {value: 'positive', title: 'Позитивная'},
                {value: 'neutral', title: 'Нейтральная'},
            ],
            optionsSelectByDate: [
                {
                    title: 'Детализация по дням',
                    value: 'day',
                },
                {
                    title: 'Детализация по неделям',
                    value: 'week',
                },
                {
                    title: 'Детализация по месяцам',
                    value: 'month',
                },
            ],
            btnColor: '#1C1C1C',
        };
    },
    computed: {
        ...mapState(['windowWidth', 'defaultDate', 'dateUpdated']),
        ...mapState({
            'detailizationStore': 'detailization',
            'tonalityStore': 'tonality',
            'regionStore': 'region',
        }),
        ...mapGetters([
            'regions',
        ]),
        ...mapGetters([
            'user',
        ]),
        ...mapGetters({
            'dateStore': 'date',
            'compareDateStore': 'compareDate',
        }),
        regionOptions() {
          if (this.regions.length < 2) {
              return this.regions;
          }
          return ['Все', ...this.regions];
        },
        optionsSelectByDateFiltered() {
            if (!this.date.startDate) {
                return [...this.optionsSelectByDate];
            } else {
                const startDate = moment(this.date.startDate, 'Y-MM-DD');
                const endDate = moment(this.date.endDate, 'Y-MM-DD');
                if (endDate.clone().subtract(3, 'months').isAfter(startDate)) {
                    return [...this.optionsSelectByDate.filter((val) => ['week', 'month'].includes(val.value))];
                } else if (endDate.clone().subtract(2, 'months').isSameOrAfter(startDate)) {
                    return [...this.optionsSelectByDate.filter((val) => ['day', 'week', 'month'].includes(val.value))];
                } else if (endDate.clone().subtract(2, 'weeks').isSameOrAfter(startDate)) {
                    return [...this.optionsSelectByDate.filter((val) => ['day', 'week'].includes(val.value))];
                } else {
                    return [...this.optionsSelectByDate.filter((val) => ['day'].includes(val.value))];
                }
            }
        },
    },
    mounted() {
        if (this.detailizationStore && this.detailizationStore.value) {
            this.detailization = this.detailizationStore;
        } else {
            this.setDetailization(this.optionsSelectByDate[0]);
        }
        if (this.tonalityStore) {
            this.tonality = this.optionsSelectTonality.find(item => item.value === this.tonalityStore);
        }
        if (this.dateUpdated && this.dateStore.startDate) {
            this.date = {
                startDate : moment(this.dateStore.startDate, 'DD.MM.YY').format('Y-MM-DD'),
                endDate : moment(this.dateStore.endDate, 'DD.MM.YY').format('Y-MM-DD'),
            };
        }
        if (this.compareDateStore && this.compareDateStore.startDate) {
            this.compareDate = {
                startDate : moment(this.compareDateStore.startDate, 'DD.MM.YY').format('Y-MM-DD'),
                endDate : moment(this.compareDateStore.endDate, 'DD.MM.YY').format('Y-MM-DD'),
            };
        }
        if (this.dateStore && this.dateStore.startDate) {
            this.date = {
                startDate : moment(this.dateStore.startDate, 'DD.MM.YY').format('Y-MM-DD'),
                endDate : moment(this.dateStore.endDate, 'DD.MM.YY').format('Y-MM-DD'),
            };
        }

        if (this.regionStore) {
            this.region = this.regionStore;
        }
    },
    watch: {
        '$route.fullPath': function(to, from) {
            this.reportCompleted = false;
        },
        tonality(tonality) {
            this.setTonality(tonality.value);
            this.reportCompleted = false;
        },
        region(region) {
            this.setRegion(region === 'Все' ? null : region);
            if(region === 'Все'){
                document.querySelector('.w-smi').classList.remove('hidedBlock');
                document.querySelector('.w-loyality').classList.remove('hidedBlock');
                document.querySelector('.w-mentions').classList.remove('hidedBlock');
            } else {
                document.querySelector('.w-smi').classList.add('hidedBlock');
                document.querySelector('.w-loyality').classList.add('hidedBlock');
                document.querySelector('.w-mentions').classList.add('hidedBlock');
            }
            this.reportCompleted = false;
        },
        fileType(val) {
            switch (val) {
                case 'pdf':
                    this._reportPdf.save(this._reportFn + '.pdf');
                    break;
                case 'xlsx':
                    this.$emit('download', {fn: this._reportFn, type: val});
                    break;
                case 'csv':
                    this.$emit('download', {fn: this._reportFn, type: val});
                    break;
                case null:
                    break;
                default:
                    console.error('Unknown download type:', val);
            }
            this.fileType = null;
        },
        // scrollToPage() {
        //     let container = null;
        //     switch (this.scrollToPage) {
        //         case 'Сводка':
        //             container = document.getElementById('widgets');
        //             break;
        //         case 'СМИ':
        //             container = document.getElementById('smi');
        //             break;
        //         case 'Соцсети':
        //             container = document.getElementById('social');
        //             break;
        //         case 'Проблематика':
        //             container = document.getElementById('regions');
        //             break;
        //         default:
        //             break;
        //     }
        //     window.scrollTo({
        //         top: container.offsetTop,
        //         behavior: 'smooth',
        //     });
        // },
        fastChoused() {
            const format = 'Y-MM-DD';
            this.date.endDate = moment().format(format);
            switch (this.fastChoused) {
                case 'Вчера':
                    this.date.startDate = moment().subtract(1, 'days').startOf('day').format(format);
                    this.date.endDate = moment().subtract(1, 'days').endOf('day').format(format);
                    break;
                case 'Неделя':
                    this.date.startDate = moment().subtract(7, 'days').startOf('day').format(format);
                    break;
                case 'Месяц':
                    this.date.startDate = moment().subtract(30, 'days').startOf('day').format(format);
                    break;
                case 'Квартал':
                    this.date.startDate = moment().subtract(90, 'days').startOf('day').format(format);
                    break;
                case 'Год':
                    this.date.startDate = moment().subtract(365, 'days').startOf('day').format(format);
                    break;
                case 'Сегодня':
                    this.date.startDate = moment().startOf('day').format(format);
                // default:
                //     this.date.startDate = moment().startOf('day').format(format); //Сегодня
            }
            this.setDate({
                startDate : moment(this.date.startDate, 'Y-MM-DD').format('DD.MM.YY'),
                endDate : moment(this.date.endDate, 'Y-MM-DD').format('DD.MM.YY'),
            });
            this.reportCompleted = false;
        },
        date: {
            handler(val, oldval) {
                if (!val.startDate) {
                    val.startDate = null;
                }
                if (!val.endDate) {
                    val.endDate = null;
                }
                if (this.compareDate.startDate || this.compareDate.endDate) {
                    this.isOpenResult = true;
                }
                if (val.startDate) {
                    if (val.startDate !== oldval.startDate || val.endDate !== oldval.endDate) {
                        this.setDate({
                            startDate: moment(val.startDate, 'Y-MM-DD').format('DD.MM.YY'),
                            endDate: moment(val.endDate, 'Y-MM-DD').format('DD.MM.YY'),
                        });
                    }
                } else {
                    this.setDate(this.defaultDate);
                }

                let format = 'Y-MM-DD';
                let yesterday = moment().subtract(1, 'days').startOf('day').format(format);
                let week = moment().subtract(7, 'days').startOf('day').format(format);
                let month = moment().subtract(30, 'days').startOf('day').format(format);
                let quarter = moment().subtract(90, 'days').startOf('day').format(format);
                let year = moment().subtract(365, 'days').startOf('day').format(format);
                let today = moment().startOf('day').format(format);
                switch (val.startDate ) {
                    case yesterday:
                        this.fastChoused = 'Вчера'
                        break;
                    case week:
                        this.fastChoused = 'Неделя'
                        break;
                    case month:
                        this.fastChoused = 'Месяц'
                        break;
                    case quarter:
                        this.fastChoused = 'Квартал'
                        break;
                    case year:
                        this.fastChoused = 'Год'
                        break;
                    case today:
                        this.fastChoused = 'Сегодня' 
                        break;
                    default:
                        this.fastChoused = '' 
                }
                this.reportCompleted = false;
            },
            deep: true,


        },
        compareDate: {
            handler(val) {
                if (!val.startDate) {
                    val.startDate = null;
                }
                if (!val.endDate) {
                    val.endDate = null;
                }
                if ((val.startDate || val.endDate) && this.date.startDate && this.date.endDate) {
                    this.isOpenResult = true;
                }
                if (val.startDate) {
                    this.setCompareDate({
                        startDate: moment(val.startDate, 'Y-MM-DD').format('DD.MM.YY'),
                        endDate: moment(val.endDate, 'Y-MM-DD').format('DD.MM.YY'),
                    });
                } else {
                    this.setCompareDate({});
                }
                this.reportCompleted = false;
            },
            deep: true,
        },
        detailization(val) {
            this.setDetailization(val);
            this.reportCompleted = false;
        },
        optionsSelectByDateFiltered: {
            handler(val) {
                const detailization = Object.keys(this.detailization).length ? this.detailization : this.detailizationStore;
                if (!val.find(item => item.value === detailization.value)) {
                    this.detailization = {
                        ...val[0],
                    };
                }
            },
            deep: true,
        }
    },
    methods: {
        ...mapActions(['setCompareDate', 'setDate', 'setDetailization', 'setTonality', 'setRegion']),
        async cropImage(img, sourceY, sourceHeight) {
            const canvas = document.getElementById('image-crop');
            const context = canvas.getContext('2d');

            const sourceX = 0;
            const sourceWidth = img.width;
            const destWidth = sourceWidth;
            const destHeight = sourceHeight;
            canvas.width = destWidth;
            canvas.height = destHeight;
            const destX = 0;
            const destY = 0;

            context.drawImage(img, sourceX, sourceY, sourceWidth, sourceHeight, destX, destY, destWidth, destHeight);
            const blob = await toBlob(canvas);
            return await this.getImageFromUrl(URL.createObjectURL(blob));
        },
        async createPDF(imgs, crop) {
            const doc = new jsPDF({
                format: 'a4',
                unit: 'px',
                hotfixes: ['px_scaling'],
            });

            const fn = 'report_' + moment().format('DD.MM.YY_HH:mm:ss');
            const width = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
            const margin = 48 * width / imgs[0].width;
            const padding = 1.9 * pageHeight / 29.7;
            let currentY = padding;
            for (let i = 0; i < imgs.length; i++) {
                const img = imgs[i];
                const height = img.height * width / img.width;
                if (crop[i]) {
                    let progress = 0;
                    const imgHeight = img.height * width / img.width;
                    while (progress < imgHeight) {
                        let cropped_img = img;
                        let destHeight = imgHeight - progress;
                        let emptySpace = pageHeight - margin - currentY;

                        if (emptySpace < 10) {
                            doc.addPage('a4', 'p');
                            currentY = padding;
                            emptySpace = pageHeight - margin - currentY;
                        }

                        if (progress > 0 || emptySpace < imgHeight - progress) {
                            destHeight = Math.min(destHeight, emptySpace);
                            cropped_img = await this.cropImage(img, progress * img.width / width, destHeight * img.width / width);
                        }

                        doc.addImage(cropped_img, 'JPEG', 0, currentY, width, destHeight, `${fn}_${i}_${Math.round(progress)}.jpg`);
                        currentY += destHeight;
                        progress += destHeight;
                    }
                    currentY += margin;
                } else {
                    if (pageHeight < height + padding + margin) {
                        doc.addPage([793.7066666666666, height + padding + margin], 'px');
                        currentY = padding;
                    } else if (pageHeight < currentY + height) {
                        doc.addPage('a4', 'p');
                        currentY = padding;
                    }
                    doc.addImage(img, 'JPEG', 0, currentY, width, height, `${fn}_${i}.jpg`);
                    currentY += height + margin;
                }
            }
            this._reportPdf = doc;
            this._reportFn = fn;
        },
        async getImageFromUrl(url) {
            const img = new Image();

            img.onError = function () {
                console.error('Cannot load image: "' + url + '"');
            };
            img.onloadPromise = () =>
                new Promise((res, rej) => {
                    img.onload = () => res(img);
                });
            img.src = url;
            await img.onloadPromise();
            return (this.reportImage = img);
        },
        isSafari() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('safari') != -1) {
                if (ua.indexOf('chrome') > -1) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        async makeScreenshot() {
            const fixSvgElements = (svgElements) => {
                svgElements.forEach(function(item) {
                    item.setAttribute("width", item.getBoundingClientRect().width);
                    item.setAttribute("height", item.getBoundingClientRect().height);
                    if (item.style.width) {
                        item.dataset.width = item.style.width;
                        item.style.width = null;
                    }
                    if (item.style.height) {
                        item.dataset.height = item.style.height;
                        item.style.height= null;
                    }
                });
            };
            const restoreSvgElements = (svgElements) => {
                svgElements.forEach(function(item) {
                    if (item.dataset.width) {
                        item.removeAttribute("width");
                        item.style.width = item.dataset.width;
                    }
                    if (item.dataset.height) {
                        item.removeAttribute("height");
                        item.style.height = item.dataset.height;
                    }
                });
            };
            fixSvgElements([...document.querySelectorAll('svg')].filter(node => !node.closest('.map')));
            const crop = [];

            const canvases = await Promise.all(
                [...document.querySelectorAll('#header + div:not(.screenshot-hidden) > *:not(.screenshot-hidden) > *:not(.screenshot-hidden)')]
                // .filter((node, i) => i ===0)
                .map(node => {
                    crop.push(!!node.querySelector('.table-wrap'));
                    return node;
                })
                .map(async node => await html2canvas(node, {
                    scale: 1,
                    // profile: true,
                    // useCORS: true,
                    // allowTaint: true
                }))
            );
            const main = document.getElementById('screenshot');
            main.textContent = '';
            // while (main.firstChild) {
            //     main.removeChild(main.firstChild);
            // }
            const blobs = await Promise.all(canvases.map(canvas => toBlob(canvas)));
            const reportImageUrls = blobs.map(blob => URL.createObjectURL(blob));
            const imgs = await Promise.all(reportImageUrls.map(reportImageUrl => this.getImageFromUrl(reportImageUrl)));
            await this.createPDF(imgs, crop);
            restoreSvgElements(document.querySelectorAll('svg'));
        },
        async completeReportHandler() {
            this.loading = true;
            this.$emit('prepare');
            await this.makeScreenshot();
            this.reportCompleted = true;
            this.loading = false;
        },
        downloadReportHandler() {
            if (this.reportCompleted) {
                this.showFileTypesDropdown = !this.showFileTypesDropdown;
            }
        },
        remove(idx) {
            if (idx === 0) {
                this.date = {
                    startDate: null,
                    endDate:null,
                };
            } else if (idx === 1) {
                this.compareDate = {
                    startDate: null,
                    endDate: null,
                };
                this.setCompareDate({});
            } else if (idx === null) {
                this.date = {
                    startDate: null,
                    endDate: null,
                };
                this.compareDate = {
                    startDate: null,
                    endDate: null,
                };
                this.setCompareDate({});
                this.isOpenResult = false;
            }
        },
    },
    components: {
        RadioInputGroup,
        Select,
        DatePicker,
        DatePickerResult,
        ReportDownload,
        Download,
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';
$margin-big: calc(60 / 1200 * 100%);

.form-wrap {
    //padding-bottom: 30px;

    .show-dropdown {
        display: none;
    }

    &.form-pb {
        padding-bottom: 0;
    }

    .form-inner {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-bottom: 20px;

        .form-field {
            margin-bottom: 22px;
        }
    }

    .form-field {
        &-title {
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: (20/15);
            color: $c-dark;
        }
    }

    .fastchoosed {
        width: calc(405 / 1200 * 100%);
        margin-right: $margin-big;
        min-width: 410px;
    }

    .date,
    .comparedate {
        min-width: 175px;
        margin-right: $margin-big;
        width: calc(211 / 1200 * 100%);
    }

    .detailization {
        margin-right: calc(100 / 1200 * 100%);
        //margin-top: 25px;
        width: calc(222 / 1200 * 100%);
    }

    .tonality,
    .region {
        min-width: 189px;
        width: calc(190 / 1200 * 100%);
    }

    .tonality {
        margin-right: 30px;
        //margin-right: calc(31 / 1200 * 100%);
    }

    .region {
        margin-right: $margin-big;
    }

    .btns-wrap {
        //padding-top: 25px;
        display: flex;
        justify-content: space-between;
        flex: auto;
    }

    .btn-wrap {
        position: relative;
        min-width: 162px;
        width: calc(204 / 458 * 100%);
        margin-right: 0;
    }

    .btn.btn-none-active {
        width: 100%;

        &.active {
            background-color: $c-grey4;
            color: $c-dark2;

            &:hover {
                background-color: $c-dark;
                color: $c-white;
            }
        }
    }
    .btn.btn-disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    .btn.btn-loading {
        background-color: $c-grey4;
        color: $c-dark;
        pointer-events: none;
        opacity: 0.5;
    }

    .btn {
        flex-grow: 1;
        width: calc(225 / 458 * 100%);
        margin-right: calc(30 / 459 * 100%);
    }
}

@media (min-width: 2320px) {
    section {
        margin-top: 60px;
    }
    .form-wrap {
        padding-bottom: 48px;

        .form {
            &-inner {
                margin-bottom: 61px;

                .form-field {
                    margin-bottom: 35px;
                }
            }
        }

        // .detailization {
        //     margin-top: 40px;
        // }

        // .btns-wrap {
        //     padding-top: 40px;
        // }

        .fastchoosed {
            width: calc(660 / 1932 * 100%);
        }

        .tonality {
            margin-right: calc(48 / 1932 * 100%);
        }

        .form-field {
            &-title {
                margin-bottom: 8px;
                font-size: 2.4rem;
                line-height: (32/24);
            }
        }

        // .btn {
        //     flex-grow: 1;
        //     width: calc(225 / 458 * 100%);
        //     margin-right: 30px;
        // }

        // .btn-none-active {
        //     min-width: 162px;
        //     width: calc(204 / 458 * 100%);
        //     margin-right: 0;
        // }
    }
}

@media (min-width: 1700px) and (max-width: 2319px) {
    .form-wrap {
        .fastchoosed {
            width: calc(478 / 1416 * 100%);
        }

        .tonality {
            margin-right: calc(30 / 1416 * 100%);
        }
    }
}

@media (max-width: 1440px) {
    .form-wrap {
        .btn {
            margin-right: 30px;
        }
    }
}

@media (max-width: 1306px) {
    .form-wrap {
        .form-inner {
            margin-bottom: 30px;
        }

        .detailization {
            width: calc(222 / 920 * 100%);
            margin-right: 0;
        }

        .btns-wrap {
            padding-top: 8px;
            margin-bottom: 0;
        }
    }
}

@media (max-width: 1108px) {
    $margin-big: calc(30 / 920 * 100%);
    .form-wrap {
        .region,
        .fastchoosed,
        .date,
        .comparedate {
            margin-right: $margin-big;
        }
    }
}

@media (max-width: 1013px) {
    section {
        margin-top: 40px;
    }
    .form-wrap {
        .region,
        .tonality,
        .detailization,
        .btns-wrap {
            order: 1;
        }

        .tonality,
        .detailization {
            margin-right: calc(30 / 920 * 100%);
        }

        .comparedate {
            margin-right: 0;
        }

        .date,
        .comparedate {
            flex-basis: 0;
            flex-grow: 1;
            width: 100%;
            max-width: 210px;
        }

        .fastchoosed {
            width: unset;
            min-width: unset;
        }
    }
}

@media (max-width: 918px) {
    .form-wrap {
        .form-field-title {
            font-size: 1.4rem;
        }

        .detailization {
            margin-top: 23px;
        }

        .date,
        .comparedate {
            min-width: 164px;
        }
    }
}

@media (max-width: 890px) {
    section {
        margin-top: 32px;
    }
    .form-wrap {
        .form-inner {
            margin-bottom: 20px;
        }

        .region,
        .tonality,
        .btns-wrap {
            order: 2;
        }

        // .btns-wrap {
        //     padding-top: 22px;
        // }

        .tonality,
        .detailization {
            margin-right: 0;
        }

        .region,
        .fastchoosed {
            margin-right: 20px;
        }

        .detailization {
            min-width: 200px;
            order: 0;
        }

        .date,
        .comparedate {
            width: calc(210 / 740 * 100%);
            margin-right: 20px;
            order: 1;
        }
    }
}

@media (max-width: 620px) {
    section {
        margin-top: 22px;
    }
    .form-wrap {
        padding-bottom: 22px;

        .detailization {
            margin-top: 11px;
            order: 2;
        }

        .form-inner .form-field {
            margin-bottom: 12px;
            width: 100%;
            margin-right: 0 !important;
        }

        .date,
        .comparedate {
            max-width: unset;
            flex-basis: unset;
        }

        .btns-wrap {
            margin-bottom: 0;
            padding-top: 10px;
            flex-wrap: wrap;
        }

        .btn {
            width: 100%;
            min-width: 145px;
            height: 38px;
            font-size: 1.3rem;
            margin-right: 0;

            &-icon {
                width: 18px;
                margin-right: 6px;
            }
        }

        .btn-wrap {
            width: 100%;
            margin-top: 12px;
        }
    }
}
</style>
