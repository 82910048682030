<template>
    <radial-progress-bar
        :diameter="diameter"
        :completed-steps="completedSteps"
        :total-steps="totalSteps"
        :inner-stroke-width="innerStrokeWidth"
        :stroke-width="strokeWidth"
        :start-color="startColor"
        :stop-color="stopColor"
        :inner-stroke-color="innerStrokeColor"
        :animate-speed="animateSpeed"
    >
        <Pause
            :height="pauseIconStyles.height"
            :width="pauseIconStyles.width"
            :rectWidth="pauseIconStyles.rectWidth"
            v-if="live"
            class="live"
            @toggleLive="$emit('toggleLive')"
        />
        <Play
            :height="playIconStyles.height"
            :width="playIconStyles.width"
            v-if="!live"
            class="live"
            @toggleLive="$emit('toggleLive')"
        />
    </radial-progress-bar>
</template>

<script>
import Pause from '@/components/icons/Pause';
import Play from '@/components/icons/Play';
import RadialProgressBar from 'vue-radial-progress';
import { primaryColor, radialStrokeColor } from '@/styles/vars.scss';
import {mapActions, mapState} from 'vuex';

export default {
    name: 'RadialProgress',
    props: {
        live: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            timer: null,
            pauseIconStyles: {
                height: 19.9726,
                rectWidth: 2.99589,
                width: 11,
            },
            playIconStyles: {
                width: 16,
                height: 17,
            },
            innerStrokeColor: radialStrokeColor,
            startColor: primaryColor,
            stopColor: primaryColor,
            completedSteps: 0,
            totalSteps: 12,
            innerStrokeWidth: 3,
            strokeWidth: 3,
            diameter: 44,
            animateSpeed: 1000,
            //isClockwise: false,
            reRequest: null,
        };
    },
    computed: mapState(['windowWidth']),
    mounted() {
        this.setSizes();
        this.startInterval();
    },
    beforeDestroy() {
        clearInterval(this.timer);
        // clearInterval(this.reRequest);
    },
    watch: {
        live() {
            if (!this.live) {
                clearInterval(this.timer);
                //this.animateSpeed = 0
            } else {
                this.startInterval();
            }
        },
        windowWidth() {
            this.setSizes();
        },
    },
    methods: {
        ...mapActions(['setProgressTime']),
        startInterval() {
            this.timer = setInterval(() => {
                if (!this.animateSpeed) {
                    this.animateSpeed = 1000;
                }

                if (this.completedSteps === this.totalSteps) {
                    this.animateSpeed = 0;
                    this.completedSteps = -1;
                    this.setProgressTime(Date.now());
                }

                this.completedSteps++;
            }, 1000);
            // this.reRequest = setInterval(() => this.setProgressTime(Date.now()), 10000);
        },
        setSizes() {
            if (this.windowWidth <= 768) {
                this.diameter = 26;
                this.strokeWidth = 1;
                this.innerStrokeWidth = 1;
                this.pauseIconStyles = {
                    height: 19.9726,
                    rectWidth: 2.99589,
                    width: 11,
                };
                this.playIconStyles = {
                    width: 16,
                    height: 17,
                };
            } else if (this.windowWidth <= 992) {
                this.diameter = 32;
                this.strokeWidth = 2;
                this.innerStrokeWidth = 2;
                this.pauseIconStyles = {
                    height: 19.9726,
                    rectWidth: 2.99589,
                    width: 11,
                };
                this.playIconStyles = {
                    width: 16,
                    height: 17,
                };
            } else if (this.windowWidth <= 1200) {
                this.diameter = 41;
                this.strokeWidth = 2;
                this.innerStrokeWidth = 2;
                this.pauseIconStyles = {
                    height: 19.9726,
                    rectWidth: 2.99589,
                    width: 11,
                };
                this.playIconStyles = {
                    width: 16,
                    height: 17,
                };
            } else if (this.windowWidth < 2320) {
                this.innerStrokeWidth = 3;
                this.strokeWidth = 3;
                this.diameter = 44
                this.pauseIconStyles = {
                    height: 19.9726,
                    rectWidth: 2.99589,
                    width: 11,
                };
                this.playIconStyles = {
                    width: 16,
                    height: 17,
                };
            } else {
                this.diameter = 66;
                this.strokeWidth = 4.61;
                this.innerStrokeWidth = 4.61;
                this.pauseIconStyles = {
                    height: 30,
                    width: 15.38,
                    rectWidth: 4.61,
                };
                this.playIconStyles = {
                    width: 24,
                    height: 27,
                };
            }
        },
    },
    components: {
        Pause,
        Play,
        RadialProgressBar,
    },
};
</script>

<style scoped lang="scss">
.live {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 1px;
}

// @media (min-width: 2320px) {
// }

@media (max-width: 1200px) {
    .live {
        width: 9px;
    }
}

@media (max-width: 992px) {
    .live {
        width: 7px;
    }
}

@media (max-width: 768px) {
    .live {
        width: 6px;
        margin-right: 0;
    }
}
</style>
