<template>
    <div v-if="Math.round(value) !== 0" @mouseover="$emit('mouseover', $event)" @mouseleave="$emit('mouseleave', $event)">
        <div class="bar" :style="barStyles">
            <div class="top" :style="{backgroundColor: getColor(value, colorsTop)}"/>
            <div class="left" :style="{backgroundColor: getColor(value, colorsLeft)}"/>
        </div>
        <div class="value-wrap" :style="{bottom: Math.max(quantity * barMaxHeight / 100 - 8, 30) + 'px'}">
            <div class="value">{{quantity}}</div>
        </div>
        <div class="diff-wrap" v-if="value2 !== null"
             :style="diffStyles">
            <div class="diff">{{ Math.round((value - value2) * 10) / 10 | format_number }} п.п.</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Bar",
    props: {
        value: {
            type: Number,
            default: 0,
        },
        quantity: {
            type: Number,
            default: 0,
        },
        value2: {
            type: Number,
            default: null,
        },
        barStyle: {
            type: Object,
            default: () => ({}),
        }
    },
    data() {
        return {
            colors: {
                0: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                10: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                30: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                40: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                50: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                70: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                80: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                90: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
                100: {
                    r: 152,
                    g: 40,
                    b: 171,
                },
            },
            colorsTop: {
                0: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                10: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                30: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                40: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                50: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                70: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                80: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                90: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
                100: {
                    r: 195,
                    g: 110,
                    b: 209,
                },
            },
            colorsLeft: {
                0: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                10: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                30: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                40: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                50: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                70: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                80: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                90: {
                    r: 115,
                    g: 25,
                    b: 130,
                },
                100: {
                    r: 115,
                    g: 25,
                    b: 130,
                }           
        }
            
        }
    },
    methods: {
        getColor(val, colors = this.colors, mod = 0) {
            let prev = -Infinity;
            let next = Infinity;
            for (let v in colors) {
                prev = Math.max(prev, val >= Number(v) ? Number(v) : prev);
                next = Math.min(next, val <= Number(v) ? Number(v) : next);
            }
            let r, g, b;
            if (val === prev) {
                r = colors[prev].r;
                g = colors[prev].g;
                b = colors[prev].b;
            } else if (val === next) {
                r = colors[next].r;
                g = colors[next].g;
                b = colors[next].b;
            } else {
                const len = next - prev;
                r = colors[prev].r + ((colors[next].r - colors[prev].r) * (val - prev)) / len;
                g = colors[prev].g + ((colors[next].g - colors[prev].g) * (val - prev)) / len;
                b = colors[prev].b + ((colors[next].b - colors[prev].b) * (val - prev)) / len;
            }
            r = Math.round(r);
            g = Math.round(g);
            b = Math.round(b);
            return `rgba(${r}, ${g}, ${b}, ${1 - mod / 100})`;
        }
    },
    computed: {
        ...mapGetters([ 'isMobile', 'windowWidth' ]),
        barMaxHeight() {
            return this.windowWidth >= 2320 ? 226 : this.isMobile ? 97 : 140;
        },
        barStyles() {
            return {
                ...this.barStyle,
                height: this.quantity * this.barMaxHeight / 100 + 'px',
                backgroundColor: this.getColor(this.value)
            }
        },
        diffStyles() {
            return {
                bottom: Math.max(this.quantity * this.barMaxHeight / 100 - 5, this.barMaxHeight * 48 / 140 ) + 'px',
                color: this.value <= this.value2 ? '#000' : '#731982',
            }
        }
    }
}
</script>

<style lang="scss" scoped>

/* ---------------------
  Vertical Bars
----------------------*/

.bar {
    //float: left;
    //position: absolute;
    bottom: 0;
    z-index: 99;
    height: 98px;
    width: 18px;
    /*margin: 0 40px;*/
    background-color: rgba(107, 255, 49, 1);
    transform: translateX(56%) skewY(-29deg);
}

.left {
    content: "";
    position: absolute;
    right: 100%;
    bottom: 9px;
    height: 100%;
    width: 18px;
    background-color: rgba(107, 255, 49, .65);
    transform: skewY(45deg);

    &-bg {
        background-color: #000;
    }
}

.top {
    content: "";
    position: absolute;
    bottom: 100%;
    left: -9px;
    width: 18px;
    height: 18px;
    background-color: rgba(107, 255, 49, .85);
    transform: skewX(45deg);

    &-bg {
        background-color: #fff;
    }
}

.value-wrap {
    position: absolute;
    z-index: 100;
    font-weight: 600;
    font-size: 12px;
    right: 50%;
    bottom: 30px;
    top: auto;
    white-space: nowrap;
    transform: translateX(18px) rotate(-90deg);
    transform-origin: 100% 100%;
}

.value {
    color: white;
    transform: skew(20deg);
}

.diff-wrap {
    position: absolute;
    font-weight: 400;
    font-size: 12px;
    right: 50%;
    bottom: 44px;
    top: auto;
    transform: translateX(-1px) rotate(-90deg);
    transform-origin: 100% 100%;
    white-space: nowrap;
}

.diff {
    /*color: #FFF;*/
    transform: skew(-33deg);
}


@media (max-width: 768px) {
    .bar {
        width: 12px;
    }

    .left {
        bottom: 6px;
        width: 12px;
    }

    .top {
        left: -6px;
        width: 12px;
        height: 12px
    }

    .value-wrap {
        transform: translateX(12px) rotate(-90deg);
        font-size: 7px;
    }

    .diff-wrap {
        transform: translateX(-1px) rotate(-90deg);
        font-size: 7px;
    }
}

@media (min-width: 2320px) {
    .bar {
        width: 30px;
    }

    .left {
        bottom: 15px;
        width: 30px;
    }

    .top {
        left: -15px;
        width: 30px;
        height: 30px
    }

    .value-wrap {
        transform: translateX(30px) rotate(-90deg);
        font-size: 19px;
    }

    .diff-wrap {
        transform: translateX(-1px) rotate(-90deg);
        font-size: 19px;
    }
}
</style>
