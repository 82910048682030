import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import axios from 'axios';

import widgets from './widgets';
import topPress from './topPress';
import topNegativeCategories from './topNegativeCategories';
import topNegativeSources from './topNegativeSources';
import pressMentions from './pressMentions';
import socialQuantityAndCoverage from './socialQuantityAndCoverage';
import social from './social';
import socialProblemDistricts from './socialProblemDistricts';
import socialRiskyMentions from './socialRiskyMentions';
import socialReputationMatrix from './socialReputationMatrix';
import socialProblemMap from './socialProblemMap';
import modalMessages from './modalMessages';
import infoFeed from './infoFeed';

const format = 'DD.MM.YY';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        resizing: true,
        windowWidth: null,
        mobileBreakpoint: 768,
        detailization: {
            title: 'Детализация по неделям',
            value: 'week',
        },
        compareDate: {},
        date: {
            startDate: moment()
                .subtract(1, 'months')
                .format(format),
            endDate: moment().format(format),
        },
        dateUpdated: false,
        defaultDate: {
            startDate: moment()
                .subtract(1, 'months')
                .format(format),
            endDate: moment().format(format),
        },
        '4KBreakpoint': 2320,
        progressTime: 0,
        tonality: null,
        region: null,
    },
    getters: {
        resizing(state) {
            return state.resizing;
        },
        windowWidth(state) {
            return state.windowWidth;
        },
        isMobile(state) {
            return state.windowWidth ? state.windowWidth <= state.mobileBreakpoint : null;
        },
        is4K(state) {
            return state.windowWidth ? state.windowWidth >= state['4KBreakpoint'] : null;
        },
        horizonBarChartMediaPoints(state) {
            if (state.windowWidth !== null) {
                if (state.windowWidth <= state.mobileBreakpoint) {
                    return 'MOBILE';
                } else if (state.windowWidth <= 1000) {
                    return 1000;
                } else if (state.windowWidth <= 1300) {
                    return 1300;
                } else if (state.windowWidth < 2320) {
                    return 'DESKTOP';
                } else {
                    return '4K';
                }
            } else {
                return null;
            }
        },
        compareDate(state) {
            if (!state.date.startDate) {
                return {};
            }
            return state.compareDate;
        },
        date(state) {
            if (!state.date.startDate) {
                return state.compareDate;
            }
            return state.date;
        },
        detailization(state) {
            return state.detailization;
        },
        progressTime(state) {
            return state.progressTime;
        },
        tonality(state) {
            return state.tonality;
        },
        user(state) {
            return state.user;
        },
        userAccessSmi(state) {
            return state.user.id ? state.user.rights.smi : null;
        },
        userAccessSocmedia(state) {
            return state.user.id ? state.user.rights.socmedia : null;
        },
        regions(state) {
            if (!state.user.id) {
                return [];
            }
            if (state.user.branchNames.length) {
                return state.user.branchNames;
            } else {
                return ['Волжский', 'Кавказский', 'Московский', 'Северо-Западный', 'Сибирский', 'Уральский', 'Центральный', 'Южный'];
            }
        },
        region(state) {
            return state.region;
        }
    },
    mutations: {
        setWindowWidth(state, val) {
            state.windowWidth = val;
        },
        setCompareDate(state, val) {
            state.compareDate = { ...val };
        },
        setDate(state, val) {
            state.date = { ...val };
            state.dateUpdated = true;
        },
        setDetailization(state, val) {
            state.detailization = { ...val };
        },
        setResizing(state, val) {
            state.resizing = val;
        },
        setProgressTime(state, val) {
            state.progressTime = val;
        },
        setTonality(state, val) {
            state.tonality = val;
        },
        setUser(state, val) {
            state.user = val;
        },
        setRegion(state, val) {
            state.region = val;
        },
    },
    actions: {
        setWindowWidth(context, val) {
            context.commit('setWindowWidth', val);
        },
        setCompareDate(context, val) {
            context.commit('setCompareDate', val);
        },
        setDate(context, val) {
            context.commit('setDate', val);
        },
        setTonality(context, val) {
            context.commit('setTonality', val);
        },
        setDetailization(context, val) {
            context.commit('setDetailization', val);
        },
        setResizing(context, val) {
            context.commit('setResizing', val);
        },
        setProgressTime(context, val) {
            context.commit('setProgressTime', val);
        },
        setRegion(context, val) {
            context.commit('setRegion', val);
        },
        async fetchUser({commit}) {
            try {
                const res = await axios.post('/site/user');
                commit('setUser', res.data);
            } catch (e) {
                console.error(e);
                try {
                    await axios.post('/site/logout-token');
                } catch (e) {
                    console.error(e);
                }
               window.location.href = process.env.VUE_APP_ADMIN_URL;
            }
        }
    },
    modules: {
        widgets,
        topPress,
        topNegativeCategories,
        topNegativeSources,
        pressMentions,
        socialQuantityAndCoverage,
        social,
        socialProblemDistricts,
        socialRiskyMentions,
        socialReputationMatrix,
        socialProblemMap,
        modalMessages,
        infoFeed,
    },
});
