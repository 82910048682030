<template>
    <path
            :index='index'
            :d='path'
            class='state'
            @mouseover='isActive = true'
            @mouseout='isActive = false'
            @click="showTitleDialog(region)"
            ref="el"
            :style="pathInlineStyle"
            :fill="pathStyle.fill"
            :stroke="pathStyle.stroke"
            :class='{ [pathClass]: true, regionActive: isActive}'/>
</template>

<script>
export default {
    props: {
        region: Object,
        path: String,
        index: Number,
        pathStyle: Object,
        pathClass: String,
        pathInlineStyle: Object,
    },
    data() {
        return {
            isActive: false
        };
    },
    mounted() {
        this.updatePosition();
    },
    watch: {
        path() {
            this.updatePosition();
        },
        pathStyle() {
            this.updatePosition();
        },
        pathClass() {
            this.updatePosition();
        },
    },
    methods: {
        updatePosition() {
            this.$emit('pathEl', this.$refs.el);
        },
        showTitleDialog(region) {
            // console.log(region.name, region.fed_okrug);
            // this.$modal.show('dialog', {
            //   title: region.fed_okrug  + '<br/><br/>' + region.name,
            //   text: 'Городское население: ' + region.city_population + ' человек <br/>' +
            //        'Сельское население: ' + region.country_population + ' человек <br/>' +
            //        'Естественный прирост населения: ' + region.country_natural_increase + ' человек <br/>' +
            //        'Среднаяя заработная плата: ' + region.average_nominal_wage + ' рублей <br/>' +
            //        'Средние потребительские цены: ' + region.average_consumer_rate + ' рублей <br/>'
            // })
        },
    }
};
</script>

<style>
</style>
