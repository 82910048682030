import { Line, mixins, generateChart } from 'vue-chartjs';
import { mergeDeep } from '../../helpers';
import Chart from 'chart.js';
import { mapGetters } from 'vuex';
import { primaryColor, darkColor2, greyColor6, greyColor } from '@/styles/vars.scss';

const { reactiveProp } = mixins;

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function(ease) {
        if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
            let yAxesId = 'y-axis-0';
            let xAxesId = 'x-axis-0';
            if (typeof this.chart.scales[yAxesId] === 'undefined') {
                for (const id in this.chart.scales) {
                    if (this.chart.scales.hasOwnProperty(id) && id !== xAxesId) {
                        yAxesId = id;
                        break;
                    }
                }
            }

            let activePoint = this.chart.tooltip._active[0],
                ctx = this.chart.ctx,
                x = activePoint.tooltipPosition().x,
                topY = this.chart.scales[yAxesId].top,
                bottomY = this.chart.scales[yAxesId].bottom;

            // draw line
            //ctx.globalCompositeOperation = 'source-out'
            //ctx.globalAlpha = 10
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.beginPath();
            ctx.moveTo(x, topY);
            ctx.lineTo(x, bottomY);
            ctx.lineWidth = 1;
            ctx.strokeStyle = greyColor;
            ctx.stroke();
            ctx.restore();
        }
        Chart.controllers.line.prototype.draw.call(this, ease);
    },
});
const LineWithLine = generateChart('LineWithLine', 'LineWithLine');

Chart.Tooltip.positioners.top = function(elements, eventPosition) {
    if (!elements.length) return {};
    const x = elements[0]._model.x + elements[0]._chart.options.tooltipPadding;
    const yPaddingTop = elements[0]._chart.options.layout.padding.top;

    return {
        x,
        y: yPaddingTop,
    };
};

const customTooltips = function(tooltipModel) {
    const messages1 = this._data.options.messages1;
    const messages2 = this._data.options.messages2;
    const yAxisLabels = this._data.options.yAxisLabels;

    // Tooltip Element
    let tooltipEl = document.getElementById('line-tooltip');
    const removeThisTooltip = () => {
        window.removeEventListener('resize', removeThisTooltip);
        tooltipEl.remove();
    };
    window.addEventListener('resize', removeThisTooltip);
    // Create element on first render
    if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'line-tooltip';
        tooltipEl.innerHTML = '<div class="line-tooltip-inner"></div>';
        document.body.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = 0;
        tooltipEl.style.display = 'none';
        return;
    }
    // Set caret Position
    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
        tooltipEl.classList.add('no-transform');
    }

    // Set Text
    if (tooltipModel.body) {
        let bodyLines = tooltipModel.dataPoints.map((bodyItem, i) => {
            const title = bodyItem.xLabel[i % 2] ? bodyItem.xLabel[i % 2] : bodyItem.xLabel[(i + 1) % 2];

            let label = '';
            if (tooltipModel.body[bodyItem.datasetIndex % 2] && tooltipModel.body[bodyItem.datasetIndex % 2].lines.length) {
                label = tooltipModel.body[bodyItem.datasetIndex % 2].lines[0].split(': ')[0];
            }
            if (title) {
                return {
                    id: bodyItem.datasetIndex % 2,
                    color: this.chartData.datasets[i].borderColor,
                    label,
                    value: bodyItem.value,
                    title,
                };
            }
        });
        if (yAxisLabels) {
            bodyLines = bodyLines.sort((a, b) => (a.label > b.label ? -1 : 1));
        }

        let innerHtml = '';
        let isTitleRendered = false;
        let isMessage1HaveRendered = false;
        let isMessage2HaveRendered = false;

        bodyLines.forEach((body, i) => {
            if (body && messages1[body.title] && !isMessage1HaveRendered && body.id === 0) {
                innerHtml += "<div class='line-tooltip-item'>";
                innerHtml += "<div class='line-tooltip-title'>" + body.title + '</div>';

                innerHtml += `<div  class='line-tooltip-message'>
                                        ${messages1[body.title]}
                                    </div>`;
                innerHtml += '</div>';
                isMessage1HaveRendered = true;
            }
            if (body && messages2[body.title] && !isMessage2HaveRendered && body.id === 1) {
                innerHtml += "<div class='line-tooltip-item'>";
                innerHtml += "<div class='line-tooltip-title'>" + body.title + '</div>';

                innerHtml += `<div  class='line-tooltip-message'>
                							${messages2[body.title]}
                						</div>`;
                innerHtml += '</div>';
                isMessage2HaveRendered = true;
            }
        });

        bodyLines.forEach((body, i) => {
            if (body && typeof bodyLines === 'object' && yAxisLabels) {
                if (!isTitleRendered) {
                    isTitleRendered = true;

                    innerHtml += "<div class='line-tooltip-item space-between'>";

                    innerHtml += "<div class='line-tooltip-title'>" + body.title + '</div>';

                    innerHtml += `<div class='line-tooltip-body' style='justify-content: space-between'>
													<div class='line-tooltip-label'>
														<span class='line-tooltip-dot' style='background-color: ${body.color}'></span>
														<span>${yAxisLabels[body.label]}</span>
													</div>
													<span>${body.value}</span>
											</div>`;
                } else {
                    isTitleRendered = false;
                    innerHtml += `<div class='line-tooltip-body' style='justify-content: space-between;'>
													<div class='line-tooltip-label'>
														<span class='line-tooltip-dot' style='background-color: ${body.color}'></span>
														<span>${yAxisLabels[body.label]}</span>
													</div>
													<span>${body.value}</span>
											</div>`;
                    innerHtml += '</div>';
                }
            } else if (body) {
                innerHtml += "<div class='line-tooltip-item'>";

                innerHtml += "<div class='line-tooltip-title'>" + body.title + '</div>';

                innerHtml += `<div class='line-tooltip-body'>
											<span class='line-tooltip-dot' style='background-color: ${body.color}'></span>
											<span>${body.value} публикаций за сегодня</span>
										</div>`;
                innerHtml += '</div>';
            }
        });

        const tableRoot = tooltipEl.querySelector('.line-tooltip-inner');
        tableRoot.innerHTML = innerHtml;
    }

    // `this` will be the overall tooltip
    const position = this._data._chart.canvas.getBoundingClientRect();

    let posLeft = position.left + window.pageXOffset + tooltipModel.caretX;
    let posRight = false;
    if (this.windowWidth <= 768 && this.windowWidth <= posLeft + 120) {
        posRight = true;
    } else if (this.windowWidth > 768 && this.windowWidth <= posLeft + 185) {
        posRight = true;
    }

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.display = 'block';
    tooltipEl.style.position = 'absolute';
    if (posRight) {
        tooltipEl.style.right = position.right - tooltipModel.caretX + this.options.tooltipPadding * 2 + 'px';
        tooltipEl.style.left = 'unset';
    } else {
        tooltipEl.style.left = posLeft + 'px';
        tooltipEl.style.right = 'unset';
    }
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
};

export default {
    name: 'LineChart',
    extends: LineWithLine,
    mixins: [reactiveProp],
    computed: {
        ...mapGetters(['windowWidth', 'isMobile']),
    },
    props: {
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
        chartData: {
            type: Object,
        },
    },
    data() {
        return {
            _options: {
                layout: {
                    padding: {
                        left: 27,
                        right: 37,
                        top: 40,
                        bottom: 30,
                    },
                },
                hover: {
                    animationDuration: 0,
                },
                animation: {
                    duration: 1, // general animation time
                    onComplete: function() {
                        const chartInstance = this.chart;
                        let ctx = chartInstance.ctx;
                        const font = ctx.font.split(' ');
                        ctx.textAlign = 'center';
                        ctx.font = `bold ${this.options.pointFontSize}px ${font[font.length - 1]}`;
                        ctx.fillStyle = '#fff';

                        this.chart.config.data.datasets.forEach((dataset, i) => {
                            const meta = chartInstance.controller.getDatasetMeta(i);

                            let index = 0;
                            let indexes = [];
                            meta.data.forEach(function(p, i) {
                                if (p._model.radius > 2.5) {
                                    index++;
                                    indexes.push({y: p._model.y, i});
                                    // ctx.fillText(index, p._model.x, p._model.y + p._model.radius / 2);
                                }
                            });

                            indexes = indexes.sort((a, b) => a.y - b.y);
                            meta.data.forEach(function(p, i) {
                                if (p._model.radius > 2.5) {
                                    index = indexes.findIndex(item => item.i === i) + 1;
                                    ctx.fillText(index, p._model.x, p._model.y + p._model.radius / 2);
                                }
                            });
                        });
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                // scales: {
                //     xAxes: [
                //         {
                //             gridLines: {
                //                 display: false,
                //                 drawBorder: false,
                //             },
                //             ticks: {
                //                 padding: 12,
                //                 fontColor: darkColor2,
                //                 fontStyle: 500,
                //                 callback: (value, index, values) => (!this.isMobile || [values.length - 1, 1].includes(index) ? value : ''),
                //             },
                //         },
                //     ],
                // },
                tooltips: {
                    intersect: false,
                    position: 'top',
                    enabled: false,
                    custom: customTooltips.bind(this),
                    mode: 'index',
                    axis: 'x',
                },
            },
            options: {},
        };
    },
    mounted() {
        this.mergeOptions();
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartOptions: {
            handler(v) {
                this.mergeOptions();
                this.renderChart(this.chartData, this.options);
            },
            deep: true,
        },
    },
    methods: {
        mergeOptions() {
            this.options = mergeDeep({}, this.$data._options, this.chartOptions);
        },
    },
};
