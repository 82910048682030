<template>
    <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @mouseenter="color = '#28b93c'"
            @mouseleave="color = '#8E8F93'"
    >
        <path
                d="M1.00006 1.32881L12.6416 13.1657"
                :stroke="color"
                stroke-width="2"
        />
        <path
                d="M12.5001 1.43015L1.00005 13.5082"
                :stroke="color"
                stroke-width="2"
        />
    </svg>
</template>
<script>
export default {
    data() {
        return {
            color: '#8E8F93',
        }
    },
}
</script>