<template>
    <section class="section">
        <div class="container">
            <router-link
                :to="$url('/socnet-riskymentions')"
                class="section-subtitle"
            >Рисковые упоминания
            </router-link>
            <VerticalBarChartComponent
                :haveSelection="false"
                :labels="labels"
                :data="data"
                :data2="data2"
                :loaded="socialRiskyMentionsLoaded"
            />
        </div>
    </section>
</template>

<script>
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import {mapGetters} from "vuex";

export default {
    name: 'RiskyMentions',
    computed: {
        ...mapGetters([
            'compareDate',
            'socialRiskyMentions',
            'socialRiskyMentionsLoaded',
            'date',
            'region',
            'tonality',
            'detailization',
        ]),
        ...mapGetters({
            socialRiskyMentions2Global: "socialRiskyMentions2",
        }),
        socialRiskyMentions2() {
            return this.compareDate.startDate ? this.socialRiskyMentions2Global : {};
        },
        labels() {
          return this.socialRiskyMentions.map(item => item.name)
        },
        data() {
          return this.socialRiskyMentions.map(item => item.value)
        },
        data2() {
            return this.compareDate.startDate && this.compareDate.endDate ? this.socialRiskyMentions.map(item => this.socialRiskyMentions2[item.name] || 0) : [];
        },
    },
    components: {
        VerticalBarChartComponent,
    },
};
</script>

<style lang="scss" scoped>
$min-width: 821px;

.vertical-bar-chart {
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
}

@media (max-width: 988px) {
    .vertical-bar-chart {
        overflow-x: auto;
    }
}

:export {
    minWidth: $min-width;
}
</style>
