<template>
    <section
        id="smi"
        v-if="pressMentions.data.length"
        class=" line-chart-wrap"
    >
        <div class="container">
            <div class="section-title">СМИ</div>
            <router-link
                :to="$url('/smi-mentioning')"
                class="section-subtitle"
            >Динамика упоминаемости в СМИ
            </router-link>

            <LineChartComponent />
        </div>
    </section>
</template>

<script>
import LineChartComponent from '@/components/LineChartComponent';
import {mapGetters} from "vuex";

export default {
    name: 'Press',
    components: {
        LineChartComponent,
    },
    computed: {
        ...mapGetters([
            'pressMentions',
            'date',
            'compareDate',
            'tonality',
            'region',
            'detailization',
        ]),
    }
};
</script>

<style lang="scss">
@media (max-width: 768px) {
    .line-chart-wrap {
        padding-top: 0;
    }
}
</style>
