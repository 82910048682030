<template>
    <div class="soc-top-negative-publications" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: ТОП источников негативных публикаций</div>
            </div>
        </div>
        <Form
            ref="form"
            :comparedatePosBreakPoints="{620: 'center', 890: 'left', 1440: 'left', 10000: 'center'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'tonality', 'region', 'compareDate']"
            @download="$downloadReport(socialNegativeSourcesList, $event)"
        />
        <div>
            <div class="container">
                <ChooseChart
                    :activeChart="activeChart"
                    @click="toggleChart"
                />
                <VerticalBarChartComponent
                    v-if="activeChart === 'verticalBar'"
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                    :loaded="topNegativeSourcesLoaded"
                    v-model="sources"
                />
                <PieChartComponent
                    v-else
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                />
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                    style="margin-bottom: 0"
                >
                    <Posts
                        @click="postClick"
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :postsCompare="socialNegativeSourcesList2"
                        :posts="socialNegativeSourcesList"
                    />
                </div>
                <!-- <Pagination /> -->
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import PieChartComponent from '@/components/PieChartComponent';
import Pagination from '@/components/Pagination';
import ChooseChart from '@/components/ChooseChart';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SocNetTopNegativePublications',
    data() {
        return {
            orderData: {},
            activeChart: 'verticalBar',
            postHeaders: {
                name: { title: 'Название социаьной сети', class: 'td--text td--center', format_number: false, ordering: true },
                quantity: { title: 'Количество публикаций', class: '', format_number: true, ordering: true },
            },
            reportColumns: [ 'name', 'quantity' ],
            sources: [],
        };
    },
    mixins: [downloadReport],
    computed: {
        ...mapState(['detailization']),
        ...mapGetters([
            'date',
            'compareDate',
            'topNegativeSourcesLoaded',
            'topNegativeSources',
            'socialNegativeSourcesList',
            'progressTime',
            'tonality',
            'userAccessSocmedia',
            'region',
        ]),
        ...mapGetters({
            topNegativeSources2Global: "topNegativeSources2",
            socialNegativeSourcesList2Global: "socialNegativeSourcesList2",
        }),
        topNegativeSources2() {
            return this.compareDate.startDate ? this.topNegativeSources2Global : {};
        },
        socialNegativeSourcesList2() {
            return this.compareDate.startDate ? this.socialNegativeSourcesList2Global : [];
        },
        data() {
            return this.topNegativeSources.map(item => item.value);
        },
        data2() {
            return this.compareDate.startDate && this.compareDate.endDate ? this.topNegativeSources.map(item => this.topNegativeSources2[item.name] || 0) : [];
        },
        labels() {
            return this.topNegativeSources.map(item => item.name);
        },
        apiQueryParams() {
            return {
                date: this.date,
                compareDate: this.compareDate,
                detailization: this.detailization,
                progressTime: this.progressTime,
                tonality: this.tonality,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                progressTime: this.progressTime,
                tonality: this.tonality,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
            }
        },
        apiQueryParamsSources() {
            return {
                date: this.date,
                detailization: this.detailization,
                sources: this.sourcesToFilter,
                progressTime: this.progressTime,
                tonality: this.tonality,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsSourcesCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                sources: this.sourcesToFilter,
                progressTime: this.progressTime,
                tonality: this.tonality,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        sourcesToFilter() {
            return this.sources.length ? this.sources : [];
        }
    },
    watch: {
        apiQueryParams() {
            this.userAccessSocmedia && this.getTopNegativeSources(this.apiQueryParams);
        },
        apiQueryParamsCompare() {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.userAccessSocmedia && this.getTopNegativeSources2(this.apiQueryParamsCompare);
            }
        },
        apiQueryParamsSources() {
            this.userAccessSocmedia && this.getSocialNegativeSourcesList(this.apiQueryParamsSources);
        },
        apiQueryParamsSourcesCompare() {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.userAccessSocmedia && this.getSocialNegativeSourcesList2(this.apiQueryParamsSourcesCompare);
            }
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.getTopNegativeSources(this.apiQueryParams);
        this.getSocialNegativeSourcesList(this.apiQueryParamsSources);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getTopNegativeSources2(this.apiQueryParamsCompare);
            this.getSocialNegativeSourcesList2(this.apiQueryParamsSourcesCompare);
        }
    },
    methods: {
        ...mapActions([
            'getTopNegativeSources',
            'getTopNegativeSources2',
            'getSocialNegativeSourcesList',
            'getSocialNegativeSourcesList2',
            'getSocialModalMessages',
        ]),
        toggleChart() {
            if (this.activeChart === 'verticalBar') {
                this.activeChart = 'pie';
            } else {
                this.activeChart = 'verticalBar';
            }
            this.$refs.form.reportCompleted = false;
        },
        postClick(post) {
            this.getSocialModalMessages({
                source: post.name,
                ...this.apiQueryParams
            });
        }
    },
    components: {
        Form,
        Posts,
        Pagination,
        VerticalBarChartComponent,
        PieChartComponent,
        ChooseChart,
    },
};
</script>

<style lang="scss">
.soc-top-negative-publications {
    .form-field {
        &.comparedate .opensleft {
            transform: translateX(calc(50% + 52px));
        }
    }
}
@media (max-width: 1032px) and (min-width: 1014px) {
    .soc-top-negative-publications .form-field.comparedate .opensleft {
        transform: translateX(28%);
    }
}

@media (max-width: 969px) {
    .soc-top-negative-publications .form-field.comparedate .opensleft {
        transform: none;
    }
}
@media (max-width: 890px) {
    .soc-top-negative-publications {
        .form-field {
            &.tonality,
            &.region {
                min-width: 170px;
            }
            &.tonality,
            &.btns-wrap .btn {
                margin-right: 20px;
            }
        }
    }
}
@media (max-width: 620px) {
    .soc-top-negative-publications {
        .form-field {
            &.btns-wrap {
                max-width: unset;
                .btn {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
