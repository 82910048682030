<template>
    <div class="soc-top-negative-subjects" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: ТОП субъектов по негативу</div>
            </div>
        </div>
        <Form
            :comparedatePosBreakPoints="{620: 'center', 890: 'left', 1440: 'left', 10000: 'center'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'region', 'compareDate']"
            @download="$downloadReport($data._reportData, $event)"
            @prepare="$prepareReport('getSocialProblemDistrictMessages', 'apiQueryParamsTitles')"
        />
        <div>
            <div class="container">
                <!-- <div class="chart-icons">
                    <div class="chart-desc">Выберите вид отображения:</div>
                    <div :class="['chart-icon', activeChart === 'pie' ? 'active' : null]">
                        <PieChartIcon :strokeColor="activeChart === 'pie' ? '#28b93c' : '#878787'" />
                    </div>
                    <div :class="['chart-icon', activeChart === 'verticalBar' ? 'active' : null]">
                        <VerticalBarChartIcon :strokeColor="activeChart === 'verticalBar' ? '#28b93c' : '#878787'" />
                    </div>
                </div> -->
                <VerticalBarChartComponent
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                    v-model="titles"
                />
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                >
                    <Posts
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :posts="socialProblemDistrictMessages"
                    />
                </div>
                <Pagination
                        v-model="page"
                        :pages="socialProblemDistrictMessagesPages"
                />
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import Pagination from '@/components/Pagination';
import downloadReport from '@/mixins/downloadReport';
import prepareReport from '@/mixins/prepareReport';
import {mapGetters, mapActions} from "vuex";
// import VerticalBarChartIcon from '@/components/icons/VerticalBarChartIcon';
// import PieChartIcon from '@/components/icons/PieChartIcon';

export default {
    name: 'SocNetTopNegativeSubjects',
    data() {
        return {
            //activeChart: 'verticalBar',
            orderData: {},
            postHeaders: {
                district: { title: 'Название субъекта', class: 'td--text td--center', format_number: false, ordering: true },
                date: { title: 'Дата', class: 'td--date', format_number: false, ordering: true },
                title1: { title: 'Проблема классификатор 1', class: 'td--text', format_number: false, ordering: true },
                title2: { title: 'Проблема классификатор 2', class: 'td--text', format_number: false, ordering: true },
                description: { title: 'Описание', class: 'td--text', format_number: false, ordering: true },
                link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
            },
            reportColumns: [ 'district', 'date', 'title1', 'title2', 'description', 'link' ],
            titles: [],
            page: 1,
        };
    },
    components: {
        Form,
        Posts,
        Pagination,
        VerticalBarChartComponent,
        // VerticalBarChartIcon,
        // PieChartIcon,
    },
    mixins: [ downloadReport, prepareReport ],
    computed: {
        ...mapGetters([
            'date',
            'compareDate',
            'socialProblemDistricts',
            'socialProblemDistrictsMin',
            'socialProblemDistrictMessages',
            'socialProblemDistrictMessagesPages',
            'progressTime',
            'userAccessSocmedia',
            'region',
            'tonality',
        ]),
        ...mapGetters({
            socialProblemDistricts2Global: "socialProblemDistricts2",
            socialProblemDistrictsMin2Global: "socialProblemDistrictsMin2",
            socialProblemDistrictMessages2Global: "socialProblemDistrictMessages2",
        }),
        socialProblemDistricts2() {
            return this.compareDate.startDate ? this.socialProblemDistricts2Global : {};
        },
        socialProblemDistrictsMin2() {
            return this.compareDate.startDate ? this.socialProblemDistrictsMin2Global : {};
        },
        socialProblemDistrictMessages2() {
            return this.compareDate.startDate ? this.socialProblemDistrictMessages2Global : {};
        },
        spd () {
          return this.orderDir === -1 ? this.socialProblemDistricts : this.socialProblemDistrictsMin;
        },
        spd2 () {
          return this.orderDir === -1 ? this.socialProblemDistricts2 : this.socialProblemDistrictsMin2;
        },
        labels() {
            return this.spd.map(item => item.name);
        },
        data() {
            return this.spd.map(item => item.value);
        },
        data2() {
            return this.spd.map(item => this.spd2[item.name]);
        },
        orderDir() {
            return Number(this.$route.query.orderDir) || -1;
        },
        apiQueryParams() {
            return {
                date: this.date,
                orderdir: this.orderDir,
                limit: 10,
                percents: 0,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                // tonality: this.tonality,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                orderdir: this.orderDir,
                limit: 10,
                percents: 0,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                // tonality: this.tonality,
            }
        },
        apiQueryParamsTitles() {
            return {
                date: this.date,
                districts: this.titlesToFilter,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                // tonality: this.tonality,
                page: this.page,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsTitlesCompare() {
            return {
                date: this.compareDate,
                districts: this.titlesToFilter,
                progressTime: this.progressTime,
                userAccessSocmedia: this.userAccessSocmedia,
                region: this.region,
                // tonality: this.tonality,
                page: this.page,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        titlesToFilter() {
            return this.titles.length ? this.titles : undefined;
        }
    },
    watch: {
        apiQueryParams: {
            handler() {
                setTimeout(() => {
                    this.userAccessSocmedia && this.getSocialProblemDistricts(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getSocialProblemDistricts2(this.apiQueryParamsCompare);
                }
            },
            deep: true,
        },
        apiQueryParamsTitles: {
            handler() {
                setTimeout(() => {
                    this.userAccessSocmedia && this.getSocialProblemDistrictMessages(this.apiQueryParamsTitles);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsTitlesCompare: {
            handler() {
                if (this.compareDate.startDate && this.compareDate.endDate) {
                    this.userAccessSocmedia && this.getSocialProblemDistrictMessages2(this.apiQueryParamsTitlesCompare);
                }
            },
            deep: true,
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.getSocialProblemDistricts(this.apiQueryParams);
        this.getSocialProblemDistrictMessages(this.apiQueryParamsTitles);
        if (this.compareDate.startDate && this.compareDate.endDate) {
            this.getSocialProblemDistricts2(this.apiQueryParamsCompare);
            this.getSocialProblemDistrictMessages2(this.apiQueryParamsTitlesCompare);
        }
    },
    methods: {
        ...mapActions([
            'getSocialProblemDistricts',
            'getSocialProblemDistricts2',
            'getSocialProblemDistrictMessages',
            'getSocialProblemDistrictMessages2',
        ]),
    }
};
</script>

<style lang="scss">
.soc-top-negative-subjects {
    .form-field {
        &.comparedate .opensleft {
            transform: translateX(calc(50% + 52px));
        }
    }
}
@media (max-width: 1032px) and (min-width: 1014px) {
    .soc-top-negative-subjects .form-field.comparedate .opensleft {
        transform: translateX(28%);
    }
}

@media (max-width: 969px) {
    .soc-top-negative-subjects .form-field.comparedate .opensleft {
        transform: none;
    }
}
@media (max-width: 890px) {
    .soc-top-negative-subjects {
        .form-field {
            &.tonality,
            &.region {
                min-width: 170px;
            }
            &.tonality,
            &.btns-wrap .btn {
                margin-right: 20px;
            }
        }
    }
}
@media (max-width: 620px) {
    .soc-top-negative-subjects {
        .form-field {
            &.btns-wrap {
                max-width: unset;
                .btn {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>
