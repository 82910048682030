<template>
    <table class="table-condensed">
        <thead>
        <tr>
            <th class="prev available" @click="prevYearClick" tabindex="0"><span/></th>
            <th
                    v-if="showDropdowns"
                    :colspan="2"
                    class="month"
            >
                <div class="row mx-1">
                    <select v-model="month" class="monthselect col">
                        <option v-for="(m) in months" :key="m.value" :value="m.value + 1">{{m.label}}</option>
                    </select>
                    <input ref="yearSelect" type="number" v-model="year" @blur="checkYear" class="yearselect col" />
                </div>
            </th>
            <th v-else :colspan="2" class="month">{{year}}</th>
            <th class="next available" @click="nextYearClick" tabindex="0"><span/></th>
        </tr>
        </thead>
        <tbody>
        <tr />
        <tr
                v-for="(monthGroup, index) in monthsGrouped"
                :key="index"
        >
            <slot name="date-slot" v-for="(month, idx) in monthGroup">
                <td
                        :class="monthClass(month)"
                        @click="monthClicked(month)"
                        @mouseover="$emit('hoverDate', month)"
                        :key="idx"
                >
                    {{monthNamesShort[month.value]}}
                </td>
            </slot>
        </tr>
        </tbody>
    </table>
</template>
<script>
import Calendar from 'vue2-daterange-picker/src/components/Calendar'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    name: 'CalendarMonth',
    extends: Calendar,
    data() {
        return {
            monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
        }
    },
    methods: {
        monthClass (date) {
            let dt = new Date(this.year, date.value, 1);
            dt.setHours(0, 0, 0, 0);
            let start = new Date(this.start);
            start.setHours(0, 0, 0, 0);
            let end = new Date(this.end);
            end.setHours(0, 0, 0, 0);

            let classes = {
                // off: date.value + 1 !== this.month,
                active: dt.setHours(0, 0, 0, 0) == new Date(this.start).setHours(0, 0, 0, 0) || dt.setHours(0, 0, 0, 0) == new Date(this.end).setHours(0, 0, 0, 0),
                'in-range': dt >= start && dt <= end,
                'start-date': dt.getTime() === start.getTime(),
                'end-date': dt.getTime() === end.getTime(),
                disabled: (this.minDate && dt.getTime() < this.minDate.getTime())
                    || (this.maxDate && dt.getTime() > this.maxDate.getTime()),
            };
            return this.dateFormat ? this.dateFormat(classes, date) : classes
        },
        monthClicked(month) {
            this.$emit('dateClick', new Date(this.year, month.value, 1));
            this.$emit('dateClick', new Date(this.year, month.value + 1, 0))
        },
        prevYearClick() {
            this.year -= 1;
        },
        nextYearClick() {
            this.year += 1;
        },
    },
    computed: {
        monthsGrouped() {
            let i, j, monthsGrouped = [], chunk = 4;
            for (i=0,j=this.months.length; i<j; i+=chunk) {
                monthsGrouped.push(this.months.slice(i,i+chunk));
            }
            return monthsGrouped;
        }
    }
}
</script>
