<template>
    <div/>
</template>

<script>
export default {
    name: 'Logout',
    mounted() {
        localStorage.removeItem('token');
        localStorage.removeItem('sessionId');
        window.location.href = this.$adminUrl + 'site/logout';
    }
};
</script>
