<template>
    <div class="login-wrap">
        <div class="container login-modal-wrap">
            <div class="login-modal">
                <ForgotPassword
                        @toggleForgotPassword="close"
                        v-model="forgotPassword"
                        v-if="forgotPassword"
                />
                <Login
                        v-else
                        @toggleForgotPassword="toggleForgotPassword"
                        v-model="forgotPassword"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Login from '@/components/Login/Login.vue'
import ForgotPassword from '@/components/Login/ForgotPassword.vue'

export default {
    name: 'LoginPage',
    data: () => ({
        forgotPassword: false,
    }),
    watch: {
        forgotPassword() {
            // console.log('Forgot Password', this.forgotPassword)
        },
    },
    methods: {
        toggleForgotPassword(val = true) {
            this.forgotPassword = val
        },
        close() {
            if (this.forgotPassword) {
                this.forgotPassword = false
            }
        },
    },
    components: {
        Login,
        ForgotPassword,
    },
}
</script>

<style lang="scss">
@import '@/styles/vars.scss';
@import '@/styles/fonts.scss';

.login {
    &-modal {
        &-inner {
            padding: 38px 12px 64px 12px;
            max-width: 322px;
            width: 100%;
        }

        position: relative;
        background-color: #fff;
        display: flex;
        justify-content: center;
        box-shadow: $shadow-login;
        margin: 0 auto;
        width: 100%;
        color: $c-login-dark;
    }

    &-wrap {
        .login-modal-wrap {
            max-width: 474px;
            flex: auto;
        }

        font-family: 'Roboto';
        background-color: $c-login-dark2;
        padding: 20px 0;
        min-height: calc(100vh - 188px);
        display: flex;
        align-items: center;
    }

    &-caption {
        font-family: 'HelveticaNeueCyr';
        margin-bottom: 5px;
        font-size: 2.4rem;
        line-height: (29/24);
        font-weight: 700;
    }

    &-sub,
    &-sub .link {
        font-size: 1.3rem;
        line-height: (20/13);
        color: $c-login-dark;
    }

    &-sub {
        margin-bottom: 30px;
    }

    &-input {
        outline: none;
        padding: 12px 15px;
        color: $c-login-dark;
        font-size: 1.3rem;
        line-height: (20/13);
        border: 1px solid $c-login-grey;
        border-radius: 4px;
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        transition: $login-transition;

        &-error {
            .login-input {
                border-color: $c-primary;
            }
        }

        &-group {
            margin-bottom: 15px;
        }

        &-field {
            position: relative;
            margin-bottom: 30px;
            padding: 0 2px 0 3px;

            .login-input-title {
                transition: $login-transition;
                padding: 0 2px 0 3px;
                background-color: #fff;
                top: -5px;
                left: 13px;
                font-size: 0.9rem;
                line-height: 1;
                position: absolute;
                color: $c-primary;
                display: none;
            }

            &.login-input-error .login-input-title {
                display: inline-block;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &.focused {
                .login-input {
                    border-color: $c-login-primary;
                }

                .login-input-title {
                    display: inline-block;
                    color: $c-login-primary;
                }
            }
        }

        &-icon {
            cursor: pointer;
            position: absolute;
            right: 17px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &-btn {
        transition: $login-transition;
        outline: none;
        cursor: pointer;
        border: none;
        border-radius: 4px;
        width: 100%;
        color: $c-login-dark;
        background-color: $c-login-primary;
        padding: 14px 0;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: (20/15);

        &:hover {
            color: #fff;
            background-color: $c-login-dark;
        }

        &.none-active {
            cursor: default;
            color: $c-login-dark;
            background-color: $c-login-grey;
        }
    }

    &-error-message {
        position: absolute;
        left: 12px;
        bottom: 50px;
        font-size: 10px;
        line-height: 1;
        color: $c-primary;
    }
}

@media (max-width: 1200px) {
    .login-wrap {
        min-height: calc(100vh - 168px);
    }
}

@media (max-width: 992px) {
    .login-wrap {
        min-height: calc(100vh - 148px);

        .login-modal-inner {
            padding-bottom: 40px;
            padding-top: 30px;
        }

        .login-input-field {
            padding: 0;
        }

        .login-sub {
            margin-bottom: 20px;
            font-size: 1.2rem;
            line-height: (16/12);
        }

        .login-input-field {
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 768px) {
    .login-wrap {
        min-height: calc(100vh - 128px);

        .login-modal-inner {
            padding-bottom: 30px;
            padding-top: 20px;
        }

        .login-input-field {
            margin-bottom: 15px;
        }

        .login-checkbox {
            margin-right: 8px;
        }

        .login-checkbox-group,
        .login-forgot {
            font-size: 1.2rem;
        }

        .login-forgot-wrap {
            margin-bottom: 20px;
        }

        .login-btn {
            padding: 12px 0;
            font-size: 1.4rem;
        }

        .login-input {
            padding: 10px;
            font-size: 1.2rem;
        }

        .login-caption {
            font-size: 2rem;
        }
    }
}
</style>
