<template>
    <div id="header" class="header">
        <div class="container">
            <div class="header-inner">
                <div class="inner-left">
                    <router-link
                        to="/"
                        style="line-height: 0"
                    >
                        <Logo class="logo" />
                    </router-link>
                    <RadialProgress
                        class="radial-progress"
                        v-if="main && !login"
                        :live="live"
                        @toggleLive="toggleLive"
                        v-tooltip="'Работа в режиме реального времени'"
                    />
                </div>
                <div
                    v-if="!login"
                    class="inner-right"
                >
                    <Timer
                        v-if="main"
                        :live="live"
                    />
                    <a
                        :href="$adminUrl"
                        class="user-info"
                    >
                        <div class="user-logo">
                            <UserIcon />
                        </div>
                        <span class="user-name">{{ user.lastName }} {{ user.firstName ? user.firstName.substr(0, 1) + '.' : ''}}</span>
                    </a>
                    <div @click="logout" class="user-info">
                        <Logout class="user-logout" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {mapActions, mapGetters} from "vuex";

import Logo from '@/components/icons/Logo';
import Timer from '@/components/Timer';
import RadialProgress from '@/components/RadialProgress';
import UserIcon from '@/components/icons/User';
import Logout from '@/components/icons/Logout';

export default {
    name: 'Header',
    props: {
        main: {
            type: Boolean,
            default: false,
        },
        login: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            live: true,
            timer: null,
        };
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        toggleLive() {
            this.live = !this.live;
        },
        async logout() {
            try {
                // const res = await axios.post(this.$adminUrl + 'site/logout-token', {
                //     sessionId: localStorage.getItem('sessionId'),
                // });
                // if (res.data.success) {
                    localStorage.removeItem('token');
                    localStorage.removeItem('sessionId');
                    window.location.href = this.$adminUrl + 'site/logout';
                // }
            } catch (e) {
                console.error(e);
            }
        }
    },
    components: {
        Logo,
        Timer,
        RadialProgress,
        UserIcon,
        Logout,
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/vars.scss';

.header {
    .inner-left,
    .inner-right {
        display: flex;
        align-items: center;
    }
}

.logo {
    margin-right: 46px;
}

.radial-progress {
    margin-bottom: 2px;
}

.user {
    &-info {
        text-decoration: none;
        display: flex;
        align-items: center;
        padding-right: 4px;
        > * {
            cursor: pointer;
        }
        &:active {
            .user-name {
                color: $c-primary3;
            }
        }
    }

    &-logo {
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $c-white;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &-name {
        &:hover {
            color: $c-primary;
        }
        color: $c-white;
        margin-right: 18px;
        font-size: 1.4rem;
        line-height: (18/14);
    }
}

.skin-magnit {
    .user {
        &-logo {
            border: 1px solid #1f2020;
        }
        &-name {
            color: #1f2020;
            &:hover {
                color: $c-primary;
            }
        }
    }
}

@media (min-width: 2320px) {
    .header-inner {
        height: 119px;
    }
    .header .inner-left .logo {
        height: 51px;
        width: 238px;
        margin-right: 55px;
    }

    .user {
        &-logout {
            width: 44px;
            height: 44px;
        }
        &-logo {
            margin-right: 19px;
            width: 64.5px;
            height: 64.5px;
            svg {
                width: 50%;
                height: 50%;
            }
        }

        &-name {
            margin-right: 37px;
            font-size: 22px;
        }
    }
}

@media (max-width: 1200px) {
    .user {
        &-logo {
            margin-right: 10px;
            width: 35px;
            height: 35px;
        }

        &-name {
            margin-right: 14px;
            font-size: 1.3rem;
        }
    }
}

@media (max-width: 992px) {
    .user {
        &-logo {
            margin-right: 8px;
            width: 32px;
            height: 32px;
        }

        &-name {
            margin-right: 10px;
            font-size: 1.2rem;
        }

        &-logout {
            width: 26px;
        }
    }
}

@media (max-width: 768px) {
    .user {
        &-logo {
            margin-right: 6px;
            width: 28px;
            height: 28px;
        }

        &-name {
            display: none;
        }

        &-logout {
            width: 25px;
        }
    }
}
</style>
