<template>
    <table v-if="state === 'month'" class="table-condensed">
        <thead>
        <tr>
            <th class="prev available" @click="prevYearClick" tabindex="0"><span/></th>
            <th
                    v-if="showDropdowns"
                    :colspan="2"
                    class="month"
            >
                <div class="row mx-1">
                    <select v-model="month" class="monthselect col">
                        <option v-for="(m) in months" :key="m.value" :value="m.value + 1">{{m.label}}</option>
                    </select>
                    <input ref="yearSelect" type="number" v-model="year" @blur="checkYear" class="yearselect col" />
                </div>
            </th>
            <th v-else :colspan="2" @click="state = 'year'" class="month month--clickable">{{year}}</th>
            <th class="next available" @click="nextYearClick" tabindex="0"><span/></th>
        </tr>
        </thead>
        <tbody>
        <tr />
        <tr
                v-for="(monthGroup, index) in monthsGrouped"
                :key="index"
        >
            <slot name="date-slot" v-for="(month, idx) in monthGroup">
                <td
                        :class="monthClass(month)"
                        @click="monthClicked(month)"
                        @mouseover="$emit('hoverDate', month)"
                        :key="idx"
                >
                    {{monthNamesShort[month.value]}}
                </td>
            </slot>
        </tr>
        </tbody>
    </table>
    <table v-else-if="state === 'year'" class="table-condensed">
        <thead>
        <tr>
            <th class="prev available" @click="prevDecadeClick" tabindex="0"><span/></th>
            <th
                    v-if="showDropdowns"
                    :colspan="2"
                    class="month"
            >
                <div class="row mx-1">
                    <select v-model="year" class="monthselect col">
                        <option v-for="(y) in years" :key="y.value" :value="y.value + 1">{{y.label}}</option>
                    </select>
                    <input ref="yearSelect" type="number" v-model="year" @blur="checkYear" class="yearselect col" />
                </div>
            </th>
            <th v-else :colspan="2" class="month">{{decadeString}}</th>
            <th class="next available" @click="nextDecadeClick" tabindex="0"><span/></th>
        </tr>
        </thead>
        <tbody>
        <tr />
        <tr
                v-for="(yearGroup, index) in yearsGrouped"
                :key="index"
        >
            <slot name="date-slot" v-for="(year, idx) in yearGroup">
                <td
                        :class="{...yearClass(year), pzdc: true}"
                        @click="yearClicked(year)"
                        @mouseover="$emit('hoverDate', year)"
                        :key="idx"
                >
                    {{year}}
                </td>
            </slot>
        </tr>
        </tbody>
    </table>
    <table v-else-if="state === 'day'" class="table-condensed">
        <thead>
        <tr>
            <th class="prev available" @click="prevMonthClick" tabindex="0"><span/></th>
            <th
                    v-if="showDropdowns"
                    :colspan="showWeekNumbers ? 6 : 5"
                    class="month"
            >
                <div class="row mx-1">
                    <select v-model="month" class="monthselect col">
                        <option v-for="(m) in months" :key="m.value" :value="m.value + 1">{{m.label}}</option>
                    </select>
                    <input ref="yearSelect" type="number" v-model="year" @blur="checkYear" class="yearselect col" />
                </div>
            </th>
            <th v-else :colspan="showWeekNumbers ? 6 : 5" @click="state = 'month'" class="month month--clickable">{{monthName}} {{year}}</th>
            <th class="next available" @click="nextMonthClick" tabindex="0"><span/></th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <th v-if="showWeekNumbers" class="week">{{locale.weekLabel}}</th>
            <th v-for="weekDay in locale.daysOfWeek" :key="weekDay">{{weekDay}}</th>
        </tr>
        <tr
                v-for="(dateRow, index) in calendar"
                :key="index"
        >
            <td v-if="showWeekNumbers && (index%7 || index===0)" class="week">
                {{$dateUtil.weekNumber(dateRow[0])}}
            </td>
            <slot name="date-slot" v-for="(date, idx) in dateRow">
                <td
                        :class="dayClass(date)"
                        @click="$emit('dateClick', date)"
                        @mouseover="$emit('hoverDate', date)"
                        :key="idx"
                >
                    {{date.getDate()}}
                </td>
            </slot>
        </tr>
        </tbody>
    </table>
</template>
<script>
import Calendar from 'vue2-daterange-picker/src/components/Calendar'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    name: 'CalendarCustom',
    extends: Calendar,
    data() {
        return {
            state: 'day',
            monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            decade: {},
        }
    },
    watch: {
        state(state) {
            if (state === 'year') {
                const start = this.year - this.year % 20;
                this.decade = {
                    start,
                    end: start + 19,
                }
            }
        }
    },
    methods: {
        monthClass (date) {
            let dt = new Date(this.year, date.value, 1);
            dt.setHours(0, 0, 0, 0);
            let start = new Date(this.start);
            start.setHours(0, 0, 0, 0);
            let end = new Date(this.end);
            end.setHours(0, 0, 0, 0);

            let classes = {
                // off: date.value + 1 !== this.month,
                active: dt.setHours(0, 0, 0, 0) == new Date(this.start).setHours(0, 0, 0, 0) || dt.setHours(0, 0, 0, 0) == new Date(this.end).setHours(0, 0, 0, 0),
                'in-range': dt >= start && dt <= end,
                'start-date': dt.getTime() === start.getTime(),
                'end-date': dt.getTime() === end.getTime(),
                disabled: (this.minDate && dt.getTime() < this.minDate.getTime())
                    || (this.maxDate && dt.getTime() > this.maxDate.getTime()),
            };
            return this.dateFormat ? this.dateFormat(classes, date) : classes
        },
        yearClass (date) {
            let dt = new Date(date, 0, 1);
            dt.setHours(0, 0, 0, 0);
            let start = new Date(this.start);
            start.setHours(0, 0, 0, 0);
            let end = new Date(this.end);
            end.setHours(0, 0, 0, 0);

            let classes = {
                // off: date.value + 1 !== this.month,
                active: dt.setHours(0, 0, 0, 0) == new Date(this.start).setHours(0, 0, 0, 0) || dt.setHours(0, 0, 0, 0) == new Date(this.end).setHours(0, 0, 0, 0),
                'in-range': dt >= start && dt <= end,
                'start-date': dt.getTime() === start.getTime(),
                'end-date': dt.getTime() === end.getTime(),
                disabled: (this.minDate && dt.getTime() < this.minDate.getTime())
                    || (this.maxDate && dt.getTime() > this.maxDate.getTime()),
            };
            return classes
        },
        monthClicked(month) {
            this.month = month.value + 1;
            this.state = 'day';
        },
        yearClicked(year) {
            console.log('yearClicked', year);
            this.year = year;
            this.month = 1;
            setTimeout(() => {
                console.log(this.month, this.year)
            }, 200);
            this.state = 'month';
        },
        prevYearClick() {
            this.year -= 1;
        },
        nextYearClick() {
            this.year += 1;
        },
        prevDecadeClick() {
            if (this.minDate && new Date(this.decade.start, 0, 1) <= this.minDate) return;
            this.decade = {
                start: this.decade.start -= 20,
                end: this.decade.end -= 20,
            };
        },
        nextDecadeClick() {
            if (this.maxDate && new Date(this.decade.end, 0, 1) >= this.maxDate) return;
            this.decade = {
                start: this.decade.start += 20,
                end: this.decade.end += 20,
            };
        },
    },
    computed: {
        monthsGrouped() {
            let i, j, monthsGrouped = [], chunk = 4;
            for (i=0,j=this.months.length; i<j; i+=chunk) {
                monthsGrouped.push(this.months.slice(i,i+chunk));
            }
            return monthsGrouped;
        },
        years() {
            const years = [];

            for(let year = this.decade.start; year <= this.decade.end; year++) {
                years.push(year);
            }
            return years;
        },
        yearsGrouped() {
            let i, j, yearsGrouped = [], chunk = 4;
            for (i=0,j=this.years.length; i<j; i+=chunk) {
                yearsGrouped.push(this.years.slice(i,i+chunk));
            }
            return yearsGrouped;
        },
        decadeString() {
            return this.decade.start + '-' + this.decade.end;
        }
    }
}
</script>
