<template>
    <div
        class="line-chart"
        style="padding-bottom: 14px"
    >
        <Loading v-if="!lineChartData.labels || !lineChartData.labels.length" :loaded="socialQuantityAndCoverageLoaded"/>
        <LineChart
                v-else
            :styles="chartStyles"
            :chart-options="chartOptions"
            :chart-data="lineChartData"
        ></LineChart>
        <template v-if="lineChartData.labels && lineChartData.labels.length" v-for="(points, i) in visiblePoints">
            <div
                v-if="points.length"
                class="points-wrap"
                :key="i"
            >
                <div
                    v-for="(description, j) in points"
                    :class="{'point': true, 'point-grey': i === 1}"
                    :key="j"
                >
                    <div class="point-number">{{ j + 1 }}</div>
                    <div class="point-text">{{ description }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import LineChart from '@/components/Charts/LineChart';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
import { primaryColor, darkColor, greyColor, greyColor6, darkColor2 } from '@/styles/vars.scss';
import moment from 'moment';

export default {
    name: 'LineChart2Component',
    components: {
        LineChart,
        Loading,
    },
    watch: {
        isMobile(val, oldVal) {
            this.setStyles();
            this.setTicksStyles();
            this.fillData();
        },
        is4K(val, oldVal) {
            this.setStyles();
            this.setTicksStyles();
            this.fillData();
        },
        detailization() {
            this.setStyles();
            this.setTicksStyles();
            this.fillData();
        },
        socialQuantityAndCoverage: {
            handler() {
                this.setStyles();
                this.setTicksStyles();
                this.fillData();
            },
            deep: false,
        },
        socialQuantityAndCoverage2: {
            handler() {
                this.setStyles();
                this.setTicksStyles();
                this.fillData();
            },
            deep: false,
        },
        compareDate: {
            handler(val, oldVal) {
                this.setStyles();
                this.setTicksStyles();
                this.fillData();
            },
            deep: true,
        },
        date: {
            handler(val, oldVal) {
                this.setStyles();
                this.setTicksStyles();
                this.fillData();
            },
            deep: true,
        },
    },
    computed: {
        ...mapGetters(['isMobile', 'is4K', 'compareDate', 'date', 'detailization', 'socialQuantityAndCoverage', 'socialQuantityAndCoverageLoaded']),
        ...mapGetters({
            socialQuantityAndCoverage2Global: "socialQuantityAndCoverage2",
        }),
        socialQuantityAndCoverage2() {
            return this.compareDate.startDate ? this.socialQuantityAndCoverage2Global : {
                data: [],
                posts: [],
            };
        },
    },
    data() {
        return {
            borderWidth: 2,
            pointRadius: 10,
            lineChartData: {},
            pointsData: [],
            chartStyles: {
                height: 314 + 'px',
                paddingTop: 8 + 'px',
            },
            chartOptions: {
                messages1: {},
                messages2: {},
                pointFontSize: 14,
                tooltipPadding: 20,
                layout: {
                    padding: {
                        left: 27,
                        right: 37,
                        top: 40,
                        bottom: 30,
                    },
                },
                yAxisLabels: {
                    ['y-axis-1']: 'Охват',
                    ['y-axis-0']: 'Публикаций',
                },
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-0',
                            type: 'linear',
                            position: 'left',
                            //stacked: true,
                            gridLines: {
                                drawBorder: false,
                                color: greyColor6,
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 5,
                                fontColor: primaryColor,
                                fontStyle: 500,
                            },
                        },
                        {
                            id: 'y-axis-1',
                            type: 'linear',
                            position: 'right',
                            //stacked: true,
                            gridLines: {
                                display: false,
                                drawBorder: false,
                                color: greyColor6,
                            },
                            ticks: {
                                beginAtZero: true,
                                padding: 5,
                                fontColor: darkColor,
                                fontStyle: 500,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            id: 'xAxis1',
                            type: 'category',
                            position: 'bottom',
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontSize: 12,
                                padding: 12,
                                fontColor: darkColor2,
                                fontStyle: 500,
                                callback: (value, index, values) => {
                                    const is_last_index = index === values.length - 1 || !values[index + 1][0];
                                    return !this.isMobile || is_last_index || index === 1 ? value[0] : '';
                                },
                            },
                        },
                        {
                            id: 'xAxis2',
                            type: 'category',
                            position: 'top',
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                fontSize: 12,
                                padding: 12,
                                fontColor: darkColor2,
                                fontStyle: 500,
                                callback: (value, index, values) => {
                                    const is_last_index = index === values.length - 1 || !values[index + 1][1];
                                    return !this.isMobile || is_last_index || index === 1 ? value[1] : '';
                                },
                            },
                        },
                    ],
                },
            },
            pointStyles: {},
            pointStyles2: {},
            pointStyles3: {},
            pointStyles4: {},
            visiblePoints: [],
        };
    },
    mounted() {
        this.setStyles();
        this.fillData();
        this.setTicksStyles();
    },
    methods: {
        fillData() {
            if (!this.socialQuantityAndCoverage.data) {
                return;
            }
            const pr = this.pointRadius;
            const pointStyles = {
                borderColor: primaryColor,
                borderWidth: this.borderWidth,
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: primaryColor,
                pointBorderColor: 'transparent',
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                fill: false,
                label: 'y-axis-0',
                yAxisID: 'y-axis-0',
            };
            const pointStyles2 = {
                borderColor: darkColor,
                borderWidth: this.borderWidth,
                pointBackgroundColor: darkColor,
                pointHoverBackgroundColor: darkColor,
                pointBorderColor: darkColor,
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                fill: false,
                label: 'y-axis-1',
                yAxisID: 'y-axis-1',
            };
            const pointStyles3 = {
                borderColor: '#28b93c',
                borderWidth: this.borderWidth,
                pointBackgroundColor: 'transparent',
                pointHoverBackgroundColor: '#28b93c',
                pointBorderColor: 'transparent',
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                borderDash: [5, 5],
                fill: false,
                label: 'y-axis-0',
                yAxisID: 'y-axis-0',
            };
            const pointStyles4 = {
                borderColor: greyColor,
                borderWidth: this.borderWidth,
                pointBackgroundColor: greyColor,
                pointHoverBackgroundColor: greyColor,
                pointBorderColor: greyColor,
                pointBorderWidth: 0,
                pointHoverBorderWidth: 0,
                borderDash: [5, 5],
                fill: false,
                label: 'y-axis-1',
                yAxisID: 'y-axis-1',
            };
            const messages1 = {};
            const messages2 = {};

            const labelsLength = Math.max(this.socialQuantityAndCoverage.data.length, this.socialQuantityAndCoverage2.data.length);

            let labels = [{ 0: '', 1: '' }];
            for (let i = 0; i < labelsLength; i++) {
                labels.push({
                    0: (this.socialQuantityAndCoverage.data[i] && this.socialQuantityAndCoverage.data[i].date) ? this.socialQuantityAndCoverage.data[i].date: '',
                    1: (this.socialQuantityAndCoverage2.data[i] && this.socialQuantityAndCoverage2.data[i].date) ? this.socialQuantityAndCoverage2.data[i].date: '',
                });
            }

            const pointsData = [
                [null, ...this.socialQuantityAndCoverage.data.map(item => item.coverage || 0)],
                [null, ...this.socialQuantityAndCoverage.data.map(item => item.quantity)],
                [null, ...this.socialQuantityAndCoverage2.data.map(item => item.coverage || 0)],
                [null, ...this.socialQuantityAndCoverage2.data.map(item => item.quantity)],
            ];
            const visiblePoints = [[], []];
            pointStyles.pointRadius = [0];
            pointStyles2.pointRadius = [0];
            pointStyles3.pointRadius = [0];
            pointStyles4.pointRadius = [0];
            pointStyles.pointHoverRadius = [0];
            pointStyles2.pointHoverRadius = [0];
            pointStyles3.pointHoverRadius = [0];
            pointStyles4.pointHoverRadius = [0];


            for (let i = 1; i <= labels.length; i++) {
                let postFound = false;
                for (const post of this.socialQuantityAndCoverage.posts) {
                    if (
                        this.socialQuantityAndCoverage.data[i - 1] &&
                        Number(this.socialQuantityAndCoverage.data[i - 1].start_date) <= Number(post.date) &&
                        Number(this.socialQuantityAndCoverage.data[i - 1].end_date) >= Number(post.date)
                    ) {
                        //if (i < labels.length - 1 && ['3', '6', '9', '18'].includes(labels[i].split(' ')[0])) {
                        pointStyles.pointRadius.push(0);
                        pointStyles.pointHoverRadius.push(2.5);
                        pointStyles2.pointRadius.push(pr);
                        pointStyles2.pointHoverRadius.push(pr);
                        visiblePoints[0].push({value: this.socialQuantityAndCoverage.data[i - 1].value, title: post.title});
                        messages1[labels[i][0]] = post.title;
                        postFound = true;
                        break;
                    }
                }
                if (!postFound) {
                    pointStyles.pointRadius.push(0);
                    pointStyles.pointHoverRadius.push(2.5);
                    pointStyles2.pointRadius.push(0);
                    pointStyles2.pointHoverRadius.push(2.5);
                }
                postFound = false;
                for (const post of this.socialQuantityAndCoverage2.posts) {
                    if (
                        this.socialQuantityAndCoverage2.data[i - 1] &&
                        Number(this.socialQuantityAndCoverage2.data[i - 1].start_date) <= Number(post.date) &&
                        Number(this.socialQuantityAndCoverage2.data[i - 1].end_date) >= Number(post.date)
                    ) {
                        pointStyles3.pointRadius.push(0);
                        pointStyles3.pointHoverRadius.push(2.5);
                        pointStyles4.pointRadius.push(pr);
                        pointStyles4.pointHoverRadius.push(pr);
                        visiblePoints[1].push({value: this.socialQuantityAndCoverage2.data[i - 1].quantity, title: post.title});
                        messages2[labels[i][1]] = post.title;
                        postFound = true;
                        break;
                    }
                }
                if (!postFound) {
                    pointStyles3.pointRadius.push(0);
                    pointStyles3.pointHoverRadius.push(2.5);
                    pointStyles4.pointRadius.push(0);
                    pointStyles4.pointHoverRadius.push(2.5);
                }
            }
            // pointStyles.pointHoverRadius = pointStyles.pointRadius
            // pointStyles2.pointHoverRadius = pointStyles2.pointRadius

            if (true || labels.length <= 10) {
                labels = labels.slice(1);
                pointsData[0] = pointsData[0].slice(1);
                pointsData[1] = pointsData[1].slice(1);
                pointsData[2] = pointsData[2].slice(1);
                pointsData[3] = pointsData[3].slice(1);
                pointStyles.pointRadius = pointStyles.pointRadius.slice(1);
                pointStyles.pointHoverRadius = pointStyles.pointHoverRadius.slice(1);
                pointStyles2.pointRadius = pointStyles2.pointRadius.slice(1);
                pointStyles2.pointHoverRadius = pointStyles2.pointHoverRadius.slice(1);
                pointStyles3.pointRadius = pointStyles3.pointRadius.slice(1);
                pointStyles3.pointHoverRadius = pointStyles3.pointHoverRadius.slice(1);
                pointStyles4.pointRadius = pointStyles4.pointRadius.slice(1);
                pointStyles4.pointHoverRadius = pointStyles4.pointHoverRadius.slice(1);
            }
            if (labels.length === 1) {
                labels = ['', labels[0], ''];
                pointsData[0] = [null, pointsData[0][0], null];
                pointsData[1] = [null, pointsData[1][0], null];
                pointsData[2] = [null, pointsData[2][0], null];
                pointsData[3] = [null, pointsData[3][0], null];
                pointStyles.pointHoverRadius = [0, pointStyles.pointRadius[0] ? pointStyles.pointHoverRadius[0] : 2.5, 0];
                pointStyles.pointRadius = [0, pointStyles.pointRadius[0] || 1.25, 0];
                pointStyles2.pointHoverRadius = [0, pointStyles2.pointRadius[0] ? pointStyles2.pointHoverRadius[0] : 2.5, 0];
                pointStyles2.pointRadius = [0, pointStyles2.pointRadius[0] || 1.25, 0];
                pointStyles3.pointHoverRadius = [0, pointStyles3.pointRadius[0] ? pointStyles3.pointHoverRadius[0] : 2.5, 0];
                pointStyles3.pointRadius = [0, pointStyles3.pointRadius[0] || 1.25, 0];
                pointStyles4.pointHoverRadius = [0, pointStyles4.pointRadius[0] ? pointStyles4.pointHoverRadius[0] : 2.5, 0];
                pointStyles4.pointRadius = [0, pointStyles4.pointRadius[0] || 1.25, 0];
            }

            this.pointStyles = pointStyles;
            this.pointStyles2 = pointStyles2;
            this.pointStyles3 = pointStyles3;
            this.pointStyles4 = pointStyles4;
            this.pointsData = pointsData;
            this.visiblePoints = visiblePoints.map(item => item.sort((a, b) => b.value - a.value).map(a => a.title));
            this.chartOptions.messages1 = messages1;
            this.chartOptions.messages2 = messages2;
            const datasets = [
                {
                    ...pointStyles,
                    data: this.pointsData[0],
                },
                {
                    ...pointStyles2,
                    data: this.pointsData[1],
                },
            ];
            if (this.compareDate.startDate) {
                datasets.push({
                    ...pointStyles3,
                    data: this.pointsData[2],
                });
                datasets.push({
                    ...pointStyles4,
                    data: this.pointsData[3],
                });
            }
            this.lineChartData = {
                labels,
                datasets,
            };
        },
        setStyles() {
            if (this.isMobile) {
                this.chartStyles.height = 138 + 'px';
                this.chartStyles.paddingTop = 17 + 'px';
                this.chartStyles.paddingBottom = 25 + 'px';
                this.chartOptions.scales.yAxes[0].ticks.fontSize = 8;
                this.chartOptions.scales.yAxes[1].ticks.fontSize = 8;

                this.chartOptions.scales.yAxes[0].ticks.beginAtZero = false;

                this.chartOptions.scales.xAxes[0].ticks.fontSize = 8;
                this.chartOptions.scales.xAxes[1].ticks.fontSize = 8;

                this.chartOptions.scales.yAxes[0].ticks.padding = 0;

                this.chartOptions.scales.xAxes[0].ticks.padding = 0;
                this.chartOptions.scales.xAxes[1].ticks.padding = 0;

                this.chartOptions.scales.xAxes[0].ticks.autoSkip = false;
                this.chartOptions.scales.yAxes[0].gridLines.tickMarkLength = -17; //-23
                this.chartOptions.scales.yAxes[1].gridLines.tickMarkLength = -23;
                this.chartOptions.pointFontSize = 8;
                this.chartOptions.tooltipPadding = 8;
                this.chartOptions.layout.padding = {
                    left: 8,
                    right: 12,
                    top: 0,
                    bottom: 1,
                };
                this.pointRadius = 6;
                this.borderWidth = 1.2;
            } else {
                if (this.is4K) {
                    this.chartStyles.height = 468 + 'px';
                    this.chartStyles.paddingTop = 8 + 'px';
                    this.chartStyles.paddingBottom = 0 + 'px';
                    this.chartOptions.scales.yAxes[0].ticks.fontSize = 19;
                    this.chartOptions.scales.yAxes[1].ticks.fontSize = 19;
                    this.chartOptions.scales.yAxes[0].ticks.beginAtZero = true;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 20;
                    this.chartOptions.scales.xAxes[1].ticks.fontSize = 20;
                    this.chartOptions.scales.yAxes[0].ticks.padding = 10;
                    this.chartOptions.scales.xAxes[0].ticks.padding = 20;
                    this.chartOptions.scales.xAxes[1].ticks.padding = 20;
                    this.chartOptions.scales.xAxes[0].ticks.autoSkip = true;
                    this.chartOptions.scales.yAxes[0].gridLines.tickMarkLength = 10;
                    this.chartOptions.scales.yAxes[1].gridLines.tickMarkLength = 10;
                    this.chartOptions.pointFontSize = 22;
                    this.chartOptions.tooltipPadding = 30;
                    this.chartOptions.layout.padding = {
                        left: 50,
                        right: 60,
                        top: 64,
                        bottom: 40,
                    };
                    this.pointRadius = 16;
                    this.borderWidth = 3.22;
                } else {
                    this.chartStyles.height = 314 + 'px';
                    this.chartStyles.paddingTop = 8 + 'px';
                    this.chartStyles.paddingBottom = 0 + 'px';
                    this.chartOptions.scales.yAxes[0].ticks.fontSize = 12;
                    this.chartOptions.scales.yAxes[1].ticks.fontSize = 12;
                    this.chartOptions.scales.yAxes[0].ticks.beginAtZero = true;
                    this.chartOptions.scales.xAxes[0].ticks.fontSize = 12;
                    this.chartOptions.scales.xAxes[1].ticks.fontSize = 12;
                    this.chartOptions.scales.yAxes[0].ticks.padding = 5;
                    this.chartOptions.scales.xAxes[0].ticks.padding = 12;
                    this.chartOptions.scales.xAxes[1].ticks.padding = 12;
                    this.chartOptions.scales.xAxes[0].ticks.autoSkip = true;
                    this.chartOptions.scales.yAxes[0].gridLines.tickMarkLength = 10;
                    this.chartOptions.scales.yAxes[1].gridLines.tickMarkLength = 10;
                    this.chartOptions.pointFontSize = 14;
                    this.chartOptions.tooltipPadding = 20;
                    this.chartOptions.layout.padding = {
                        left: 27,
                        right: 37,
                        top: 40,
                        bottom: 30,
                    };
                    this.pointRadius = 10;
                    this.borderWidth = 2;
                }
            }
        },
        setTicksStyles() {
            if (this.isMobile) {
                const maxValue = Math.max(...this.pointsData[0], ...this.pointsData[2]);
                const precision = Math.pow(10, Math.floor(Math.log10(maxValue)) - 1);
                this.chartOptions.scales.yAxes[0].ticks.stepSize = (Math.ceil(maxValue / precision) * precision) / 2;

                const maxValue2 = Math.max(...this.pointsData[1], ...this.pointsData[3]);
                const precision2 = Math.pow(10, Math.floor(Math.log10(maxValue2)) - 1);
                this.chartOptions.scales.yAxes[1].ticks.stepSize = (Math.ceil(maxValue2 / precision2) * precision2) / 2;
            } else {
                this.chartOptions.scales.yAxes[0].ticks.stepSize = null;
                this.chartOptions.scales.yAxes[1].ticks.stepSize = null;
            }
            this.chartOptions.scales.yAxes[0].ticks.callback = (value) => (value === 0 && this.isMobile ? '' : value);
            this.chartOptions.scales.yAxes[1].ticks.callback = (value) => (value === 0 && this.isMobile ? '' : value);
        },
        getRandomInt(multiplier = 1) {
            return Math.floor(Math.random() * (1400 * multiplier - 5 + 1)) + 5;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
