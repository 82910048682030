import { Bar, mixins } from 'vue-chartjs';
import { mergeDeep } from '../../helpers';
import { mapGetters } from 'vuex';

const { reactiveProp, reactiveData } = mixins;

export default {
    name: 'BarChartVertical',
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        ...mapGetters(['is4K']),
    },
    data() {
        return {
            _options: {
                responsive: true,
                maintainAspectRatio: false,
                type: 'verticalBar',
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                tickMarkLength: 15,
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                padding: 0,
                                beginAtZero: true,
                                fontColor: '#878787',
                                lineHeight: 1,
                                fontStyle: 500,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                color: '#E0E0E0',
                                lineWidth: 2,
                                drawBorder: false,
                            },
                            ticks: {
                                fontColor: '#878787',
                                padding: 12,
                                beginAtZero: true,
                                fontStyle: 500,
                                lineHeight: 15 / 12,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                    callbacks: {
                        label(tooltipItem) {
                            return tooltipItem.value;
                        },
                    },
                },
                hover: {
                    animationDuration: 0,
                    onHover: function(e) {
                        var point = this.getElementAtEvent(e);
                        if (!this.options.hoverPointer) return;
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                },
                animation: {
                    onProgress: () => {
                        this.$data._options.animation.onComplete();
                    },
                    onComplete: () => {
                        let chartInstance = this.$data._chart.chart;
                        let ctx = chartInstance.ctx;
                        let font = ctx.font.split(' ');

                        ctx.textAlign = 'center';
                        ctx.font = `normal ${this.options.barFontSize}px ${font[font.length - 1]}`;
                        Chart.helpers.each(
                            this.$data._chart.data.datasets.forEach((dataset, i) => {
                                let meta = chartInstance.controller.getDatasetMeta(i);
                                Chart.helpers.each(
                                    meta.data.forEach((bar, index) => {
                                        ctx.fillStyle = '#fff';
                                        const fillTextSuffix = chartInstance.options.fillTextSuffix || '';
                                        const fillText = dataset.data[index] + fillTextSuffix;
                                        let textMargin = 10;
                                        let textHeight = 10;
                                        if (this['is4K']) {
                                            textMargin = 15;
                                            textHeight = 15;
                                        }

                                        let y = bar._model.y + textHeight + textMargin;
                                        if (y >= bar._model.base || fillText.length > 2) {
                                            y = bar._model.y - 3;
                                            ctx.fillStyle = '#000';
                                        }
                                        ctx.fillText(fillText, bar._model.x, y);
                                    }),
                                    this
                                );
                            }),
                            this
                        );
                        this.$router.currentRoute.path !== '/' &&
                            Chart.helpers.each(
                                this.$data._chart.scales['x-axis-0']._labelItems.forEach((label, i) => {
                                    const dashWidth = this['is4K'] ? 4 : 3;
                                    const x = label.x + (label.x % dashWidth);
                                    const font = label.font.string;
                                    let y = label.y;
                                    let textWidth = 0;
                                    const lineWidth = this['is4K'] ? 2 : 1;
                                    ctx.font = font;

                                    if (typeof label.label === 'object') {
                                        label.label.forEach((t) => {
                                            textWidth = textWidth < ctx.measureText(t).width ? ctx.measureText(t).width : textWidth;
                                            y += label.font.lineHeight;
                                        });
                                    } else {
                                        textWidth = ctx.measureText(label.label).width;
                                        y += label.font.lineHeight;
                                    }
                                    if (!label.rotation) {
                                        textWidth = textWidth - (textWidth % dashWidth);
                                        ctx.setLineDash([dashWidth, dashWidth]);
                                        ctx.lineWidth = lineWidth;
                                        ctx.beginPath();
                                        ctx.moveTo(x - textWidth / 2, y + label.textOffset);
                                        ctx.lineTo(x + textWidth / 2, y + label.textOffset);
                                        ctx.strokeStyle = '#878787';
                                        ctx.stroke();
                                    }

                                }),
                                this
                            );
                    },
                },
            },
            options: {},
        };
    },
    mounted() {
        this.mergeOptions();
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartOptions: {
            handler(v) {
                this.mergeOptions();
                this.renderChart(this.chartData, this.options);
            },
            deep: true,
        },
        // chartData: {
        // 	handler(v) {
        // 		//this.mergeOptions()
        // 		this.renderChart(this.chartData, this.options)
        // 	},
        // 	deep: true,
        // },
    },
    methods: {
        mergeOptions() {
            this.$data._options.onClick = this.onClick.bind(this);
            this.options = mergeDeep({}, this.$data._options, this.chartOptions);
        },
        onClick(e, array) {
            for (let i = 0; i < array.length; i++) {
                if (array[i]._model && array[i]._model.label) {
                    return this.$emit('onClick', array[i]._model.label);
                }
            }
            if (!array.length) {
                const chartInstance = this.$data._chart.chart;
                const xAxis = chartInstance.scales['x-axis-0'];

                // If mouse is over the legend, change cursor style to pointer, else don't show it
                const x = e.offsetX;
                const y = e.offsetY;

                if (e.type === 'click' && x <= xAxis.right && x >= xAxis.left && y <= xAxis.bottom && y >= xAxis.top) {
                    // category scale returns index here for some reason
                    const index = xAxis.getValueForPixel(x);
                    return this.$emit('onClick', chartInstance.data.labels[index]);
                }
            }
        },
    },
};
