import { Bubble, mixins } from 'vue-chartjs';
import { mergeDeep } from '../../helpers';

const { reactiveProp, reactiveData } = mixins;

export default {
    name: 'BubbleChart',
    extends: Bubble,
    mixins: [reactiveProp],
    props: {
        // label: {
        // 	type: String,
        // },
        chartData: {
            type: Object,
            default: () => ({}),
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            _options: {
                padding: 60,
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        //fontSize: 30,
                        usePointStyle: true,
                    },
                },
                // scales: {
                // 	// scaleLabel: {
                // 	// 	display: false,
                // 	// 	labelString: 'A',
                // 	// },
                // 	xAxes: [
                // 		{
                // 			gridLines: {
                // 				display:false
                // 			},
                // 			ticks: {
                // 				display: false,
                // 				padding: 7,
                // 				min:0,
                // 			},
                // 		},
                // 	],
                // 	yAxes: [
                // 		{
                // 			barThickness: 18,
                // 			maxBarThickness: 18,
                // 			barPercentage: 1.0,
                // 			categoryPercentage: 1.0,
                // 			gridLines: {
                // 				display:false
                // 			},
                // 			ticks: {
                // 				padding: 14,
                // 				fontSize: 14,
                // 				fontStyle: 500,
                // 				lineHeight: 1.071,
                // 			},
                // 		},
                // 	],
                // },
                // tooltips: {
                // 	enabled:false,
                // 	callbacks: {
                // 		label(tooltipItem) {
                // 			return tooltipItem.value;
                // 		},
                // 	},
                // },
                hover: {
                    animationDuration: 0, // duration of animations when hovering an item
                    onHover: function(e) {
                        var point = this.getElementAtEvent(e);
                        if (!this.options.hoverPointer) return;
                        if (point.length) e.target.style.cursor = 'pointer';
                        else e.target.style.cursor = 'default';
                    }
                },
                responsiveAnimationDuration: 0, // animation duration after a resize
                animation: {
                    duration: 1, // general animation time
                    onComplete: function() {
                        var chartInstance = this.chart;
                        var ctx = chartInstance.ctx;
                        // var height = chartInstance.controller.boxes[0].bottom;
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        Chart.helpers.each(
                            this.data.datasets.forEach(function(dataset, i) {
                                var meta = chartInstance.controller.getDatasetMeta(i);
                                ctx.fillStyle = '#fff';
                                Chart.helpers.each(
                                    meta.data.forEach(function(point, index) {
                                        const fillTextSuffix = chartInstance.options.fillTextSuffix || '';
                                        const fillText = dataset.data[index].value + fillTextSuffix;
                                        if (dataset.data[index].fontColor) {
                                            ctx.fillStyle = dataset.data[index].fontColor;
                                        }
                                        if (dataset.data[index].fontSize) {
                                            ctx.font =
                                                dataset.data[index].fontSize +
                                                'px ' +
                                                ctx.font
                                                    .split(' ')
                                                    .slice(1)
                                                    .join(' ');
                                        }
                                        let x = point._model.x;
                                        let y = point._model.y;
                                        const rotation = point._model.rotation || 0;

                                        if (point._model.pointStyle === 'semicircle') {
                                            let dy = (Math.cos(Math.PI - rotation) * point._model.radius) / 2;
                                            x -= (Math.sin(Math.PI - rotation) * point._model.radius) / 2;
                                            if (dy > 0) {
                                                dy -= dy / 4.5;
                                            }
                                            y -= dy;
                                        }
                                        ctx.fillText(fillText, x, y);
                                    }),
                                    this
                                );
                            }),
                            this
                        );
                    },
                },
            },
            options: {},
        };
    },
    mounted() {
        this.mergeOptions();
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartOptions: {
            handler(v) {
                this.mergeOptions();
                this.renderChart(this.chartData, this.options);
            },
            deep: true,
        },
    },
    methods: {
        onClick(e, array) {
            if (array[0]) {
                const chartInstance = this.$data._chart.chart;
                const xAxis = chartInstance.scales['x-axis-0'];
                const yAxis = chartInstance.scales['y-axis-0'];
                return this.$emit('click', {
                    index: array[0]._index,
                    x: Math.round(xAxis.getValueForPixel(array[0]._model.x)),
                    y: Math.round(yAxis.getValueForPixel(array[0]._model.y)),
                });
            }
        },
        mergeOptions() {
            this.$data._options.onClick = this.onClick.bind(this);
            this.options = mergeDeep({}, this.$data._options, this.chartOptions);
        },
    },
};
