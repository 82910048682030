<template>
    <div class="smi-top-publications" v-if="userAccessSmi">
        <div>
            <div class="container">
                <div class="section-subtitle">СМИ: ТОП источников по количеству публикаций</div>
            </div>
        </div>
        <Form
            ref="form"
            :comparedatePosBreakPoints="{900: 'center', 1440: 'right', 10000: 'right'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'tonality', 'compareDate']"
            @download="$downloadReport(pressList, $event)"
        />

        <div>
            <div class="container">
                <ChooseChart
                    :activeChart="activeChart"
                    @click="toggleChart"
                />
                <VerticalBarChartComponent
                    v-if="activeChart === 'verticalBar'"
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                    :loaded="topPressLoaded"
                    :maxRotation="90"
                    v-model="sources"
                />
                <PieChartComponent
                    v-else
                    :labels="labels"
                    :data="data"
                    :data2="data2"
                />
            </div>
        </div>
        <section>
            <div class="container">
                <div
                    class="table-wrap"
                    style="margin-bottom: 0"
                >
                    <Posts
                        @click="postClick"
                        v-model="orderData"
                        :ordering="true"
                        :headers="postHeaders"
                        :posts="pressList"
                        :postsCompare="pressList2"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import Posts from '@/components/Posts';
import VerticalBarChartComponent from '@/components/VerticalBarChartComponent';
import PieChartComponent from '@/components/PieChartComponent';
import Pagination from '@/components/Pagination';
import ChooseChart from '@/components/ChooseChart';
import downloadReport from '@/mixins/downloadReport';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SmiTopPublications',
    mixins: [downloadReport],
    data() {
        return {
            orderData: {},
            activeChart: 'verticalBar',
            postHeaders: {
                name: { title: 'Название СМИ', class: 'td--text td--center', format_number: false, ordering: true },
                quantity: { title: 'Количество публикаций', class: '', format_number: true, ordering: true },
                mediaIndex: { title: 'Медиаиндекс', class: '', format_number: true, ordering: true },
                link: { title: 'Перейти на оригинал', class: 'td--btn', format_number: false, ordering: false },
            },
            reportColumns: [ 'name', 'quantity', 'mediaIndex', 'link' ],
            sources: [],
        };
    },
    computed: {
        ...mapState(['detailization', 'tonality', 'region']),
        ...mapGetters([
            'date',
            'compareDate',
            'topPress',
            'topPressLoaded',
            'pressList',
            'userAccessSmi',
        ]),
        ...mapGetters({
            topPress2Global: "topPress2",
            pressList2Global: "pressList2",
        }),
        topPress2() {
            return this.compareDate.startDate ? this.topPress2Global : {};
        },
        pressList2() {
            return this.compareDate.startDate ? this.pressList2Global : [];
        },
        data() {
            return this.topPress.map(item => item.value);
        },
        data2() {
            return this.compareDate.startDate && this.compareDate.endDate ? this.topPress.map(item => this.topPress2[item.name] || 0) : [];
        },
        labels() {
            return this.topPress.map(item => item.name);
        },
        apiQueryParams() {
            return {
                date: this.date,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
            }
        },
        apiQueryParamsCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
            }
        },
        apiQueryParamsSources() {
            return {
                date: this.date,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                sources: this.sourcesToFilter,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        apiQueryParamsSourcesCompare() {
            return {
                date: this.compareDate,
                detailization: this.detailization,
                tonality: this.tonality,
                region: this.region,
                sources: this.sourcesToFilter,
                progressTime: this.progressTime,
                userAccessSmi: this.userAccessSmi,
                orderBy: this.orderData.orderBy,
                orderDir: this.orderData.orderDir,
            }
        },
        sourcesToFilter() {
            return this.sources.length ? this.sources : this.topPress.map(item => item.name);
        }
    },
    watch: {
        apiQueryParams() {
            this.userAccessSmi && this.getTopPress(this.apiQueryParams);
        },
        apiQueryParamsCompare() {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.userAccessSmi && this.getTopPress2(this.apiQueryParamsCompare);
            }
        },
        apiQueryParamsSources() {
            this.userAccessSmi && this.getPressList(this.apiQueryParamsSources);
        },
        apiQueryParamsSourcesCompare() {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.userAccessSmi && this.getPressList2(this.apiQueryParamsSourcesCompare);
            }
        },
        userAccessSmi(userAccessSmi) {
            this.$checkAccess(userAccessSmi);
        },
    },
    methods: {
        ...mapActions([
            'getTopPress',
            'getTopPress2',
            'getPressList',
            'getPressList2',
            'progressTime',
            'getSmiModalMessages',
        ]),
        toggleChart() {
            if (this.activeChart === 'verticalBar') {
                this.activeChart = 'pie';
            } else {
                this.activeChart = 'verticalBar';
            }
            this.$refs.form.reportCompleted = false;
        },
        postClick(post) {
            this.getSmiModalMessages({
                source: post.name,
                ...this.apiQueryParams
            });
        },
    },
    mounted() {
        this.$checkAccess(this.userAccessSmi);
    },
    components: {
        Form,
        Posts,
        Pagination,
        VerticalBarChartComponent,
        PieChartComponent,
        ChooseChart,
    },
};
</script>

<style lang="scss">
.smi-top-publications {
    .form-field {
        &.tonality {
            margin-right: calc(44 / 1200 * 100%);
        }
    }
}

@media (max-width: 1108px) {
    .smi-top-publications {
        .form-field {
            &.tonality {
                margin-right: calc(30 / 920 * 100%);
            }
        }
    }
}
</style>
