<template>
    <div class="soc-mentions" v-if="userAccessSocmedia">
        <div>
            <div class="container">
                <div class="section-subtitle">Социальные сети: Матрица упоминаний</div>
            </div>
        </div>
        <Form
            :comparedatePosBreakPoints="{620: 'center', 890: 'left', 1440: 'left', 10000: 'center'}"
            :datePosBreakPoints="{620: 'center', 980: 'left'}"
            :fieldsList="['fastChoose', 'date', 'tonality', 'region', 'compareDate']"
            :tableReport="false"
        />
        <section>
            <div class="container">
                <BubbleChartComponent
                        @click="onBubbleClick"
                        :hoverPointer="true"
                />
            </div>
        </section>
    </div>
</template>

<script>
import Form from '@/components/Form/Form';
import BubbleChartComponent from '@/components/BubbleChartComponent';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'SocNetMentions',
    data() {
        return {
            timeout: null,
            timeout2: null,
        };
    },
    components: {
        Form,
        BubbleChartComponent,
    },

    watch: {
        apiQueryParams: {
            handler() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.userAccessSocmedia && this.getSocialReputationMatrix(this.apiQueryParams);
                }, 500);
            },
            deep: true,
        },
        apiQueryParamsCompare: {
            handler() {
                clearTimeout(this.timeout2);
                this.timeout2 = setTimeout(() => {
                    if (this.compareDate.startDate && this.compareDate.endDate) {
                        this.userAccessSocmedia && this.getSocialReputationMatrix2(this.apiQueryParamsCompare);
                    }
                }, 500);
            },
            deep: true,
        },
        userAccessSocmedia(userAccessSocmedia) {
            this.$checkAccess(userAccessSocmedia);
        }
    },
    mounted() {
        this.$checkAccess(this.userAccessSocmedia);
        if (!this.userAccessSocmedia) return;
        this.timeout = setTimeout(() => {
            this.getSocialReputationMatrix(this.apiQueryParams);
        }, 500);
        this.timeout2 = setTimeout(() => {
            if (this.compareDate.startDate && this.compareDate.endDate) {
                this.getSocialReputationMatrix2(this.apiQueryParamsCompare);
            }
        }, 500);
    },
    computed: {
        ...mapState(['detailization']),
    	...mapGetters([
            'date',
            'compareDate',
            'region',
            'tonality',
            'progressTime',
            'userAccessSocmedia',
    	]),
        apiQueryParams() {
          return {
              date: this.date,
              tonality: this.tonality,
              region: this.region,
              detailization: this.detailization,
              progressTime: this.progressTime,
              userAccessSocmedia: this.userAccessSocmedia,
          }
        },
        apiQueryParamsCompare() {
          return {
              date: this.compareDate,
              tonality: this.tonality,
              region: this.region,
              detailization: this.detailization,
              progressTime: this.progressTime,
              userAccessSocmedia: this.userAccessSocmedia,
          }
        },
    },
    methods: {
        ...mapActions([
            'getSocialReputationMatrix',
            'getSocialReputationMatrix2',
            'getSocialModalMessages',
        ]),
        onBubbleClick(data) {
            const params = {
                ...this.apiQueryParams
            };
            if (data.x === 'Все') {
                params.districts = data.xArray.map(x => typeof x === 'string' ? x : x.join(" "));
            } else {
                params.district = data.x;
            }
            switch (data.level) {
                case 1:
                    params.problem1 = data.y;
                    break;
                case 2:
                    params.problem2 = data.y;
                    break;
                default:
                    console.error(`Unknown level: ${data.level}`)
            }
            if (data.y.toLowerCase() === 'все') {
                params.problem1 = params.problem2 = undefined;
            }
            this.userAccessSocmedia && this.getSocialModalMessages({
                ...params,
                compareDate: this.apiQueryParamsCompare.date,
            });
        }
    }
};
</script>

<style lang="scss">
.soc-mentions {
    section {
        margin-top: 0;
    }
    .form-field {
        &.comparedate .opensleft {
            transform: translateX(calc(50% + 52px));
        }
    }
}

@media (max-width: 1032px) and (min-width: 1014px) {
    .soc-mentions .form-field.comparedate .opensleft {
        transform: translateX(28%);
    }
}
@media (max-width: 969px) {
    .soc-mentions .form-field.comparedate .opensleft {
        transform: none;
    }
}

@media (max-width: 890px) {
    .soc-mentions {
        .form-field {
            &.tonality {
                min-width: 160px;
            }
            &.tonality,
            &.btns-wrap .btn {
                margin-right: 20px;
            }
        }
    }
}
@media (max-width: 620px) {
    .soc-mentions {
        .form-field {
            &.btns-wrap .btn {
                margin-right: 0;
            }
        }
    }
}
</style>
